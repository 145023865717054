import React, { Component } from "react";
import { Container, Grid } from "@material-ui/core";
import { imageBasePath } from "../../../constants";
import styles from "../../../assets/styles/home.module.scss";
import Lottie from "react-lottie";
import * as animationData from "../../../assets/lottie/homeScreen.json";
import LazyLoad from "react-lazyload";

export default function (props) {
  return (
    <section className={styles.bannerSection}>
      <Container maxWidth="lg" className={styles.bannerContainer}>
        <h1 className="bannerBold"> Hello there,</h1>
        <h1 className="bannerReg">
          DesignString is now{" "}
          <a
            href="https://blocktheory.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="bannerBold anchorLink">BlockTheory</span>{" "}
            <span className="arrowLink" role="img" aria-label="arrow emoji">
              {" "}
              ↗️{" "}
            </span>
          </a>{" "}
        </h1>
        {/* <h1 className="bannerReg bannerMargin">
          DesignString was a Web2 Product Design and Development studio operated
          out of Bengaluru, India, between August 2014 - April 2023.
        </h1> */}
        <h1 className="bannerReg">Thank you for visiting our website.</h1>
      </Container>
    </section>
  );
}
