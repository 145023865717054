import React, {Component} from 'react';
import {Container, Grid} from '@material-ui/core';
import {imageBasePath} from '../../../constants';
import styles from '../../../assets/styles/contact.module.scss';

export default function(props) {
  return(
    <div className={styles.bannerWrapper}>
      <div className={styles.contentBlk}>
        <h1 className="heading2">Let’s connect to explore!</h1>
        <h2 className="heading3">Contact Us</h2>
        <h3 className="paragraph">DesignString Solutions Private Limited <br/>
            #65/6, A P Complex, Agara, Sarjapura Main Road <br/>
            Bengaluru - 560102
        </h3>
        <p className={styles.getDirection}>
          <a href="https://www.google.co.in/maps/place/DesignString+Solutions+Pvt+Ltd/@12.9241191,77.6532371,18z/data=!3m1!4b1!4m8!1m2!3m1!2sDesignString+Solutions+Pvt+Ltd!3m4!1s0x3bae149a6394ec99:0x82d5be3b6734cf7d!8m2!3d12.9241176!4d77.6538741?shorturl=1" className="btnTextReg" target="_blank" rel="noopener">
            <span>View Direction</span>
            <img src={imageBasePath +'go_arrow.svg'} alt="Image" />
          </a>
        </p>
        <div className={styles.contactInfo}>
          <p>
            <a href="tel:080 4806 2233" className="subHeading1">080 4806 2233</a>
          </p>
          <p>
            <a href="mailto:info@designstring.com" className="subHeading1">info@designstring.com</a>
          </p>
        </div>
      </div>
    </div>
  )
}
