import React from 'react'
import {Container, Grid} from '@material-ui/core'
import styles from '../../../assets/styles/casestudy_detail.module.scss';

export default function Challenge(props) {
    const{pageName, challenge} = props;
    return (
        <Container maxWidth="lg">
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={8} md={7}>
                    <h2 className="heading2">The Challenge</h2>
                    <p className="paragraph">
                        {challenge}
                    </p>
                </Grid>
            </Grid>
        </Container>
    )
}
