import React, { useState, useRef } from 'react'
import {Container, Grid} from '@material-ui/core'
import {imageBasePath} from '../../../constants';
import styles from '../../../assets/styles/casestudy_detail.module.scss';
import VideoDialog from './VideoDialog'

export default function Solution(props) {
    const { pageName, solution, disignsImage }= props;
    const [activeMedia, setActiveMedia] = useState(solution.list ? solution.list[0].link : '')
    const [open, setOpen] = React.useState(false);
    const handleChangeMedia = (media) => {
        setActiveMedia(media)
    }

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
      vidRef.current.play();
    };

    const vidRef = useRef(null);
    const handlePauseVideo = () => {
      vidRef.current.pause();
    }

    return (
        <Container maxWidth="lg">
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={8} md={8}>
                    <h2 className={styles.sectionTitle+" heading2"}>The solution</h2>
                    {
                        solution.description.map((des, key) => (
                            <p key={key} className={styles.sectionSubtitle+" paragraph"}>
                                {des}
                            </p>
                        ))
                    }
                </Grid>
            </Grid>
            <Grid container direction="row" spacing="2">
                <Grid item xs={12} sm={8} md={8}>
                    {
                        solution && solution.list ?
                        <ul class={styles.solutionList+" listUnstyled"}>
                            {
                                solution.list.map((list, key) => (
                                    <li key={key} className={`heading4 ${activeMedia == list.link ? styles.active : ''}`} onClick={() => handleChangeMedia(list.link)}>
                                        {list.title}
                                    </li>
                                ))
                            }
                        </ul>
                        : null
                    }
                </Grid>
            </Grid>
            {
                !disignsImage?
                <div className={styles.heroImageContainer}>
                    {
                        solution.media.product_type == 'website' && solution.media.media_type == 'image' ?
                        <img className={styles.heroImage+' '+styles.websiteImage} src={imageBasePath+'v4/'+activeMedia} alt="solution"/>
                        :
                        solution.media.product_type == 'mobile' && solution.media.media_type == 'image' ?
                        <img className={styles.heroImage} src={imageBasePath+'v4/'+activeMedia} alt="solution"/>
                        :
                        solution.media.product_type == 'website' && solution.media.media_type == 'video' ?
                            <div className={styles.videoWrapper+' backgroundProp'}>
                                <img onClick={() => {handleClickOpen(); handlePauseVideo()}} className={styles.playIcon + ' ' +styles.playIconWebsite} src={imageBasePath+'v4/play_icon.png'} alt="Play icon"/>
                                <video
                                    key="home_intro"
                                    loop={true}
                                    autoPlay="autoplay"
                                    preload="auto"
                                    muted
                                    className="videoPlayer"
                                    ref={vidRef}
                                    >
                                    <source
                                        src={imageBasePath+'v4/'+activeMedia}
                                        type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        :
                        solution.media.product_type == 'mobile' && solution.media.media_type == 'video' ?
                            <div className={styles.videoWrapper+' backgroundProp '+ styles[solution.media.product_type]}>
                                <img onClick={() => {handleClickOpen(); handlePauseVideo()}} className={styles.playIcon} src={imageBasePath+'v4/play_icon.png'} alt="Play icon"/>
                                <video
                                    key={activeMedia}
                                    loop={true}
                                    autoPlay="autoplay"
                                    preload="auto"
                                    muted
                                    className="videoPlayer"
                                    id="myVideo"
                                    ref={vidRef}
                                    // style={{ width: "100%", height: "auto", borderRadius: "10px" }}
                                    >
                                    <source
                                        src={imageBasePath+'v4/'+activeMedia}
                                        type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        : null

                    }
                </div>
                : null
            }
            <VideoDialog
              open={open}
              onClose={handleClose}
              activeMedia={activeMedia}
              solution={solution}
            />
        </Container>

    )
}
