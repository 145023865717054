import React, { useState } from 'react'
import { Container, Grid } from '@material-ui/core';
import styles from '../../../assets/styles/careers.module.scss';
import { imageBasePath } from '../../../constants';

export default function Perks(props){
    const [perks] = useState([
        {
            img: `learning.png`,
            title: `Learning & development`
        },
        {
            img: `annual_leaves.png`,
            title: `Paid annual leaves`
        },
        {
            img: `healthy_food.png`,
            title: `Healthy food and snacks`
        },
        {
            img: `team_outbound.png`,
            title: `Annual team outbounds`
        },
    ])
    return(
        <section className={styles.perksSection}>
            <Container maxWidth="lg" className={styles.perksContainer}>
                <div className={styles.title}>
                    <h3 className="heading2">Perks at DesignString</h3>
                </div>
                <Grid container className={styles.perksListing} spacing={4}>
                    {
                        perks.map((item, index) => <Grid item xs={12} sm={6} md={3} className={styles.perksItemGrid} key={index}>
                                <div className={`${styles.perksItemWrapper} ${styles[index+1]}`}>
                                    <img src={`${imageBasePath}v4/${item.img}`} />
                                    <h3 className="heading3">{item.title}</h3>
                                </div>
                        </Grid>)
                    }
                </Grid>
            </Container>
        </section>
    )
}
