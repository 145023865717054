import React, { Component } from 'react';
import {Container,Grid, List, ListItem, FormControlLabel, Checkbox, TextField, InputLabel} from '@material-ui/core';
import {imageBasePath} from '../../../constants';
import styles from '../../../assets/styles/tinycanvas.module.scss';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share'

export default function(props) {
  const { error, userInfo, loader } = props
  return(
    <Container maxWidth="md" className={styles.ctaContainer}>
      <Grid container direction="row" alignItems="center" spacing={2} className={styles.titleRow + ' flex-centered'}>
        <Grid item md={8} sm={8} xs={12}>
          <h6>
            <img src={imageBasePath + 'giffy_canvas_cta.svg'} alt="Giffy Canvas"/>
            <span>Start Creating Your Own GIFs</span>
          </h6>
        </Grid>
        <Grid item md={4} sm={4} xs={12}>
          <p className={styles.figmaBtnBlk}>
            <a className="tryFigmaBtn" href="https://www.figma.com/c/plugin/803633147991628761/GiffyCanvas" target="_blank" rel="noopener">
              <span>Try for </span>
              <img src={imageBasePath + 'figma_icon.svg'} alt="Figma" />
              <span>Figma</span>
            </a>
          </p>
        </Grid>
      </Grid>
      <Grid container direction="row" alignItems="center"  spacing={2} className={styles.contentRow}>
        <Grid item md={7} xs={12} className={styles.leftBlk}>
          <h6 className={styles.title + ' heading1'}>Drop Us a Mail </h6>
          <p className="sub-heading2">Your feedback helps us to come up with more exciting features</p>
          <form  autoComplete="off" onSubmit={props.sendFeedback}>
            <Grid container direction="row" className={styles.formWrapper} spacing={2}>
              <Grid item md={6} xs={12}>
                <div className={styles.inputGroup + ' inputGroup'}>
                    <TextField
                      id="name"
                      label="Name"
                      variant="outlined"
                      fullWidth={true}
                      value={userInfo.name}
                      error={error.name ? true : false}
                      onChange={(e) => props.handleChange('name', e.target.value)}
                    />
                    <h6 className="error-msg" >{error.name ? error.name : ''}</h6>
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className={styles.inputGroup + ' inputGroup'}>
                  <TextField
                      id="email"
                      label="Email Address"
                      variant="outlined"
                      fullWidth={true}
                      value={userInfo.email}
                      error={error.email ? true : false}
                      onChange={(e) => props.handleChange('email', e.target.value)}
                  />
                  <h6 className="error-msg" >{error.email ? error.email : ''}</h6>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={styles.inputGroup + ' inputGroup'}>
                  <TextField
                    multiline={true}
                    rows={5}
                    rowsMax={5}
                    fullWidth={true}
                    label="Write your feedback...."
                    variant="outlined"
                    value={userInfo.message}
                    error={error.message ? true : false}
                    onChange={(e) => props.handleChange('message', e.target.value)}
                  />
                  <h6 className="error-msg" >{error.message ? error.message : ''}</h6>
                </div>
              </Grid>
              <Grid item xs={12}>
                  <input type="submit" onClick={props.sendFeedback} className="figmaSubmitBtn" disabled={loader} value={loader ? 'Sending..' : 'Send'}/>
              </Grid>
            </Grid>
          </form>
        </Grid>
        <Grid item md={5} xs={12} className={styles.rightBlk}>
          <div className={styles.socialMediaBlk}>
            <p className="heading3">Share it</p>
            <List className="list-inline">
              <ListItem key={0} className="list-inline-item">
                <FacebookShareButton url="https://www.designstring.com/giffycanvas">
                  <img src={imageBasePath + 'facebook_share.svg'} alt="Facebook" />
                </FacebookShareButton>
              </ListItem>
              <ListItem key={1} className="list-inline-item">
                <TwitterShareButton url="https://www.designstring.com/giffycanvas">
                  <img src={imageBasePath + 'twitter_share.svg'} alt="Twitter" />
                </TwitterShareButton>
              </ListItem>
              <ListItem key={2} className="list-inline-item">
                <LinkedinShareButton url="https://www.designstring.com/giffycanvas">
                  <img src={imageBasePath + 'linkedin_share.svg'} alt="linkedIn" />
                </LinkedinShareButton>
              </ListItem>
            </List>
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}
