import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { withRouter } from "react-router-dom";
import { Header, Footer } from "../shared_elements";
import { connect } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import CookieConsent from "react-cookie-consent";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function pageLayoutHoc(HocComponent, extraProps = {}) {
  class PageLayout extends Component {
    constructor(props) {
      super(props);
      this.state = {
        showMenu: false,
        fixedHeader: false,
        location: this.props.location.pathname,
      };
    }

    componentDidMount() {
      window.addEventListener("scroll", () => {
        if (window.scrollY <= 60) {
          this.setState({ fixedHeader: false });
        } else {
          this.setState({ fixedHeader: true });
        }
      });
    }

    componetWillRecieveProps(nextProps) {
      if (this.props.location.pathname != nextProps.location.pathname) {
        this.setState({ location: nextProps.location.pathname });
      }
    }

    get = () => {
      let type = this.state.location;
      if (type.includes("contact")) {
        return (
          <>
            <title>DesignString - Connect with us</title>
            <meta
              property="og:title"
              content="DesignString - Connect with us"
            />
            <meta
              property="og:description"
              content="We deliver results to tech-driven businesses through UX and UI design, help to build fantastic product experiences. Primary focus on SaaS &  Mobile apps"
            />
            <meta
              property="og:image"
              content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/mainsunset.jpg"
            />
            <meta property="og:url" content="https://designstring.com/" />
            <meta
              name="twitter:title"
              content="DesignString - Connect with us"
            />
            <meta
              name="twitter:description"
              content="We deliver results to tech-driven businesses through UX and UI design, help to build fantastic product experiences. Primary focus on SaaS &  Mobile apps"
            />
            <meta
              name="twitter:image"
              content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/mainsunset.jpg"
            />
            <meta name="twitter:card" content="summary"></meta>
            <meta
              name="description"
              content="We deliver results to tech-driven businesses through UX and UI design, help to build fantastic product experiences. Primary focus on SaaS &  Mobile apps"
            />
            <meta
              name="keywords"
              content="UX Design, UI Design Agency, User Research, Prototyping, Wireframing, Visual Design, DesignOps, DesignSprints, Interaction Design,  UX Audit, Illustration"
            />
          </>
        );
      } else if (type.includes("company")) {
        return (
          <>
            <title>DesignString - People of DesignString</title>
            <meta
              property="og:title"
              content="DesignString -  People of DesignString"
            ></meta>
            <meta
              property="og:description"
              content="Yashawanth B M founded DesignString on 25th August 2014. The Design Team is lead by Nikhil Nanu. Innovation Team is lead by Punith B M."
            ></meta>
            <meta
              property="og:image"
              content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/mainsunset.jpg"
            ></meta>
            <meta property="og:url" content="https://designstring.com/"></meta>
            <meta
              name="twitter:title"
              content="DesignString -  People of DesignString"
            ></meta>
            <meta
              name="twitter:description"
              content="Yashawanth B M founded DesignString on 25th August 2014. The Design Team is lead by Nikhil Nanu. Innovation Team is lead by Punith B M."
            ></meta>
            <meta
              name="twitter:image"
              content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/mainsunset.jpg"
            ></meta>
            <meta name="twitter:card" content="summary"></meta>
            <meta
              name="description"
              content="Yashawanth B M founded DesignString on 25th August 2014. The Design Team is lead by Nikhil Nanu. Innovation Team is lead by Punith B M."
            />
            <meta
              name="keywords"
              content="UX Design, UI Design Agency, User Research, Prototyping, Wireframing, Visual Design, DesignOps, DesignSprints, Interaction Design,  UX Audit, Illustration"
            />
          </>
        );
      } else if (type.includes("life-at-designstring")) {
        return (
          <>
            <title>DesignString - We are people first company</title>
            <meta
              property="og:title"
              content="DesignString -  We are people first company"
            ></meta>
            <meta
              property="og:description"
              content="DesignString is a not just service-based business, whereas running multiple products with core purpose as Design. It's a massive opportunity for an individual to excel in their career by associating themself with a purpose-driven company like DesignString"
            ></meta>
            <meta
              property="og:image"
              content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/mainsunset.jpg"
            ></meta>
            <meta property="og:url" content="https://designstring.com/"></meta>
            <meta
              name="twitter:title"
              content="DesignString -  We are people first company"
            ></meta>
            <meta
              name="twitter:description"
              content="DesignString is a not just service-based business, whereas running multiple products with core purpose as Design. It's a massive opportunity for an individual to excel in their career by associating themself with a purpose-driven company like DesignString"
            ></meta>
            <meta
              name="twitter:image"
              content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/mainsunset.jpg"
            ></meta>
            <meta name="twitter:card" content="summary"></meta>
            <meta
              name="description"
              content="DesignString is a not just service-based business, whereas running multiple products with core purpose as Design. It's a massive opportunity for an individual to excel in their career by associating themself with a purpose-driven company like DesignString"
            />
            <meta
              name="keywords"
              content="UX Design, UI Design Agency, User Research, Prototyping, Wireframing, Visual Design, DesignOps, DesignSprints, Interaction Design,  UX Audit, Illustration"
            />
          </>
        );
      } else if (type.includes("products")) {
        return (
          <>
            <title>
              DesignString - Initiatives to spread the importance of design
            </title>
            <meta
              property="og:title"
              content="DesignString -  Initiatives to spread the importance of design"
            ></meta>
            <meta
              property="og:description"
              content="We believe in giving back to the design community and spread the importance of the design across digital teams. It's essential for the design team to know other perspective and other teams to understand design, we facilitate the same via various initiatives"
            ></meta>
            <meta
              property="og:image"
              content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/mainsunset.jpg"
            ></meta>
            <meta property="og:url" content="https://designstring.com/"></meta>
            <meta
              name="twitter:title"
              content="DesignString -  Initiatives to spread the importance of design"
            />
            <meta
              name="twitter:description"
              content="We believe in giving back to the design community and spread the importance of the design across digital teams. It's essential for the design team to know other perspective and other teams to understand design, we facilitate the same via various initiatives"
            />
            <meta
              name="twitter:image"
              content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/mainsunset.jpg"
            />
            <meta name="twitter:card" content="summary"></meta>
            <meta
              name="description"
              content="We believe in giving back to the design community and spread the importance of the design across digital teams. It's essential for the design team to know other perspective and other teams to understand design, we facilitate the same via various initiatives"
            />
            <meta
              name="keywords"
              content="UX Design, UI Design Agency, User Research, Prototyping, Wireframing, Visual Design, DesignOps, DesignSprints, Interaction Design,  UX Audit, Illustration"
            />
          </>
        );
      } else if (type.includes("work")) {
        return (
          <>
            <title>DesignString - Good work speaks it all</title>
            <meta
              property="og:title"
              content="DesignString - Good work speaks it all"
            ></meta>
            <meta
              property="og:description"
              content="With a unique approach for design, we don't leave any stone unturned. Working with  startups, unicorns, listed companies and enterprise businesses across the globe"
            ></meta>
            <meta
              property="og:image"
              content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/mainsunset.jpg"
            ></meta>
            <meta property="og:url" content="https://designstring.com/"></meta>
            <meta
              name="twitter:title"
              content="DesignString - Good work speaks it all"
            ></meta>
            <meta
              name="twitter:description"
              content="With a unique approach for design, we don't leave any stone unturned. Working with  startups, unicorns, listed companies and enterprise businesses across the globe"
            ></meta>
            <meta
              name="twitter:image"
              content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/mainsunset.jpg"
            ></meta>
            <meta name="twitter:card" content="summary"></meta>
            <meta
              name="description"
              content="With a unique approach for design, we don't leave any stone unturned. Working with  startups, unicorns, listed companies and enterprise businesses across the globe"
            />
            <meta
              name="keywords"
              content="UX Design, UI Design Agency, User Research, Prototyping, Wireframing, Visual Design, DesignOps, DesignSprints, Interaction Design,  UX Audit, Illustration"
            />
          </>
        );
      } else if (type.includes("approach")) {
        return (
          <>
            <title>
              DesignString - Right design approach to build digital products
            </title>
            <meta
              property="og:title"
              content="DesignString - Right design approach to build digital products"
            ></meta>
            <meta
              property="og:description"
              content="We factor the stage of the product for our approach while designing digital products. With more than half a decade experience, we equip with right people, process and tools"
            ></meta>
            <meta
              property="og:image"
              content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/mainsunset.jpg"
            ></meta>
            <meta property="og:url" content="https://designstring.com/"></meta>
            <meta
              name="twitter:title"
              content="DesignString - Right design approach to build digital products"
            ></meta>
            <meta
              name="twitter:description"
              content="We factor the stage of the product for our approach while designing digital products. With more than half a decade experience, we equip with right people, process and tools"
            ></meta>
            <meta
              name="twitter:image"
              content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/mainsunset.jpg"
            ></meta>
            <meta name="twitter:card" content="summary"></meta>
            <meta
              name="description"
              content="We factor the stage of the product for our approach while designing digital products. With more than half a decade experience, we equip with right people, process and tools"
            />
            <meta
              name="keywords"
              content="UX Design, UI Design Agency, User Research, Prototyping, Wireframing, Visual Design, DesignOps, DesignSprints, Interaction Design,  UX Audit, Illustration"
            />
          </>
        );
      } else {
        return (
          <>
            <title>Top UX / UI Design firm for SaaS & Mobile apps</title>
            <meta
              property="og:title"
              content="Top UX / UI Design firm for SaaS &  Mobile apps"
            ></meta>
            <meta
              property="og:description"
              content="We deliver results to tech-driven businesses through UX and UI design, help to build fantastic product experiences. Primary focus on SaaS &  Mobile apps"
            ></meta>
            <meta
              property="og:image"
              content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/mainsunset.jpg"
            ></meta>
            <meta property="og:url" content="https://designstring.com/"></meta>
            <meta
              name="twitter:title"
              content="Top UX / UI Design firm for SaaS &  Mobile apps"
            ></meta>
            <meta
              name="twitter:description"
              content="We deliver results to tech-driven businesses through UX and UI design, help to build fantastic product experiences. Primary focus on SaaS &  Mobile apps"
            ></meta>
            <meta
              name="twitter:image"
              content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/mainsunset.jpg"
            ></meta>
            <meta name="twitter:card" content="summary"></meta>
            <meta
              name="description"
              content="We deliver results to tech-driven businesses through UX and UI design, help to build fantastic product experiences. Primary focus on SaaS &  Mobile apps"
            />
            <meta
              name="keywords"
              content="UX Design, UI Design Agency, User Research, Prototyping, Wireframing, Visual Design, DesignOps, DesignSprints, Interaction Design,  UX Audit, Illustration"
            />
          </>
        );
      }
    };

    toggleMenu = (val) => {
      this.setState({
        showMenu: val,
      });
      if (val === true) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    };

    render() {
      const headerFooterHiddenPaths = [
        "/tinycanvas",
        "/tinycanvas/detail",
        "/tinycanvas/detail",
        "/giffycanvas",
        "/giffycanvas/detail",
      ];
      return (
        <>
          {!headerFooterHiddenPaths.some((el) =>
            this.props.location.pathname.includes(el)
          ) ? (
            <Header
              showMenu={this.state.showMenu}
              toggleMenu={this.toggleMenu}
              fixedHeader={this.state.fixedHeader}
              pageName={extraProps.pageName ? extraProps.pageName : ""}
            />
          ) : null}
          <main>
            <div>
              <HocComponent {...this.props} />
            </div>
          </main>
          <Snackbar open={this.props.toastLists.length} autoHideDuration={5000}>
            <Alert severity="success">
              {this.props.toastLists[0] ? this.props.toastLists[0].message : ""}
            </Alert>
          </Snackbar>
          <CookieConsent
            location="bottom"
            buttonText="Got it!"
            cookieName="gotit"
            style={{
              background: "rgb(218, 219, 220)",
              color: "#000000",
              fontSize: "14px",
              "line-height": "22px",
              "box-shadow": "rgba(138, 138, 138, 0.38) 0px 0px 10",
              border: "1PX SOLID #CECDCD",
            }}
            buttonStyle={{
              background: "#000000",
              color: "#ffffff",
              fontSize: "13px",
              margin: "8px 30px",
              padding: "10px 25px",
            }}
            expires={150}
          >
            <span>
              This website uses cookies to ensure you get the best experience on
              our website.
            </span>{" "}
            <a
              href="https://www.designstring.com/cookie-policy"
              target="_blank"
              style={{
                color: "#0E7EE5",
                "text-decoration": "underline",
                fontSize: "12px",
                position: "relative",
                top: "-1px",
              }}
            >
              Learn More
            </a>
          </CookieConsent>
        </>
      );
    }
  }
  const mapStateToProps = (state) => ({
    toastLists: state.sharedReducers.toastLists,
  });
  return withRouter(connect(mapStateToProps, null)(PageLayout));
}
