import Launch from './containers/Launch';
import Partner from './containers/Partner';
import Accelerate from './containers/Accelerate';
import pageLayoutHoc from '../../hocs/pageLayoutHoc';
export const whatwedoRoutes = [
  {
    path: '/what-we-do/launch/',
    component : pageLayoutHoc(Launch, {pageName : 'Launch'}),
    key: 'launch'
  },
  {
    path: '/what-we-do/accelerate/',
    component : pageLayoutHoc(Accelerate, {pageName : 'Accelerate'}),
    key: 'accelerate'
  },
  {
    path: '/what-we-do/partner/',
    component : pageLayoutHoc(Partner, {pageName : 'Partner'}),
    key: 'partner'
  }
]
