import React, {Component} from 'react';
import {Container, Grid, List, ListItem, Hidden } from '@material-ui/core';
import {imageBasePath} from '../constants';
import { Link, useLocation } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import { isMobile } from 'react-device-detect';

export default function(props) {
  const location = useLocation();
    return (
        <section className="menuWrapper">
            <Container maxWidth="lg">
                <Grid container className="topHeader" direction="row" justify="center" alignItems="center">
                    <Grid item xs={6}>
                        <p className="logo">
                            <Link to="/" onClick={e => props.toggleMenu(false)}>
                                <img src={ imageBasePath+'menu_logo.svg' } alt="Designstring Logo"/>
                            </Link>
                        </p>
                    </Grid>
                    <Grid item xs={6}>
                        <Link className="closeBtn paragraph" onClick={e => props.toggleMenu(false)}>
                            <CloseIcon />
                        </Link>
                    </Grid>
                </Grid>
                <Grid container className="menuContent" direction="column" justify="center" alignItems="center">
                    <Grid container className="menuBottom" direction="row" justify="center" alignItems="center">
                      <Hidden only={['xs']}>
                        <Grid item sm={6} md={6} xs={12} className="leftBlk">
                            <div className="contentBlk">
                                <h3 className="heading3 title">What we do</h3>
                                <List className="list-unstyled">
                                    <ListItem>
                                        <Link to="/what-we-do/launch" onClick={e => props.toggleMenu(false)} className={`featureWrapper ${props.location.pathname === '/what-we-do/launch' ? 'active' : ''}`}>
                                            <h3 className="heading4">
                                                <img className="icon" src={`${imageBasePath}v4/launch.svg`}/>
                                                <span>Launch</span>
                                            </h3>
                                            <p className="paragraph">Take your digital product to the world</p>
                                        </Link>
                                    </ListItem>
                                    <ListItem>
                                        <Link to="/what-we-do/accelerate" onClick={e => props.toggleMenu(false)} className={`featureWrapper ${props.location.pathname === '/what-we-do/accelerate' ? 'active' : ''}`}>
                                            <h3 className="heading4">
                                                <img className="icon" src={`${imageBasePath}v4/accelerate.svg`}/>
                                                <span>Accelerate</span>
                                            </h3>
                                            <p className="paragraph">Take your product from good to great</p>
                                        </Link>
                                    </ListItem>
                                    <ListItem>
                                        <Link to="/what-we-do/partner" onClick={e => props.toggleMenu(false)} className={`featureWrapper ${props.location.pathname === '/what-we-do/partner' ? 'active' : ''}`}>
                                            <h3 className="heading4">
                                                <img className="icon" src={`${imageBasePath}v4/partner.svg`}/>
                                                <span>Partner</span>
                                            </h3>
                                            <p className="paragraph">Take your design efforts to next level</p>
                                        </Link>
                                    </ListItem>
                                </List>
                            </div>
                        </Grid>
                      </Hidden>
                        <Grid item sm={6} md={6} xs={12} className="rightBlk">
                            <List className="list-unstyled menu-listing">
                                <Hidden only={['sm','md','lg','xl']}>
                                  <ListItem className="btnWrapper">
                                      <Link to="/what-we-do/launch" onClick={e => props.toggleMenu(false)} className={props.location.pathname === "/what-we-do/launch" ? "active" : ""}>
                                          <span className="heading3">Launch</span>
                                      </Link>
                                  </ListItem>
                                  <ListItem className="btnWrapper">
                                      <Link to="/what-we-do/accelerate" onClick={e => props.toggleMenu(false)} className={props.location.pathname === "/what-we-do/accelerate" ? "active" : ""}>
                                          <span className="heading3">Accelerate</span>
                                      </Link>
                                  </ListItem>
                                  <ListItem className="btnWrapper">
                                      <Link to="/what-we-do/partner" onClick={e => props.toggleMenu(false)} className={props.location.pathname === "/what-we-do/partner" ? "active" : ""}>
                                          <span className="heading3">Partner</span>
                                      </Link>
                                  </ListItem>
                                </Hidden>
                                <ListItem className="btnWrapper">
                                    <Link to="/work" onClick={e => props.toggleMenu(false)} className={props.location.pathname === "/work" ? "active" : ""}>
                                        <span className="heading3">Our Work</span>
                                    </Link>
                                </ListItem>
                                <ListItem className="btnWrapper">
                                    <Link to="/company" onClick={e => props.toggleMenu(false)} className={props.location.pathname === "/company" ? "active" : ""}>
                                        <span className="heading3">Company</span>
                                    </Link>
                                </ListItem>
                                <ListItem className="btnWrapper">
                                    <Link to="/initiatives" onClick={e => props.toggleMenu(false)} className={props.location.pathname === "/initiatives" ? "active" : ""}>
                                        <span className="heading3">Initiatives</span>
                                    </Link>
                                </ListItem>
                                <ListItem className="btnWrapper">
                                    <Link to="/careers" onClick={e => props.toggleMenu(false)} className={props.location.pathname === "/careers" ? "active" : ""}>
                                        <span className="heading3">Careers</span>
                                        {/* <span className="weAreHiring">We are Hiring</span> */}
                                    </Link>
                                </ListItem>
                                <ListItem className="btnWrapper">
                                    <Link to="/contact" onClick={e => props.toggleMenu(false)} className={props.location.pathname === "/contact" ? "active" : ""}>
                                        <span className="heading3">Connect with us</span>
                                        {/* <span className="weAreHiring">We are Hiring</span> */}
                                    </Link>
                                </ListItem>
                            </List>
                            <Hidden only={['xs']}>
                            <Grid container spacing={2} className="contactUs">
                                <Grid item xs={6}>
                                    <div className="contactUsWrapper">
                                        <h3 className="paragraph">Contact</h3>
                                        <a className="paragraph" href="tel:080 4806 2233">080 4806 2233</a>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="contactUsWrapper">
                                        <h3 className="paragraph">Email</h3>
                                        <a href="mailto:info@designstring.com" className="paragraph">info@designstring.com</a>
                                    </div>
                                </Grid>
                            </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                    <Hidden only={['xs']}>
                    <Grid container alignItems="center" className="menuFooter" alignItems="center">
                        <Grid item xs={6} className="leftBlk">
                            <List className="list-inline">
                                <ListItem className="list-inline-item">
                                    <a href="http://www.linkedin.com/company/designstring"  target="_blank" rel="noopener">
                                        <span className="paragraph">Linkedin</span>
                                    </a>
                                </ListItem>
                                <ListItem className="list-inline-item">
                                    <a href="http://www.twitter.com/design_string"  target="_blank" rel="noopener">
                                        <span className="paragraph">Twitter</span>
                                    </a>
                                </ListItem>
                                <ListItem className="list-inline-item">
                                    <a href="https://dribbble.com/DesignString" target="_blank" rel="noopener">
                                        <span className="paragraph">Dribbble</span>
                                    </a>
                                </ListItem>
                                <ListItem className="list-inline-item">
                                    <a href="http://www.facebook.com/designstringtech"  target="_blank" rel="noopener">
                                        <span className="paragraph">Facebook</span>
                                    </a>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={6} className="rightBlk">
                            <p className="paragraph">© 2021. DesignString Solutions Pvt Ltd. All Rights Reserved</p>
                        </Grid>
                    </Grid>
                    </Hidden>
                </Grid>
            </Container>
        </section>
    )
}
