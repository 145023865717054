import React, {Component} from 'react';
import {Container, Grid} from '@material-ui/core';
import {imageBasePath} from '../../../constants';
import styles from '../../../assets/styles/home.module.scss';

export default function(props) {
  const{feature} = props;
  return(
    <>
    {
      feature ?
      <section className={"whatSetsUsSection"}>
        <Container maxWidth="lg" className={"whatSetsUsContainer"}>
          <Grid container direction="row" spacing={3}>
            <Grid item lg={8} md={8} sm={6} xs={12}>
              <h2 className="heading2">
                {feature.title}
              </h2>
            </Grid>
            {
              feature && feature.list && feature.list.length ?
              <>
                {
                  feature.list.map((characterstic, key) => (
                    <Grid key={key} item lg={4} md={4} sm={6} xs={12}>
                      <div className={"feature"}>
                        <h3 className="subHeading1">{characterstic.step}</h3>
                        <h4 className="heading3">{characterstic.title}</h4>
                        <p className="paragraph">{characterstic.description}</p>
                      </div>
                    </Grid>
                  ))
                }
              </>
              : null
            }
          </Grid>
        </Container>
      </section>
      : null
    }
    </>
  )
}
