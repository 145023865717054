import React from 'react'
import {imageBasePath} from '../constants';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows : false,
    autoplay: true
};

export default function Feedback(props) {
    const{feedbacks} = props;
    return (
        <div className="feedbacksContainer">
            {
                feedbacks && feedbacks.length ?
                <Slider {...settings} >
                    {
                        feedbacks.map((feedback, key) =>(
                        <div key={key} className="feedbackItem">
                            <img src={imageBasePath+'v4/feedback_quotes.png'} alt="quote"/>
                            <h3 className="heading4">{feedback.feed}</h3>
                            <div className="author flex-centered">
                                {
                                    feedback.img ?
                                    <img src={imageBasePath+'v4/'+feedback.img} alt={feedback.author}/>
                                    : null
                                }
                                <div style={ !feedback.img ? {paddingLeft: 0}: {display: 'block'}}>
                                    <h4 className="subHeading1 name">{feedback.author}</h4>
                                    <h5 class="paragraph">{feedback.designation}</h5>
                                    <p class="paragraph">{feedback.company}</p>
                                </div>
                            </div>
                        </div>
                        ))
                    }
                </Slider>
                : null
            }
        </div>
    )
}
