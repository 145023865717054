import React from 'react'
import { Container, Grid } from '@material-ui/core';
import {imageBasePath} from '../../../constants';
import styles from '../../../assets/styles/initiatives.module.scss';

const products = [
    {
      url: 'https://uxdeck.co/',
      img: 'uxdeck_logo.svg',
      productName: 'UXDeck',
      title: 'Bite size insights for better designed apps and software.',
      description: ' Made for digital product teams',
      featuredImg: 'uxdeck_featured_new.jpg',
      colorCode: '#F9F9F9'
    },
    {
      url: 'https://audiogyan.com/',
      img: 'prod_audiogyan.svg',
      productName: 'AudioGyan',
      title: 'A weekly podcast on Design and Arts',
      description: 'Podcast for those interested in Design, Philosophy & Performing arts',
      featuredImg: 'audiogyan_featured.jpg',
      colorCode: '#fff3ef'
    }
  ]

export default function SideProjects(props){
    return(
        <section className={styles.sideProjectsSection}>
            <Container maxWidth="lg" className={styles.sideProjectsContainer}>
                <Grid container direction="row" justify="flex-start" spacing={3}>
                    <Grid xs={12}>
                        <h2 className="heading2">Side Projects</h2>
                    </Grid>
                </Grid>
                {products.map((product, key) => {
                    return(
                        <Grid container key={key} direction="row" alignItems="center" spacing={3}  className={styles.contentRow} style={{'background': `${product.colorCode}`}}>
                            <Grid xs={12} sm={6} md={6}>
                                <p className={styles.logo}>
                                    <a href={product.url} target="_blank">
                                        <img src={imageBasePath+product.img} alt={product.productName} />
                                    </a>
                                </p>
                                <h3 className="heading2">{product.title}</h3>
                                <p className={styles.paragraph + ' paragraph'}>{product.description}</p>
                                <p>
                                    <a href={product.url} className="cta-btn" target="_blank">
                                        <span>Go to live</span>
                                        <img className="inactive" src={imageBasePath + 'go_arrow.svg'} alt="next"/>
                                        <img className="active" src={imageBasePath + 'go_arrow_white.svg'} alt="next"/>
                                    </a>
                                </p>
                            </Grid>
                            <Grid xs={12} sm={6} md={6}>
                                <div className={styles.imgBlk}>
                                    <img src={imageBasePath+product.featuredImg} alt={product.productName} />
                                </div>
                            </Grid>
                        </Grid>
                    )
                })}
            </Container>
        </section>
    )
}