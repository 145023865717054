import React, { Component } from 'react'
import {Hero, Bg, Challenge, Solution, Feedback, Results, FeaturedWorks} from '../components'
import styles from '../../../assets/styles/casestudy_detail.module.scss';
import {FooterTop} from '../../../shared_elements/'
import {CaseStudies} from '../../home/components';
import { imageBasePath } from '../../../constants';
import {Helmet} from "react-helmet";

export class MyoPalCaseStudy extends Component {
    constructor(props){
        super(props);
        this.state = {
            pageName : 'myopal',
            heroDetails: {
                title : 'Creating awareness about Juvenile Myositis and providing an easy and convenient way to conduct the CMAS test at home',
                subtitle: <>Myopal was born out of the lack of awareness about Myositis in the general public as well as medical professionals. Due to the COVID-19 pandemic, specialized diagnosis further became a constraint. Abhishek Bhalla, who himself remained undiagnosed for many years until he finally met his doctors, took it upon himself to help create an app that can facilitate the early detection of Juvenile Myositis. <br/> <br/>The app was to be based on the universally recognized Childhood Myositis Assessment Scale (CMAS) to detect early symptoms of Myositis in children - a simple self-assessment app with 14 activities that a child has to perform to rule out Myositis. Each activity was to be timed and rated and a consolidated score decided whether a child may or may not have Myositis.</>,
                type: 'Launch',
                typeImage: 'launch.svg',
                logo: 'myopal_logo.svg',
                category: 'Healthtech ',
                geo: 'Global',
                output : 'Web experience'
            },
            bg:{
                img : 'myopal_bg.png'
            },
            challenge : 'The challenge was to create the app in such a way that it would provide all the necessary information in a simple, understandable and friendly manner and without causing distress among the caregivers. Empathetic understanding of the user was key.',
            productType : 'website',
            solution: {
                description : [
                    `A team of 4 DesignString specialists was deployed to bring the idea to fruition. From conceptualization to implementation to final launch, it took us approx 2 months. Using an agile methodology and regular communication with the client, we were able to deliver an application with flawless experience across devices. With the Myopal app, the user could:`
                ],
                list : [
                    {
                        title: 'Take interactive assessment',
                        link: 'myopal/myopal_take_assessment.mp4'
                    },
                    {
                        title: 'Take multiple tests & save the results',
                        link: 'myopal/myopal_save_test.mp4'
                    },
                    {
                        title: 'Download and email reports',
                        link: 'myopal/myopal_report.mp4'
                    },
                ],
                media: {
                    product_type: 'website',
                    media_type: 'video',
                }
            },
            disignsImage : '',
            clientFeedback: {
                feedback: "The most impressive thing for us is their thorough professionalism and excellent domain expertise. It was an incredible experience working with the entire DesignString team who ensured the successful delivery within strict timelines. Every member of the team ensured the task is well understood, shared their suggestions at every stage and got the iterations made wherever required which finally resulted in an application with fantastic user experience",
                clientDetail: {
                    img: 'syed_dp.png',
                    name: 'Syed Waqarul Haque,',
                    designation: 'Head of Project',
                    company: 'MyoPal'
                }
            },
            result: 'Myopal was unveiled in an international conference on Myosotis and it was very well received. Few international doctors mentioned that in the days of the pandemic, telemedicine tests like this will be very useful and the way forward. Myopal also finds a mention in IMACS (International Myositis Assessment & Clinical Studies Group) annual newsletter (Jan 2021).',
            availableon: [
                {
                    img : 'web_version.svg',
                    link: 'https://myopal.app/'
                }
            ],
            caseStudies : [
                {
                    title: 'Kaleyra',
                    featuredImage: 'kaleyra_work_cover.png',
                    type: 'Accelerate',
                    typeImage: 'accelerate.svg',
                    logo: 'kaleyra_dp.png',
                    description: 'Unifying multiple interfaces to build a single self-serve flagship product and cut down on overhead costs.',
                    link: '/work/kaleyra/',
                },
                {
                    title: 'OneSky',
                    featuredImage: 'onesky_work_cover.png',
                    type: 'Launch',
                    typeImage: 'launch.svg',
                    logo: 'onesky_dp.png',
                    description: 'Translating complex airspace rules and regulations into coherent and ready-to-use information for small UAS and drone operators.',
                    link: '/work/onesky/'
                }
            ],
            writeClutchReview: 'https://clutch.co/profile/designstring#review-1550529'

        }
    }
    render() {
        const{pageName, heroDetails, bg, challenge, productType, solution, clientFeedback, result, availableon, caseStudies, disignsImage, writeClutchReview} =this.state;
        return (
            <div className={styles.caseStudyDetailPage}>
                <Helmet>
                    <title>DesignString: Case Study Myopal, Category: Healthtech</title>
                    <meta id="meta-description" name="description" content="How we helped create awareness about Juvenile Myositis & a convenient way to conduct CMAS at home" />
                    <meta id="og-title" property="og:title" content="DesignString: Case Study Myopal, Category: Healthtech" />
                    <meta id="og-description" property="og:description" content="How we helped create awareness about Juvenile Myositis & a convenient way to conduct CMAS at home" />
                    <meta id="og-image" property="og:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/myopal.jpg" />
                    <meta id="og-url" property="og:url" content="https://designstring.com/work/myopal" />
                    <meta id="twitter-title" property="twitter:title" content="DesignString: Case Study Myopal, Category: Healthtech" />
                    <meta id="twitter-description" property="twitter:description" content="How we helped create awareness about Juvenile Myositis & a convenient way to conduct CMAS at home" />
                    <meta id="twitter-image" property="twitter:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/myopal.jpg" />
                    <meta id="twitter-url" property="twitter:url" content="https://designstring.com/work/myopal" />
                    <meta id="twitter-card" property="twitter:card" content="summary_large_image" />
                </Helmet>
                <section className={styles.heroSection}>
                    <Hero pageName={pageName} heroDetails={heroDetails} />
                </section>
                <section className={styles.bgSection}>
                    <Bg pageName={pageName} bg={bg} />
                </section>
                <section className={styles.challengeSection}>
                    <Challenge pageName={pageName} challenge={challenge} />
                </section>
                <section className={styles.solutionSection + ' solutionSection ' + pageName}>
                    <Solution pageName={pageName} productType={productType} solution={solution} />
                </section>
                {
                    disignsImage ?
                    <section className={styles.disignsImageSection+' backgroundProp'} style={{ backgroundImage : `url(${imageBasePath+'v4/'+disignsImage})` }}>

                    </section>
                    : null
                }
                <section className={styles.feedbackSection}>
                    <Feedback pageName={pageName} clientFeedback={clientFeedback} writeClutchReview={writeClutchReview}/>
                </section>
                <section className={styles.resultSection}>
                    <Results pageName={pageName} result={result} availableon={availableon} />
                </section>
                <section className={styles.featuredWork}>
                    <CaseStudies caseStudies={caseStudies} />
                </section>
                <FooterTop link="contact" title={<>Ready to launch your product to the world?</>} />
            </div>
        )
    }
}


export default MyoPalCaseStudy
