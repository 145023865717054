import React, {} from 'react';
import {Container, Grid} from '@material-ui/core';
import {imageBasePath} from '../../../constants';
import styles from '../../../assets/styles/tinycanvasdetail.module.scss';
import { Link } from 'react-router-dom';


export default function(props) {
  return(
    <footer className={styles.tinyCanvasFooter}>
      <Container maxWidth="xl">
        <Grid container direction="row" justify="center" alignItems="center" >
          <Grid item xs={12} className={styles.copyrightsContainer}>
            <p className="paragraph">© 2021 <Link to="/">DesignString Solutions Pvt Ltd </Link>. All Rights Reserved</p>
          </Grid>
        </Grid>
      </Container>
    </footer>
  )
}
