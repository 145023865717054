import React, { Component } from 'react';
import {Container, Grid} from '@material-ui/core';
import styles from '../../../assets/styles/whatwedo.module.scss';
import {Banner, Tabs, Process} from '../components';
import {CaseStudies, WhatSetsUs} from '../../home/components';
import {FooterTop} from '../../../shared_elements/'
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Scrollspy from 'react-scrollspy';
import {Helmet} from "react-helmet";
class Partner extends Component {
    constructor(props){
        super(props);
        this.state = {
            activeTab : 1,
            bannerDetails : {
                type: 'Partner',
                typeImage : 'partner.svg',
                title : <>Take your design efforts to <br/> the next level</>,
                subtitle: <>Ideal for digital products with a fragmentary or no design team looking to streamline their user experience effort.</>
            },
            sliderDetails : [
                {
                    link: '/work/rapido/',
                    product_img: 'rapido_featured_home.png'
                },
                {
                    link: '/work/blackbuck/',
                    product_img: 'blackbuck_featured_home.png'
                }
            ],
            tabTitles : [
                {
                    index : 1,
                    title : 'Right fit for',
                    ref : 'tabsRef'
                },
                {
                    index : 2,
                    title : 'Process',
                    ref : 'processRef'
                },
                {
                    index : 3,
                    title : 'Case Studies',
                    ref : 'caseStudiesRef'
                },
                {
                    index : 4,
                    title : 'Why DesignString',
                    ref : 'whyRef'
                }
            ],
            tabContent : {
                '1' : {
                    title : 'We are the right fit if you',
                    descriptionLeft: 'Have developers driving your UX design, but now you want to bring in experts.',
                    descriptionRight: 'Have an in-house UX design team, but need more skilled hands on the deck.'
                }
            },
            processContent : [
                {
                    img : 'analysis.png',
                    title : 'Analysis',
                    description: 'Qualitative and quantitative analysis of the current status of the product.'
                },
                {
                    img : 'handpick.png',
                    title : 'Handpick',
                    description: 'Handpick an apt team-right skill set, experience and mindset -for the product.'
                },
                {
                    img : 'collaborate.png',
                    title : 'Collaborate',
                    description: 'Collaborate across departments to garner the right inputs for product growth.'
                },
                {
                    img : 'deadlines.png',
                    title : 'Deadlines',
                    description: 'Ensure all delivery deadlines are adhered to.'
                }
            ],
            quote : 'All our specialists attend weekly and monthly workshops and activities to stay updated on the latest market trends. An active DesignString design community also facilitates inputs/reviews by peers and seniors.',
            caseStudies : [
                {
                  title: 'Rapido',
                  featuredImage: 'rapido_work_cover.png',
                  logo: 'rapido_dp.png',
                  description: 'Streamlining UX efforts by filling in gaps in skill sets and setting up established design processes',
                  link: '/work/rapido/'
                },
                {
                  title: 'BookMyShow',
                  featuredImage: 'bookmyshow_work_cover.png',
                  logo: 'bookmyshow_dp.png',
                  description: 'Scaling up design efforts with speed and perfection to meet the growing needs of a highly competitive market',
                  link: '/work/bookmyshow/',
                },
                {
                  title: 'Blackbuck',
                  featuredImage: 'blackbuck_work_cover.png',
                  logo: 'blackbuck_dp.png',
                  description: 'Establishing a consistent design language and restructuring user flows for an enhanced digital experience of a Trucking app',
                  link: '/work/blackbuck/'
                },
            ],
            feature : {
                title : <>Why DesignString <br/> to launch my next product ?</>,
                list : [
                    {
                        step : '01',
                        title: 'Collaborative approach',
                        description:'Designers with exceptional people skills who can work side by side with internal teams across functions to get the job done.'
                    },
                    {
                        step : '02',
                        title: 'Committed & Accountable',
                        description:'We work as your own internal team and leave no stones unturned to achieve your success.'
                    },
                    {
                        step : '03',
                        title: 'No worries around setting up',
                        description:'You do not have to worry about operational costs like payroll, tools and so on.'
                    },
                    {
                        step : '04',
                        title: 'Team starts delivering from Day 1',
                        description:'Experienced designers skilled at working on core product designs across SaaS, Mobile Apps, B2B / B2C Apps.'
                    },
                    {
                        step : '05',
                        title: 'Perfected process',
                        description:'Adaptive design to suit product needs and provide a framework to build upon.'
                    }
                ]
            },
            feedbacks:[
                {
                  feed : 'We appreciated how obsessive they were about design, making sure the platform focused on the user\'s needs',
                  img: 'rishikesh_dp.png',
                  author: 'Rishikesh S R',
                  designation: 'Co-founder',
                  company: 'Rapido'
                },
                {
                  feed : 'They’re very receptive to taking feedback',
                  img: '',
                  author: 'Sushant Mittal',
                  designation: 'Group Product Manager',
                  company: 'BookMyShow'
                },
                {
                  feed : 'My experience with DesignString been really good. Both interms of people and commitment.',
                  img: 'manish_dp.png',
                  author: 'Manish Singh',
                  designation: 'Head of Products',
                  company: 'Blackbuck'
                }
            ]
        }
        this.tabsRef = React.createRef();
        this.processRef = React.createRef();
        this.caseStudiesRef = React.createRef();
        this.whyRef = React.createRef();
    }

    scrollToSection = (ref) => {
        this[ref].current.scrollIntoView({block:'start', behavior: "smooth"});
    }

    render() {
        const{feedbacks, activeTab, bannerDetails, sliderDetails, tabTitles, tabContent, processContent, quote, caseStudies, feature} = this.state;
        return (
            <div class={styles.lauchPage}>
            <Helmet>
              <title>DesignString - Streamline your UI/UX design efforts with us</title>
              <meta id="meta-description" name="description" content="Collaborative, committed and accountable, our team delivers on your product’s UI/UX challenges right from Day 1" />
              <meta id="og-title" property="og:title" content="DesignString - Streamline your UI/UX design efforts with us" />
              <meta id="og-description" property="og:description" content="Collaborative, committed and accountable, our team delivers on your product’s UI/UX challenges right from Day 1" />
              <meta id="og-image" property="og:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/partner.jpg" />
              <meta id="og-url" property="og:url" content="https://designstring.com/what-we-do/partner" />
              <meta id="twitter-title" property="twitter:title" content="DesignString - Streamline your UI/UX design efforts with us" />
              <meta id="twitter-description" property="twitter:description" content="Collaborative, committed and accountable, our team delivers on your product’s UI/UX challenges right from Day 1" />
              <meta id="twitter-image" property="twitter:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/partner.jpg" />
              <meta id="twitter-url" property="twitter:url" content="https://designstring.com/what-we-do/partner" />
              <meta id="twitter-card" property="twitter:card" content="summary_large_image" />
            </Helmet>

                <section class={styles.bannerSection}>
                    <Banner bannerDetails={bannerDetails} sliderDetails={sliderDetails}/>
                </section>
                <section className={styles.tabsTitleSection}>
                    <div className={styles.tabsContainer}>
                        <Container maxWidth="lg">
                            <Grid container direction="row" className={styles.tabsListContainer}>
                                <Scrollspy
                                    items={['tab-1', 'tab-2', 'tab-3', 'tab-4']}
                                    currentClassName={styles.active}
                                    // offset={-100}
                                    className="flex-not-centered list-inline"
                                >
                                {
                                    tabTitles.map((title, key) => (
                                        <li class="list-inline-item" key={key} >
                                            <AnchorLink href={`#tab-${title.index}`} className={"paragraph"}>
                                                {title.title}
                                            </AnchorLink>
                                        </li>
                                    ))
                                }
                                </Scrollspy>
                            </Grid>
                        </Container>
                    </div>
                </section>
                <section class={styles.tabsSection} ref={this.tabsRef} id="tab-1">
                    <Tabs scrollToSection={this.scrollToSection} tabTitles={tabTitles} tabContent={tabContent} />
                </section>
                <section class={styles.processSection} ref={this.processRef} id="tab-2">
                    <Process processContent={processContent} quote={quote} />
                </section>
                <div ref={this.caseStudiesRef} id="tab-3">
                    <CaseStudies feedbacks={feedbacks} caseStudies={caseStudies} />
                </div>
                <div ref={this.whyRef} id="tab-4">
                    <WhatSetsUs feature={feature}/>
                </div>
                <FooterTop link="contact" title={<>Ready to partner to scale up your design efforts ?</>} />
            </div>
        )
    }
}

export default Partner
