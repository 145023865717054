import React from 'react'
import {Container, Grid} from '@material-ui/core'
import {imageBasePath} from '../../../constants';
import styles from '../../../assets/styles/casestudy_detail.module.scss';

export default function Results(props) {
    const{result, availableon}= props;
    return (
        <Container maxWidth="lg">
            <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={8} md={7}>
                    <h2 className={styles.sectionTitle+ " heading2"}>The result</h2>
                    <p className={styles.sectionSubtitle+" paragraph"}>
                        {result}
                    </p>
                    {
                        availableon && availableon.length ?
                        <div className={styles.appsLocation}>                    
                            <ul class="list-inline">
                                {
                                   availableon.map((prod, key) => (
                                    <li key={key} class="list-inline-item">
                                        <a href={prod.link} target="_blank">
                                            <img style={{height:"64px" }} src={imageBasePath+'v4/'+prod.img} alt="image" />
                                        </a>
                                    </li>
                                   ))
                                }
                            </ul>
                        </div>
                        :
                        null
                    }

                </Grid>
            </Grid>
        </Container>
    )
}
