import OneskyCaseStudy from './containers/OneskyCaseStudy';
import AmbyCaseStudy from './containers/AmbyCaseStudy';
import MyoPalCaseStudy from './containers/MyoPalCaseStudy';
import RapidoCaseStudy from './containers/RapidoCaseStudy';
import Blackbuck from './containers/Blackbuck';
import Cleartrip from './containers/Cleartrip';
import Kaleyra from './containers/Kaleyra';
import BookMyShow from './containers/BookMyShow';
import Kargo from './containers/Kargo';
import GTM from './containers/GTM';
import pageLayoutHoc from '../../hocs/pageLayoutHoc';
export const caseStudiesRoutes = [
  {
    path: '/work/onesky',
    component : pageLayoutHoc(OneskyCaseStudy, {pageName : 'OneSky'}),
    key: 'onesky'
  },
  {
    path: '/work/amby',
    component : pageLayoutHoc(AmbyCaseStudy, {pageName : 'Amby'}),
    key: 'amby'
  },
  {
    path: '/work/myopal',
    component : pageLayoutHoc(MyoPalCaseStudy, {pageName : 'MyoPal'}),
    key: 'amby'
  },
  {
    path: '/work/rapido',
    component : pageLayoutHoc(RapidoCaseStudy, {pageName : 'Rapido'}),
    key: 'rapido'
  },
  {
    path: '/work/blackbuck',
    component : pageLayoutHoc(Blackbuck, {pageName : 'Blackbuck'}),
    key: 'blackbuck'
  },
  {
    path: '/work/kaleyra',
    component : pageLayoutHoc(Kaleyra, {pageName : 'Kaleyra'}),
    key: 'kaleyra'
  },
  {
    path: '/work/cleartrip',
    component : pageLayoutHoc(Cleartrip, {pageName : 'Cleartrip'}),
    key: 'cleartrip'
  },
  {
    path: '/work/bookmyshow',
    component : pageLayoutHoc(BookMyShow, {pageName : 'BookMyShow'}),
    key: 'bookmyshow'
  },
  {
    path: '/work/kargo',
    component : pageLayoutHoc(Kargo, {pageName : 'Kargo'}),
    key: 'kargo'
  },
  {
    path: '/work/gtm',
    component : pageLayoutHoc(GTM, {pageName : 'GotoMarkter'}),
    key: 'gtm'
  }
]
