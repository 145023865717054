import React from 'react'
import {Container, Grid} from '@material-ui/core';
import { Link, withRouter } from "react-router-dom";
import {imageBasePath} from '../constants';

export default function FooterTop(props) {
    const{title, link} = props;
    return (
        <section className="footerTop">
            <Container maxWidth="lg">
                <Grid container direction="row">
                    <Grid item md={6} sm={12} xs={12}>
                    <h2 className="heading2">{title}</h2>
                    <p>
                        <Link to={`/${link}`} className="secondaryBtn">
                            <span className="paragraph">Let’s talk</span>
                            <img src={ imageBasePath+'v4/go_arrow.svg' } alt="Image" className="inActive"/>
                        </Link>
                    </p>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}
