import  TinyCanvas from './containers/TinyCanvas';
import  TinyCanvasDetail from './containers/TinyCanvasDetail';

import pageLayoutHoc from '../../hocs/pageLayoutHoc';
export const tinyCanvasRoutes = [
  {
    path: '/tinycanvas',
    component : pageLayoutHoc(TinyCanvas, {pageName : 'TinyCanvas'}),
    key: 'tinycanvas'
  },
  {
    path: '/tinycanvas/detail',
    component : pageLayoutHoc(TinyCanvasDetail, {pageName : 'TinyCanvas'}),
    key: 'tinycanvas-detail'
  }
]
