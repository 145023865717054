import React, {Component} from 'react';
import {Container, Grid} from '@material-ui/core';
import {imageBasePath} from '../../../constants';
import styles from '../../../assets/styles/initiatives.module.scss';

const plugins = [
  {
    img: 'product_tinycanvas.svg',
    title: 'TinyCanvas',
    description: 'Compress images in a click for optimized load time. A figma plugin which will enable designers to export compressed JPG / PNG',
    pluginUrl : '/tinycanvas',
    installCount: '5k'
  },
  {
    img: 'product_giffycanvas.svg',
    title: 'GiffyCanvas',
    description: 'Generate Giffs out of Figma with no restriction on number of frames. ',
    pluginUrl : '/giffycanvas',
    installCount: '12k'
  },
]
export default function Plugins(props) {
  return(
    <section className={styles.pluginsSection}>
      <Container maxWidth="lg" className={styles.pluginsContainer}>
        <Grid container direction="row"  justify="flex-start"  spacing={3} className={styles.titleRow}>
          <Grid xs={12}>
            <h2 className="heading2">Plugins</h2>
            <p className="subHeading2Reg">Figma Community plugin to automate design tasks</p>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={3} className={styles.contentRow}>
        {
          plugins.map((plugin, key) => {
            return(
              <Grid xs={12} sm={6} md={6} className="flex-not-centered">
                    <div className={styles.cardWrapper}>
                      <a href={plugin.pluginUrl} target="_blank">
                        <p className={styles.logoWrapper}>
                            <img src={ imageBasePath+ plugin.img } alt={plugin.title} />
                            <span className={`${styles.count} paragraph`}><span>{plugin.installCount}+</span> installs</span>
                        </p>
                        <p className={styles.paragraph + ' paragraph'}>{plugin.description}</p>
                        <p className={styles.figmaBtn}>
                          <img src={imageBasePath+'figma_plugin.svg'} alt="Figma Plugin" />
                          <img className={styles.inactive + ' inactive'} src={imageBasePath + 'go_arrow.svg'} alt="next"/>
                        </p>
                      </a>
                    </div>
              </Grid>
            )
          })
        }
        </Grid>
      </Container>
    </section>
  )
}
