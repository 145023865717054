import React, {Component} from 'react';
import {Container, Grid} from '@material-ui/core';
import {imageBasePath} from '../../../constants';
import styles from '../../../assets/styles/tinycanvas.module.scss';
import { Link } from "react-router-dom";
import LazyLoad from 'react-lazyload';
export default function(props) {
  return(
    <Container maxWidth="md" className={styles.bannerContainer}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          <p className={styles.tinycanvasLogo}>
            <Link to="/tinycanvas">
              <img src={imageBasePath + 'tinycanvas_logo.svg'} alt="TinyCanvas"/>
            </Link>
          </p>
          <h1 className="heading2">Exports Compressed Images in a Click!</h1>
          <p className="sub-heading2">Optimized PNG and JPEG images help in achieving faster load time</p>
          <p className={styles.figmaBtnBlk}>
            <a className="tryFigmaBtn" href="https://www.figma.com/c/plugin/794829197801903069/TinyCanvas" target="_blank" rel="noopener">
              <span>Try for </span>
              <img src={imageBasePath + 'figma_icon.svg'} alt="Figma" />
              <span>Figma</span>
            </a>
          </p>
          <div className={styles.bannerImg}>
            <LazyLoad height={450} once >
            <img src={imageBasePath + 'tinycanvas_banner.png'} alt="Banner"/>
            </LazyLoad>
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}
