import React, {} from 'react';
import {Container, Grid } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import {imageBasePath} from '../../../constants';
import styles from '../../../assets/styles/tinycanvasdetail.module.scss';
import LazyLoad from 'react-lazyload';
const canvas = [
  {
    img: 'install_plugin.jpg',
    heading: 'Install Plugin',
    paragraph: "<p>Install the ‘TinyCanvas’ plugin from <br /> ‘Plugins’ section of Figma.</p>"
  },
  {
    img: 'tiny_png.jpg',
    heading: 'Get your API Key',
    paragraph: "<p>Go to <a href='https://tinypng.com/developers'  rel='noopener' target='_blank'>TinyPNG</a> website and click on <br /> ‘Developer API’. Click on the ‘Get your API’ <br /> key button by providing your name and <br />valid email address.</p>"
  },
  {
    img: 'copy_paste_api_key.jpg',
    heading: 'Copy and paste the API Key',
    paragraph: "<p>Verification mail sent to the provided email address. <br /> Now click on ‘Visit your dashboard’ button from <br />email and copy the API Key and paste it in<br />tinyCanvas API Key section and “Get Started”.</p>"
  },
  {
    img: 'start_compressing.jpg',
    heading: 'Start Compressing',
    paragraph: "<p>Select the images you want to compress <br /> and click the TinyCanvas plugin and <br /> download.</p>"
  },
]

export default function(props) {
  return(
    <>
      <Container maxWidth="md" className={styles.howSectionContainer}>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={12}>
            <div className={styles.backArrow}>
              <Link to="/tinycanvas" className="sub-heading2 color_black">
                <img src={ imageBasePath+'back2.svg' } alt="Back"/>
                <span className={styles.back + " " +"sub-heading2"}>Back</span>
              </Link>
            </div>
            <h1 className={styles.howItWorks + ' heading2'}>How it works?</h1>
          </Grid>
        </Grid>
          {
            canvas.map((canvas, key) => {
              return(
                <Grid container key={key} direction="row" className={styles.canvasCard} justify="flex-start" alignItems="center" spacing={isMobile ? 2 : 8}>
                  <Grid item md={6} xs={12}>
                    <LazyLoad height={350} once >
                    <img src={ imageBasePath+ canvas.img } alt="Image"/>
                    </LazyLoad>
                  </Grid>
                  <Grid item md={6} xs={12} className={styles.rightBlk}>
                    <h2 className="heading2">{canvas.heading}</h2>
                    <div className="paragraph" dangerouslySetInnerHTML={{__html: canvas.paragraph}}></div>
                  </Grid>
                </Grid>
              )
            })
          }
      </Container>
    </>
  )
}
