import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Dialog, Hidden} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import {imageBasePath} from '../../../constants';

const styles = theme => ({
  root: {
    margin: 0,
    padding: 0,
  },
  closeButton: {
    position: 'fixed',
    right: 24,
    top: 24,
    color: theme.palette.grey[500],
    padding:0,
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function VideoDialog(props) {
  const { activeMedia, solution }= props;

  return (
    <div>
      <Dialog maxWidth="md" onClose={props.onClose} aria-labelledby="customized-dialog-title" open={props.open}
        className={solution.media.product_type == 'website' ? 'vedioDialogWebsite vedioDialog' : ' vedioDialog'}>
        <DialogTitle id="customized-dialog-title" onClose={props.onClose} style={{ padding: '0' }}>
        </DialogTitle>
        <DialogContent dividers >
            <Hidden only={['xs', 'sm']}>
              <video controls="controls" autoPlay width="100%" height="600" style={{ objectFit: 'contain' }}>
                <source
                    src={imageBasePath+'v4/'+activeMedia}
                    type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
              <video controls="controls" height="380" width="100%" style={{ objectFit: 'contain' }}>
                  <source
                      src={imageBasePath+'v4/'+activeMedia}
                      type="video/mp4"
                  />
                  Your browser does not support the video tag.
              </video>
            </Hidden>
        </DialogContent>
      </Dialog>
    </div>
  );
}
