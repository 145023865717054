import React, { Component } from 'react'
import {Hero, Bg, Challenge, Solution, Feedback, Results, FeaturedWorks} from '../components'
import styles from '../../../assets/styles/casestudy_detail.module.scss';
import {FooterTop} from '../../../shared_elements/'
import {CaseStudies} from '../../home/components';
import { imageBasePath } from '../../../constants';
import {Helmet} from "react-helmet";

export class GTM extends Component {
    constructor(props){
        super(props);
        this.state = {
            pageName : 'amby',
            heroDetails: {
                title : 'Making OKR-based goal-setting and management as easy and adoptable as an excel spreadsheet',
                subtitle: 'DesignString has been using the OKR framework for managing its goals for a while. It had developed a simple Slack bot to manage goals and check-ins for its internal team. Seeing the success of the bot with the internal team, came the idea of developing it into a full-fledged product and launching it for external use.',
                type: 'Launch',
                typeImage: 'launch.svg',
                logo: 'amby_logo.svg',
                category: 'OKR Softwares / Productivity tools',
                geo: 'Global ( Primary market: United States of America, Europe )',
                output : 'Web experience & Slack Integration'
            }, 
            bg:{
                img : 'amby_bg.png'
            },
            challenge : 'The challenge was that the OKRs software space was already quite competitive with major players like BetterWorks, Workboard, Ally and GtmHub having a strong user base. Though this and the success with our internal team was a proof of our concept, we had to build something substantially better that could face these goliaths',
            productType : 'website',
            solution: {
                description : [
                    'Through an in-depth research we identified that the primary obstacle in OKR tool adoption by teams was its user experience. Many found the learning curve too steep and gave up. While the OKR framework was flexible in nature, many tools made it rigid with a set of stringent goals.',
                    'While most OKR tools targeted enterprises in their pricing, we made a conscious decision to target growing businesses to make OKR-based goal setting and management more inclusive. The ease with which it could be integrated with Slack formed a crucial aspect of our offering. Moreover, Amby was adaptable and businesses could use it as per their specific needs. We aimed at making the user experience of Amby so seamless and intuitive that it felt like using an excel spreadsheet : '
                ],
                list : [
                    {
                        title: 'Interactive Onboarding',
                        link: 'amby/amby_onboarding.mp4'
                    },
                    {
                        title: 'Tree view & quick actions',
                        link: 'amby/amby_browse.mp4'
                    },
                    {
                        title: 'Billing module with multiple subscription plans',
                        link: 'amby/amby_billing.mp4'
                    }
                ],
                media: {
                    product_type: 'website',
                    media_type: 'video',
                }
            },
            disignsImage : '', 
            clientFeedback: {
                feedback: "We used to do OKRs on Google sheets which worked well at the beginning but then weren't helpful as the team started growing and objectives became multi-faceted. After trying out other OKR products in the market, we decided on Amby. It's very simple yet powerful. Adding the team was a breeze. Plus we really liked the analytics and the Slack integration.",
                clientDetail: {
                    img: 'aniketh.jpg',
                    name: 'Sahil A',
                    designation: 'Co-Founder and CEO',
                    company: 'Small-Business'
                }
            },
            result: 'Amby was designed and launched in a record time of 6-8 weeks. From product conceptualization to final launch, everything was done internally. With its seamless and intuitive user experience, bird’s eye view approach and analytics, it has made a competitive presence in the market. With minimal product marketing, Amby achieved 100+ business sign ups within a few weeks of its launch.',
            availableon: [
                {
                    img : 'web_version.svg',
                    link: 'https://app.amby.co'
                }
            ],
            caseStudies : [
                {
                  title: 'OneSky',
                  featuredImage: 'onesky_work_cover.png',
                  type: 'Launch',
                  typeImage: 'launch.svg',
                  logo: 'onesky_dp.png',
                  description: 'Translating complex airspace rules and regulations into coherent and ready-to-use information for small UAS and drone operators.',
                  link: '/work/onesky/' 
                },
                {
                  title: 'MyoPal',
                  featuredImage: 'myopal_work_cover.png',
                  type: 'launch',
                  typeImage: 'launch.svg',
                  logo: 'myopal_dp.png',
                  description: 'Creating awareness about Juvenile Myositis and providing an easy and convenient way to conduct the CMAS test at home', 
                  link: '/work/myopal/', 
                }
            ],
            writeClutchReview: ''
        }
    }
    render() {
        const{pageName, heroDetails, bg, challenge, productType, solution, clientFeedback, result, availableon, caseStudies, disignsImage, writeClutchReview} =this.state;
        return ( 
            <div className={styles.caseStudyDetailPage}>
                <Helmet>
                    <title>Top UX / UI Design firm for SaaS &  Mobile apps</title>
                    <meta id="meta-description" name="description" content="We deliver results to tech-driven businesses through UX and UI design, help to build fantastic product experiences. Primary focus on SaaS &  Mobile apps" />
                    <meta id="og-title" property="og:title" content="Top UX / UI Design firm for SaaS &  Mobile apps" />
                    <meta id="og-description" property="og:description" content="We deliver results to tech-driven businesses through UX and UI design, help to build fantastic product experiences. Primary focus on SaaS &  Mobile apps" />
                    <meta id="og-image" property="og:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/meta_image_v2.png" />
                    <meta id="og-url" property="og:url" content="https://designstring.com/" />
                    <meta id="twitter-title" property="twitter:title" content="Top UX / UI Design firm for SaaS &  Mobile apps" />
                    <meta id="twitter-description" property="twitter:description" content="We deliver results to tech-driven businesses through UX and UI design, help to build fantastic product experiences. Primary focus on SaaS &  Mobile apps" />
                    <meta id="twitter-image" property="twitter:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/meta_image_v2.png" />
                    <meta id="twitter-url" property="twitter:url" content="https://designstring.com/" />
                    <meta id="twitter-card" property="twitter:card" content="summary_large_image" />
                </Helmet>
                <section className={styles.heroSection}>
                    <Hero pageName={pageName} heroDetails={heroDetails} />
                </section>
                <section className={styles.bgSection}>
                    <Bg pageName={pageName} bg={bg} />
                </section>
                <section className={styles.challengeSection}>
                    <Challenge pageName={pageName} challenge={challenge} />
                </section>
                <section className={styles.solutionSection}>
                    <Solution pageName={pageName} productType={productType} solution={solution} />
                </section>
                {
                    disignsImage ?
                    <section className={styles.disignsImageSection+' backgroundProp'} style={{ backgroundImage : `url(${imageBasePath+'v4/'+disignsImage})` }}>

                    </section>
                    : null
                }
                <section className={styles.feedbackSection}>
                    <Feedback pageName={pageName} clientFeedback={clientFeedback} writeClutchReview={writeClutchReview} />
                </section>
                <section className={styles.resultSection}>
                    <Results pageName={pageName} result={result} availableon={availableon} />
                </section>
                <section className={styles.featuredWork}>
                    <CaseStudies caseStudies={caseStudies} />
                </section>
                <FooterTop link="contact" title={<>Ready to launch your product to the world?</>} />
            </div>
        )
    }
}

export default GTM
