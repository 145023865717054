import React, {Component} from 'react';
import {Container, Grid } from '@material-ui/core';
import styles from '../../../assets/styles/tinycanvasdetail.module.scss';
import {Header, How, Footer} from '../components';
import {Helmet} from "react-helmet";


export default class TinyCanvasDetail extends Component{
  constructor(props){
    super(props);
  }

  render(){
    return(
      <>
      <div className={styles.tinycanvasPage+ " " +"background-prop"}>
        <Helmet>
          <title>Top UX / UI Design firm for SaaS &  Mobile apps</title>
          <meta id="meta-description" name="description" content="We deliver results to tech-driven businesses through UX and UI design, help to build fantastic product experiences. Primary focus on SaaS &  Mobile apps" />
          <meta id="og-title" property="og:title" content="Top UX / UI Design firm for SaaS &  Mobile apps" />
          <meta id="og-description" property="og:description" content="We deliver results to tech-driven businesses through UX and UI design, help to build fantastic product experiences. Primary focus on SaaS &  Mobile apps" />
          <meta id="og-image" property="og:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/meta_image_v2.png" />
          <meta id="og-url" property="og:url" content="https://designstring.com/" />
          <meta id="twitter-title" property="twitter:title" content="Top UX / UI Design firm for SaaS &  Mobile apps" />
          <meta id="twitter-description" property="twitter:description" content="We deliver results to tech-driven businesses through UX and UI design, help to build fantastic product experiences. Primary focus on SaaS &  Mobile apps" />
          <meta id="twitter-image" property="twitter:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/meta_image_v2.png" />
          <meta id="twitter-url" property="twitter:url" content="https://designstring.com/" />
          <meta id="twitter-card" property="twitter:card" content="summary_large_image" />
        </Helmet>
        <Header />
        <How />
        <Footer />
      </div>
      </>

    )
  }
}
