import React, { useState } from 'react'
import { CaseStudies } from '../../home/components'
import { FooterTop, PageBio } from '../../../shared_elements';
import styles from '../../../assets/styles/work.module.scss';
import {Helmet} from "react-helmet";

export default function Work(props){
    const [caseStudies, setCaseStudies] = useState([
        {
            title: 'Amby',
            featuredImage: 'amby_work_cover.png',
            type: 'Launch',
            typeImage: 'launch.svg',
            logo: 'amby_dp.png',
            description: 'Making OKR-based goal-setting and management as easy and adoptable as an excel spreadsheet.',
            link: '/work/amby/'

          },
          {
            title: 'Kaleyra',
            featuredImage: 'kaleyra_work_cover.png',
            type: 'Accelerate',
            typeImage: 'accelerate.svg',
            logo: 'kaleyra_dp.png',
            description: 'Unifying multiple interfaces to build a single self-serve flagship product and cut down on overhead costs.',
            link: '/work/kaleyra/'

          },
          {
            title: 'Rapido',
            featuredImage: 'rapido_work_cover.png',
            type: 'Partner',
            typeImage: 'partner.svg',
            logo: 'rapido_dp.png',
            description: 'Streamlining UX efforts by filling in gaps in skill sets and setting up established design processes',
            link: '/work/rapido/'

          },
          {
            title: 'OneSky',
            featuredImage: 'onesky_work_cover.png',
            type: 'Launch',
            typeImage: 'launch.svg',
            logo: 'onesky_dp.png',
            description: 'Translating complex airspace rules and regulations into coherent and ready-to-use information for small UAS and drone operators.',
            link: '/work/onesky/'
          },
          {
            title: 'Cleartrip',
            featuredImage: 'cleartrip_work_cover.png',
            type: 'Accelerate',
            typeImage: 'accelerate.svg',
            logo: 'cleartrip_dp.png',
            description: 'Identifying gaps and revamping the B2B user experience to make it intuitive and best in the industry',
            link: '/work/cleartrip/'
          },
          {
            title: 'Kargo',
            featuredImage: 'kargo/kargo_thumb.png',
            type: 'launch',
            typeImage: 'launch.svg',
            logo: 'kargo/kargo_logo.svg',
            description: 'To digitize mini-truck bookings for intra-city movement of goods with ease-of-use both for the Merchant and the Captain (Driver)',
            link: '/work/kargo/'
          },
          {
            title: 'Bookmyshow',
            featuredImage: 'bookmyshow_work_cover.png',
            type: 'Partner',
            typeImage: 'partner.svg',
            logo: 'bookmyshow_dp.png',
            description: 'Scaling up design efforts with speed and perfection to meet the growing needs of a highly competitive market',
            link: '/work/bookmyshow/'
          },
          {
            title: 'MyoPal',
            featuredImage: 'myopal_work_cover.png',
            type: 'Launch',
            typeImage: 'launch.svg',
            logo: 'myopal_dp.png',
            description: 'Creating awareness about Juvenile Myositis and providing an easy and convenient way to conduct the CMAS test at home',
            link: '/work/myopal/'
          },
          {
            title: 'Blackbuck',
            featuredImage: 'blackbuck_work_cover.png',
            type: 'Partner',
            typeImage: 'partner.svg',
            logo: 'blackbuck_dp.png',
            description: 'Establishing a consistent design language and restructuring user flows for an enhanced digital experience of a Trucking app',
            link: '/work/blackbuck/'

          },
    ])
    return(
        <div className={styles.workPage + ' workPage'}>
            <Helmet>
              <title>DesignString - UI/UX case studies showcasing our best work</title>
              <meta id="meta-description" name="description" content="45+ products and counting - Some selected work to show how we have helped businesses realize their goals and build design-first products" />
              <meta id="og-title" property="og:title" content="DesignString - UI/UX case studies showcasing our best work" />
              <meta id="og-description" property="og:description" content="45+ products and counting - Some selected work to show how we have helped businesses realize their goals and build design-first products" />
              <meta id="og-image" property="og:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/work.jpg" />
              <meta id="og-url" property="og:url" content="https://designstring.com/work" />
              <meta id="twitter-title" property="twitter:title" content="DesignString - UI/UX case studies showcasing our best work" />
              <meta id="twitter-description" property="twitter:description" content="45+ products and counting - Some selected work to show how we have helped businesses realize their goals and build design-first products" />
              <meta id="twitter-image" property="twitter:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/work.jpg" />
              <meta id="twitter-url" property="twitter:url" content="https://designstring.com/work" />
              <meta id="twitter-card" property="twitter:card" content="summary_large_image" />
            </Helmet>
            <section className={styles.pageBioSection + ' pageBioSection'}>
                <PageBio
                    title={`Products we enabled!`}
                    description={`From startups to enterprises, we have worked across the board to help businesses realize their goals and build design-first products.`}
                    img={`v4/our_works.png`}
                />
            </section>
            <CaseStudies caseStudies={caseStudies}/>
            <FooterTop link="contact" title={<>Got a big idea ? Let’s make it <br/> happen</>} />
        </div>
    )
}
