import React, { Component } from 'react'
import {Hero, Bg, Challenge, Solution, Feedback, Results, FeaturedWorks} from '../components'
import styles from '../../../assets/styles/casestudy_detail.module.scss';
import {FooterTop} from '../../../shared_elements/'
import {CaseStudies} from '../../home/components';
import { imageBasePath } from '../../../constants';
import {Helmet} from "react-helmet";


export class RapidoCaseStudy extends Component {
    constructor(props){
        super(props);
        this.state = {
            pageName : 'bookmyshow',
            heroDetails: {
                title : 'Scaling up design efforts with speed and perfection to meet the growing needs of a highly competitive market',
                subtitle: 'When it comes to online entertainment ticketing in India, BookMyShow is the first name that comes to a user’s mind. It enjoys such a high brand recall, not only in India but also in the other four countries it is present in. With such a massive brand presence, scaling up at speed is crucial. With its engineering to design team ratio highly skewed, this was turning out to be a big bottleneck for its growth. When multiple efforts to successfully scale up its own design team failed due to various factors, DesignString came on board as their design partner.',
                type: 'Partner',
                typeImage: 'partner.svg',
                logo: 'bookmyshow_dp.png',
                category: 'Entertainment',
                geo: 'India, Indonesia, Singapore, UAE, West Indies',
                output : 'DIY Platform for events managers, User Profiling'
            },
            bg:{
                img : 'bookmyshow_work_bg.jpg'
            },
            challenge : 'The challenge was to clear the huge design backlog, work around tech constraints and scale at speed, all without affecting the overall product experience.',
            productType : 'website',
            solution: {
                description : [
                    'We started delivering from Day 1. Working closely with the Product & Tech team, we not only cleared the design backlogs but also helped them innovate and experiment with new ideas. To streamline their own internal design efforts and collaboration, we brought in the DesignOps process.'
                ],
                media: {

                }
            },
            disignsImage : 'bookmyshow_screens.jpg',
            clientFeedback: {
                feedback: "The product designers onboarded quickly and produced work that's encouraged customers to onboard onto the platform. They took responsibility for assigned tasks and made an effort to implement all of their partner's feedback into the product. Customers can expect top talent and an experienced team.",
                clientDetail: {
                    img: '',
                    name: 'Sushant Mittal',
                    designation: 'Group Product Manager',
                    company: 'BookMyShow'
                }
            },
            result: 'With DesignString as partners, BookMyShow has further strengthened its market leader position. Constantly experimenting with innovative ideas in a post-Covid world, they are still able to hold their own and be the brand with highest recall when it comes to entertainment ticketing.',
            availableon: [
                {
                    img : 'web_version.svg',
                    link: 'https://in.bookmyshow.com/'
                }
            ],
            caseStudies : [
                {
                    title: 'Blackbuck',
                    featuredImage: 'blackbuck_work_cover.png',
                    type: 'Partner',
                    typeImage: 'partner.svg',
                    logo: 'blackbuck_dp.png',
                    description: 'Establishing a consistent design language and restructuring user flows for an enhanced digital experience of a Trucking app',
                    link: '/work/blackbuck/'
                  },
                {
                  title: 'Kaleyra',
                  featuredImage: 'kaleyra_work_cover.png',
                  type: 'Accelerate',
                  typeImage: 'accelerate.svg',
                  logo: 'kaleyra_dp.png',
                  description: 'Unifying multiple interfaces to build a single self-serve flagship product and cut down on overhead costs.',
                  link: '/work/kaleyra/',
                },
            ],
            writeClutchReview: 'https://clutch.co/profile/designstring#review-1668611'

        }
    }
    render() {
        const{pageName, heroDetails, bg, challenge, productType, solution, clientFeedback, result, availableon, caseStudies, disignsImage, writeClutchReview} =this.state;
        return (
            <div className={styles.caseStudyDetailPage}>
                <Helmet>
                    <title>DesignString: Case Study BookMyShow, Category: Entertainment</title>
                    <meta id="meta-description" name="description" content="How we helped scale up design efforts with speed and perfection to meet the growing needs of a highly competitive market" />
                    <meta id="og-title" property="og:title" content="DesignString: Case Study BookMyShow, Category: Entertainment" />
                    <meta id="og-description" property="og:description" content="How we helped scale up design efforts with speed and perfection to meet the growing needs of a highly competitive market" />
                    <meta id="og-image" property="og:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/bookmyshow.jpg" />
                    <meta id="og-url" property="og:url" content="'https://designstring.com/work/bookmyshow" />
                    <meta id="twitter-title" property="twitter:title" content="DesignString: Case Study BookMyShow, Category: Entertainment" />
                    <meta id="twitter-description" property="twitter:description" content="How we helped scale up design efforts with speed and perfection to meet the growing needs of a highly competitive market" />
                    <meta id="twitter-image" property="twitter:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/bookmyshow.jpg" />
                    <meta id="twitter-url" property="twitter:url" content="'https://designstring.com/work/bookmyshow" />
                    <meta id="twitter-card" property="twitter:card" content="summary_large_image" />
                </Helmet>
                <section className={styles.heroSection}>
                    <Hero pageName={pageName} heroDetails={heroDetails} />
                </section>
                <section className={styles.bgSection}>
                    <Bg pageName={pageName} bg={bg} />
                </section>
                <section className={styles.challengeSection}>
                    <Challenge pageName={pageName} challenge={challenge} />
                </section>
                <section className={styles.solutionSection}>
                    <Solution pageName={pageName} productType={productType} solution={solution} disignsImage={disignsImage} />
                </section>
                {
                    disignsImage ?
                    <section className={styles.disignsImageSection+' backgroundProp'} style={{ backgroundImage : `url(${imageBasePath+'v4/'+disignsImage})` }}>

                    </section>
                    : null
                }
                <section className={styles.feedbackSection}>
                    <Feedback pageName={pageName} clientFeedback={clientFeedback} writeClutchReview={writeClutchReview}/>
                </section>
                <section className={styles.resultSection}>
                    <Results pageName={pageName} result={result} availableon={availableon} />
                </section>
                <section className={styles.featuredWork}>
                    <CaseStudies caseStudies={caseStudies} />
                </section>
                <FooterTop link="contact" title={<>Ready to launch your product to the world?</>} />
            </div>
        )
    }
}

export default RapidoCaseStudy
