import React, {} from 'react';
import {Container, Grid} from '@material-ui/core';
import {imageBasePath} from '../../../constants';
import styles from '../../../assets/styles/tinycanvasdetail.module.scss';
import { Link } from "react-router-dom";

export default function(props) {
  return(
    <header className={styles.tinyCanvasHeader}>
      <Container maxWidth="md">
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={6}>
            <p className={styles.logo}>
            <Link to="/tinycanvas">
              <img src={ imageBasePath+'tiny_canvas_logo.svg' } alt="TinyCanvas"/>
            </Link>
            </p>
          </Grid>
          <Grid item xs={6} align="right">
            <p className={styles.figmaBtnBlk}>
              <a className="tryFigmaBtn" href="https://www.figma.com/c/plugin/794829197801903069/TinyCanvas" target="_blank" rel="noopener">
                <span>Try for </span>
                <img src={imageBasePath + 'figma_icon.svg'} alt="Figma" />
                <span>Figma</span>
              </a>
            </p>
          </Grid>
        </Grid>
      </Container>
    </header>
  )
}
