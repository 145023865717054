import React, { Component } from 'react'
import {Hero, Bg, Challenge, Solution, Feedback, Results, FeaturedWorks} from '../components'
import styles from '../../../assets/styles/casestudy_detail.module.scss';
import {FooterTop} from '../../../shared_elements/'
import {CaseStudies} from '../../home/components';
import { imageBasePath } from '../../../constants';
import {Helmet} from "react-helmet";

export class Kaleyra extends Component {
    constructor(props){
        super(props);
        this.state = {
            pageName : 'kaleyra',
            heroDetails: {
                title : 'Unifying multiple interfaces to build a single self-serve flagship product and cut down on overhead costs',
                subtitle: 'Kaleyra is an API-based omni-channel business communication platform where businesses can engage with their clients over SMS, Voice, WhatsApp and Chatbots. Kaleyra had close to 7 interfaces to provide the various services depending on the region, function and so on. Due to the flawed interface, customers were highly dependent on the Support team for resolving their queries, resulting in a high overhead cost for Kaleyra.',
                type: 'Accelerate',
                typeImage: 'accelerate.svg',
                logo: 'kaleyra_dp.png',
                category: 'CPaaS (Communication Platform as a Service)',
                geo: 'Global',
                output : 'Hexa, Kaleyra’s flagship product'
            },
            bg:{
                img : 'kaleyra_work_bg.png'
            },
            challenge : 'The challenge was to unify the various interfaces and build it into a more comprehensive offering. The revamped version was to be so intuitive that customers take the DIY route rather than reaching out to Support for their queries.',
            productType : 'website',
            solution: {
                description : [
                    'We conducted several user studies to identify the key pain points. Working across departments to understand the tech challenges, we defined an information architecture that worked not only for their existing and new customers but also for their internal support team. The design language was planned keeping in mind its global client base.'
                ],
                list : [
                    {
                        title: 'Campaign Creation',
                        link: 'kaleyra/campaign_creation.mp4'
                    },
                    {
                        title: 'IVR Flow builder',
                        link: 'kaleyra/flow_builder.mp4'
                    },
                    {
                        title: 'WhatsApp for Business',
                        link: 'kaleyra/whatsapp.mp4'
                    }
                ],
                media: {
                    product_type: 'website',
                    media_type: 'video',
                }
            },
            disignsImage : '',
            clientFeedback: {
                feedback: "They have a very solid understanding of product roadmaps and how to build a product. They have a great process and system in place, and they know what the end-user wants. Our website went live, and we were able to convert about 100 customers. DesignString Solutions has strong product knowledge. They were able to deliver what we asked for.",
                clientDetail: {
                    img: 'aniketh_dp.png',
                    name: 'Aniketh Jain',
                    designation: 'Cheif Revenue Officer',
                    company: 'Kaleyra'
                }
            },
            result: 'Kaleyra went public post this major overhaul and is now listed in the NYSE. Kaleyra SME business pivoted to be SaaS-based, reducing not only support but also sales and operation overheads. DesignString also supported the setting up of a design system that would help an in-house team to scale up their design efforts. Kaleyra set up its first design & product team after 1+ years of engagement with DesignString',
            availableon: [
                {
                    img : 'web_version.svg',
                    link: 'https://kaleyra.io'
                }
            ],
            caseStudies : [
                {
                  title: 'Cleartrip',
                  featuredImage: 'cleartrip_work_cover.png',
                  type: 'Accelerate',
                  typeImage: 'accelerate.svg',
                  logo: 'cleartrip_dp.png',
                  description: 'Identifying gaps and revamping the B2B user experience to make it intuitive and best in the industry',
                  link: '/work/cleartrip/'
                },
                {
                  title: 'Bookmyshow',
                  featuredImage: 'bookmyshow_work_cover.png',
                  type: 'Partner',
                  typeImage: 'partner.svg',
                  logo: 'bookmyshow_dp.png',
                  description: 'Scaling up design efforts with speed and perfection to meet the growing needs of a highly competitive market',
                  link: '/work/bookmyshow/'
                },
            ],
            writeClutchReview: 'https://clutch.co/profile/designstring?page=1#review-1434873'

        }
    }
    render() {
        const{pageName, heroDetails, bg, challenge, productType, solution, clientFeedback, result, availableon, caseStudies, disignsImage, writeClutchReview} =this.state;
        return (
            <div className={styles.caseStudyDetailPage}>
                <Helmet>
                    <title>DesignString: Case Study Kaleyra, Category: CPaaS</title>
                    <meta id="meta-description" name="description" content="How we helped unify multiple interfaces to build a single self-serve flagship product and cut down on overhead costs" />
                    <meta id="og-title" property="og:title" content="DesignString: Case Study Kaleyra, Category: CPaaS" />
                    <meta id="og-description" property="og:description" content="How we helped unify multiple interfaces to build a single self-serve flagship product and cut down on overhead costs" />
                    <meta id="og-image" property="og:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/kaleyra.jpg" />
                    <meta id="og-url" property="og:url" content="https://designstring.com/work/kaleyra" />
                    <meta id="twitter-title" property="twitter:title" content="DesignString: Case Study Kaleyra, Category: CPaaS" />
                    <meta id="twitter-description" property="twitter:description" content="How we helped unify multiple interfaces to build a single self-serve flagship product and cut down on overhead costs" />
                    <meta id="twitter-image" property="twitter:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/kaleyra.jpg" />
                    <meta id="twitter-url" property="twitter:url" content="https://designstring.com/work/kaleyra" />
                    <meta id="twitter-card" property="twitter:card" content="summary_large_image" />
                </Helmet>
                <section className={styles.heroSection}>
                    <Hero pageName={pageName} heroDetails={heroDetails} />
                </section>
                <section className={styles.bgSection}>
                    <Bg pageName={pageName} bg={bg} />
                </section>
                <section className={styles.challengeSection}>
                    <Challenge pageName={pageName} challenge={challenge} />
                </section>
                <section className={styles.solutionSection}>
                    <Solution pageName={pageName} productType={productType} solution={solution} />
                </section>
                {
                    disignsImage ?
                    <section className={styles.disignsImageSection+' backgroundProp'} style={{ backgroundImage : `url(${imageBasePath+'v4/'+disignsImage})` }}>

                    </section>
                    : null
                }
                <section className={styles.feedbackSection}>
                    <Feedback pageName={pageName} clientFeedback={clientFeedback} writeClutchReview={writeClutchReview}/>
                </section>
                <section className={styles.resultSection}>
                    <Results pageName={pageName} result={result} availableon={availableon} />
                </section>
                <section className={styles.featuredWork}>
                    <CaseStudies caseStudies={caseStudies} />
                </section>
                <FooterTop link="contact" title={<>Ready to launch your product to the world?</>} />
            </div>
        )
    }
}

export default Kaleyra
