import React from 'react'
import {Container, Grid} from '@material-ui/core';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import {Link} from 'react-router-dom';
import "slick-carousel/slick/slick-theme.css";
import {imageBasePath} from '../../../constants';
import styles from '../../../assets/styles/whatwedo.module.scss';

const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows : false,
    autoplay: true,
    arrows: true,
    nextArrow: <img src={imageBasePath + 'v4/arrow_next.svg'} alt="Next" />,
    prevArrow: <img src={imageBasePath + 'v4/arrow_prev.svg'} alt="Prev" />,
};

function Banner(props) {
    const{bannerDetails, sliderDetails} = props;
    return (
        <div className={styles.bannerContainer}>
            <Container maxWidth="lg">
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm={7} md={7} lg={7} className="flex-centered">
                        <div className={styles.bannerLeft}>
                            <h2 className={styles.launchTitle+ " flex-centered heading3"}>
                                <img src={imageBasePath+'v4/'+bannerDetails.typeImage} alt="launch" />
                                <span>{bannerDetails.type}</span>
                            </h2>
                            <h1 className="heading1Bold">{bannerDetails.title}</h1>
                            <p className="subHeading1">{bannerDetails.subtitle}</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={5} md={5} lg={5}>
                        <div class={styles.launchSlider}>
                            <Slider {...settings} >
                                {
                                    sliderDetails.map((product, key) => (
                                        <Link to={product.link}>
                                            <div key={key} class={styles.product}>
                                                <img src={imageBasePath+'v4/'+product.product_img} alt="product image" />
                                            </div>
                                        </Link>
                                    ))
                                }
                            </Slider>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Banner
