import React from 'react'
import { Plugins, SideProjects } from '../components'
import styles from '../../../assets/styles/initiatives.module.scss';
import { PageBio, FooterTop } from '../../../shared_elements';
import {Helmet} from "react-helmet";

export default function Initiatives(props){
    return(
        <div className={styles.initiativesPage + ' initiativesPage'}>
        <Helmet>
          <title>DesignString - Community initiatives to spread the value of design</title>
          <meta id="meta-description" name="description" content="Figma Community plugins to automate design tasks, UX Deck - Bite size design insights, Audiogyan - Podcast on Design & a lot more initiatives on the way !" />
          <meta id="og-title" property="og:title" content="DesignString - Community initiatives to spread the value of design" />
          <meta id="og-description" property="og:description" content="Figma Community plugins to automate design tasks, UX Deck - Bite size design insights, Audiogyan - Podcast on Design & a lot more initiatives on the way !" />
          <meta id="og-image" property="og:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/initiatives.jpg" />
          <meta id="og-url" property="og:url" content="https://designstring.com/initiatives" />
          <meta id="twitter-title" property="twitter:title" content="DesignString - Community initiatives to spread the value of design" />
          <meta id="twitter-description" property="twitter:description" content="Figma Community plugins to automate design tasks, UX Deck - Bite size design insights, Audiogyan - Podcast on Design & a lot more initiatives on the way !" />
          <meta id="twitter-image" property="twitter:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/initiatives.jpg" />
          <meta id="twitter-url" property="twitter:url" content="https://designstring.com/initiatives" />
          <meta id="twitter-card" property="twitter:card" content="summary_large_image" />
        </Helmet>
            <section className={styles.pageBioSection + ' pageBioSection'}>
                <PageBio
                    title={<>Towards a more <br/> <span>design-centric</span> world</>}
                    description={`We are on a mission to create a more user-friendly digital world one product at a time. Here are some of our community initiatives to help spread the value of design.`}
                    img={`v4/initiatives.png`}
                />
            </section>
            <Plugins />
            <SideProjects />
            <FooterTop link="contact" title={<>Got a big idea ? Let’s make it <br/> happen</>} />
        </div>
    )
}
