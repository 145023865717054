import React, {Component} from 'react';
import styles from '../../../assets/styles/tinycanvas.module.scss';
import {Banner, HowLanding, WhyTinyCanvas, Pricing, Cta, Footer} from '../components';
import { globalPostService } from '../../../utils/globalApiServices';
import { toastFlashMessage, checkApiStatus } from '../../../utils';
import { fieldValidation } from '../../../utils/formValidation';
import { regExpression } from '../../../constants';
import {Helmet} from "react-helmet";

export default class TinyCanvas extends Component{
  constructor(props){
    super(props);
    this.state = {
      userInfo: {
        name: '',
        email: '',
        message: '',
        page: "tiny_canvas"
      },
      error: {},
      errorCode: {
        email:{
          0: '',
          1: 'Please enter Email Address',
          4: 'Please enter valid Email Address'
        },
        emailObj: {
          requiredFlag: true,
          regexPattern: regExpression.email
        }
      },
      loader: false
    }
  }
  handleChange = (key, value) => {
    this.setState((prevState) => ({
      ...prevState,
      userInfo: {
        ...prevState.userInfo,
        [key]: value
      },
      error: {
        ...prevState.error,
        [key]: ''
      }
    }))
  }
  onSubmit = (e) => {
    e.preventDefault()
    const { userInfo, errorCode } = this.state
    const validateNewInput = {
        name: userInfo.name ? '' : 'Please enter Name',
        email: errorCode['email'][fieldValidation({...errorCode['emailObj'], fieldval: userInfo.email})],
        message: userInfo.message ? '' : 'Please enter your Feedback'
    }
    if(Object.values(validateNewInput).every(val => val === null || val === '')){
      this.setState({loader: true})
      globalPostService('landing-page/contact/', userInfo)
      .then(response => {
        this.setState({loader: false})
        if(checkApiStatus(response.statusCode)){
          toastFlashMessage('Feedback submitted successfully', 'success')
          this.setState({
            userInfo: {
              name: '',
              email: '',
              message: ''
            }
          })
        }
      })
    }else{
      this.setState({
        error: validateNewInput
      })
    }
  }
  render(){
    const { error, userInfo, loader } = this.state
    return(
      <>
      <div className="tinycanvasPage">
        <Helmet>
          <title>Top UX / UI Design firm for SaaS &  Mobile apps</title>
          <meta id="meta-description" name="description" content="We deliver results to tech-driven businesses through UX and UI design, help to build fantastic product experiences. Primary focus on SaaS &  Mobile apps" />
          <meta id="og-title" property="og:title" content="Top UX / UI Design firm for SaaS &  Mobile apps" />
          <meta id="og-description" property="og:description" content="We deliver results to tech-driven businesses through UX and UI design, help to build fantastic product experiences. Primary focus on SaaS &  Mobile apps" />
          <meta id="og-image" property="og:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/meta_image_v2.png" />
          <meta id="og-url" property="og:url" content="https://designstring.com/" />
          <meta id="twitter-title" property="twitter:title" content="Top UX / UI Design firm for SaaS &  Mobile apps" />
          <meta id="twitter-description" property="twitter:description" content="We deliver results to tech-driven businesses through UX and UI design, help to build fantastic product experiences. Primary focus on SaaS &  Mobile apps" />
          <meta id="twitter-image" property="twitter:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/meta_image_v2.png" />
          <meta id="twitter-url" property="twitter:url" content="https://designstring.com/" />
          <meta id="twitter-card" property="twitter:card" content="summary_large_image" />
        </Helmet>
        <section>
          <Banner />
        </section>
        <section>
          <HowLanding />
        </section>
        <section className={styles.whyTinyCanvasSection}>
          <WhyTinyCanvas />
        </section>
        <section>
          <Pricing />
        </section>
        <section className={styles.ctaSection+ ' background-prop'}>
          <Cta
              sendFeedback={this.sendFeedback}
              handleChange={this.handleChange}
              error={error}
              userInfo={userInfo}
              sendFeedback={this.onSubmit}
              loader={loader}
          />
        </section>
        <Footer />
      </div>
      </>
    )
  }
}
