import React, {Component} from 'react';
import {Container, Grid} from '@material-ui/core';
import {imageBasePath} from '../../../constants';
import styles from '../../../assets/styles/home.module.scss';
import { Link, withRouter } from 'react-router-dom';
import {Feedback} from '../../../shared_elements'
export default function(props) {
  const{caseStudies, feedbacks} = props;
  return(
    <>
      {
        caseStudies ?
        <section className={styles.caseStudySection + " caseStudySection"}>
          <Container maxWidth="lg" className={"caseStudyContainer"}>
            <Grid container direction="row" alignItems="center" spacing={4} className={"titleRow"}>
              <Grid item xs={12}>
                <h2 className="heading2">
                  Case studies
                </h2>
              </Grid>
            </Grid>
            <Grid container direction="row"  spacing={4} className={"contentRow"}>
              {
                caseStudies.map((casestudy, key) => (
                  <Grid key={key} item md={6} sm={6} xs={12} className={"cardOuterWrapper"}>
                    <Link to={casestudy.link} className={"cardWrapper"}>
                      <div className={"imgBlk"}>
                        <img className={"featuredImg"} src={imageBasePath+'v4/'+casestudy.featuredImage} alt={casestudy.title+" portfolio"} />
                        {
                          casestudy.type ?
                            <h4 className="heading4">
                              {
                                casestudy.typeImage ?
                                <img src={imageBasePath+'v4/'+casestudy.typeImage} alt={casestudy.type} />
                                : null
                              }
                              <span>{casestudy.type}</span>
                            </h4>
                          : null
                        }
                      </div>
                      <div className={"contentBlk"}>
                        <img style={{width:'150px'}} src={imageBasePath+'v4/'+casestudy.logo} alt={casestudy.title} />
                        <p className="subHeading1">
                          {casestudy.description}
                        </p>
                      </div>
                    </Link>
                  </Grid>
                ))
              }
              {
                feedbacks ?
                <>
                <Grid item md={6} sm={6} xs={12} className={"cardOuterWrapper"}>
                  <Feedback feedbacks={feedbacks} />
                </Grid>
                </>
                : null
              }

            </Grid>
            {
              window.location.pathname == '/' ?
              <Grid container direction="row"  spacing={4} className={"viewAllRow"}>
                <Grid item xs={12}>
                  <h3 className="heading3">45 Products & Counting</h3>
                  <h4 className="paragraph">We bring to the table our deep delivery knowledge of the products</h4>
                  <p>
                    <Link to="/work" className="primaryBtn">
                        <span>Learn more</span>
                        <img src={ imageBasePath+'v4/go_arrow_white.svg' } alt="Image" className="inActive"/>
                    </Link>
                  </p>
                </Grid>
              </Grid>
              : null
            }
          </Container>
        </section>
        :
        null
      }
    </>
  )
}
