import React, { Component } from 'react'
import {Hero, Bg, Challenge, Solution, Feedback, Results, FeaturedWorks} from '../components'
import styles from '../../../assets/styles/casestudy_detail.module.scss';
import {FooterTop} from '../../../shared_elements/'
import {CaseStudies} from '../../home/components';
import { imageBasePath } from '../../../constants';
import {Helmet} from "react-helmet";

export class Blackbuck extends Component {
    constructor(props){
        super(props);
        this.state = {
            pageName : 'blackbuck',
            heroDetails: {
                title : 'Establishing a consistent design language and restructuring user flows for an enhanced digital experience of a Trucking app',
                subtitle: 'BlackBuck brings the offline operations of trucking online. It has a Freight Platform for Shippers and a Services Platform for Fleet Operators.The Services platform includes a host of trucking services like FastTag distribution, e-commerce for utility, GPS, insurance, and digital rechargeable fuel card. BlackBuck being a tech-driven product, the tech to design team ratio was skewed. The newly established product team was looking for an expert design partner who can share the load and bring in skilled hands on the deck.',
                type: 'Partner',
                typeImage: 'partner.svg',
                logo: 'blackbuck_dp.png',
                category: 'Transportation',
                geo: 'India',
                output : 'Boss App for Truckers'
            },
            bg:{
                img : 'blackbuck_work_bg.png'
            },
            challenge : 'The challenge was to design an app for the tier-2 & 3 users like drivers and truck owners. Since the target audience was not too tech-savvy, it was imperative to keep the app simple and with a minimal learning curve.',
            productType : 'website',
            solution: {
                description : [
                    'We worked closely with the product & tech team to design the experience of the Boss App for Truckers. User research to restructure user flows, defining a visual language, quick prototyping to validate ideas and participating in on-going sprints to deliver designs for implementation by the tech team - were some key aspects of the design partnership.'
                ],
                media: {

                }
            },
            disignsImage : 'blackbuck_work_main.png',
            clientFeedback: {
                feedback: "My experience with DesignString has been really good, both in terms of people and commitment.",
                clientDetail: {
                    img: 'manish_dp.png',
                    name: 'Manish Singh',
                    designation: 'Head of Products',
                    company: 'Blackbuck'
                }
            },
            result: 'DesignString helped set up industry-standard design workflow for seamless, effective, and quality design outputs. With the ongoing partnership, the Design team has come at par with the Development team and ensures all delivery deadlines and quality standards are adhered to.',
            availableon: [
                {
                    img : 'android_version.png',
                    link: 'https://play.google.com/store/apps/details?id=com.zinka.boss'
                }
            ],
            caseStudies : [
                {
                    title: 'Kargo',
                    featuredImage: 'kargo/kargo_thumb.png',
                    type: 'launch',
                    typeImage: 'launch.svg',
                    logo: 'kargo/kargo_logo.svg',
                    description: 'To digitize mini-truck bookings for intra-city movement of goods with ease-of-use both for the Merchant and the Captain (Driver)',
                    link: '/work/kargo/'
                },
                {
                    title: 'Amby',
                    featuredImage: 'amby_work_cover.png',
                    type: 'Launch',
                    typeImage: 'launch.svg',
                    logo: 'amby_dp.png',
                    description: 'Making OKR-based goal-setting and management as easy and adoptable as an excel spreadsheet.',
                    link: '/work/amby/'
                }
            ],
            writeClutchReview: ''
        }
    }
    render() {
        const{pageName, heroDetails, bg, challenge, productType, solution, clientFeedback, result, availableon, caseStudies, disignsImage, writeClutchReview} =this.state;
        return (
            <div className={styles.caseStudyDetailPage}>
                <Helmet>
                    <title>DesignString: Case Study Blackbuck, Category: Transport</title>
                    <meta id="meta-description" name="description" content="How we helped establish a consistent design language and restructure user flows for an enhanced digital experience" />
                    <meta id="og-title" property="og:title" content="DesignString: Case Study Blackbuck, Category: Transport" />
                    <meta id="og-description" property="og:description" content="How we helped establish a consistent design language and restructure user flows for an enhanced digital experience" />
                    <meta id="og-image" property="og:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/blackbuck.jpg" />
                    <meta id="og-url" property="og:url" content="https://designstring.com/work/blackbuck" />
                    <meta id="twitter-title" property="twitter:title" content="DesignString: Case Study Blackbuck, Category: Transport" />
                    <meta id="twitter-description" property="twitter:description" content="How we helped establish a consistent design language and restructure user flows for an enhanced digital experience" />
                    <meta id="twitter-image" property="twitter:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/blackbuck.jpg" />
                    <meta id="twitter-url" property="twitter:url" content="https://designstring.com/work/blackbuck" />
                    <meta id="twitter-card" property="twitter:card" content="summary_large_image" />
                </Helmet>
                <section className={styles.heroSection}>
                    <Hero pageName={pageName} heroDetails={heroDetails} />
                </section>
                <section className={styles.bgSection}>
                    <Bg pageName={pageName} bg={bg} />
                </section>
                <section className={styles.challengeSection}>
                    <Challenge pageName={pageName} challenge={challenge} />
                </section>
                <section className={styles.solutionSection}>
                    <Solution pageName={pageName} productType={productType} solution={solution} />
                </section>
                {
                    disignsImage ?
                    <section className={styles.disignsImageSection+' backgroundProp'} style={{ backgroundImage : `url(${imageBasePath+'v4/'+disignsImage})` }}>

                    </section>
                    : null
                }
                <section className={styles.feedbackSection}>
                    <Feedback pageName={pageName} clientFeedback={clientFeedback} writeClutchReview={writeClutchReview}/>
                </section>
                <section className={styles.resultSection}>
                    <Results pageName={pageName} result={result} availableon={availableon} />
                </section>
                <section className={styles.featuredWork}>
                    <CaseStudies caseStudies={caseStudies} />
                </section>
                <FooterTop link="contact" title={<>Ready to launch your product to the world?</>} />
            </div>
        )
    }
}

export default Blackbuck
