import  GiffyCanvas from './containers/GiffyCanvas';
import  GiffyCanvasDetail from './containers/GiffyCanvasDetail';

import pageLayoutHoc from '../../hocs/pageLayoutHoc';
export const giffyCanvasRoutes = [
  {
    path: '/giffycanvas',
    component : pageLayoutHoc(GiffyCanvas, {pageName : 'GiffyCanvas'}),
    key: 'giffycanvas'
  },
  {
    path: '/giffycanvas/detail',
    component : pageLayoutHoc(GiffyCanvasDetail, {pageName : 'GiffyCanvas'}),
    key: 'giffycanvas/detail'
  }
]
