import React from 'react'
import {Container, Grid} from '@material-ui/core';
import styles from '../../../assets/styles/whatwedo.module.scss';
import { imageBasePath } from '../../../constants';
// const processContent = [
//     {
//         img : 'brainstorm.png',
//         title : 'Brainstorm',
//         description: 'Brainstorm with key stakeholders on the product concept and finalize the SoW.'
//     },
//     {
//         img : 'assign_team.png',
//         title : 'Assign team',
//         description: 'Assign a multidisciplinary team of specialists - design, product, tech, QA & DevOps.'
//     },
//     {
//         img : 'launch_quickly.png',
//         title : 'Launch quickly',
//         description: 'Develop and launch the product quickly. Maintain, adapt and work on a sprint basis.'
//     },
//     {
//         img : 'help_to_grow.png',
//         title : 'Help to grow',
//         description: 'Help you grow until you build your internal team.'
//     }
// ]
function Process(props) {
    const{processContent, quote} = props;
    return (
        <div className={styles.processContainer} >
            <Container maxWidth="lg">
                <Grid container direction="row" spacing={4} className={styles.processTitle}>
                    <Grid item xs={12}>
                        <h2 className="heading2">The DesignString Process</h2>
                    </Grid>
                </Grid>
                <Grid container direction="row" spacing={4} className={styles.processContent}>
                    {
                        processContent.map((process, key) => (
                            <Grid key={key} item xs={12} sm={6} md={6} lg={3}>
                                <div className={styles.processCard}>
                                    <img src={imageBasePath+'v4/'+process.img} alt="Brainstorm" />
                                    <h3 className="heading3">{process.title}</h3>
                                    <p className="paragraph">
                                        {process.description}
                                    </p>
                                </div>
                            </Grid>
                        ))
                    }
                </Grid>
                <Grid container direction="row" spacing={4} className={styles.processContent}>
                    <Grid item xs={12}>
                        <p className={"subHeading1 "+styles.quoteBlock}>
                            <img src={imageBasePath+'v4/left-quote.png'} alt="quote" />
                            {quote}
                        </p>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Process
