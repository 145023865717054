import React, {Component} from 'react';
import {Container, Grid, List, ListItem} from '@material-ui/core';
import {imageBasePath} from '../../../constants';
import styles from '../../../assets/styles/home.module.scss';
import {LinkBtn} from '../../../shared_elements';

const partners = [
  {
    img: 'kaleyra_dp.png',
    title: 'Kaleyra',
    url: 'https://www.kaleyra.com'
  },
  {
    img: 'onesky_dp.png',
    title: 'OneSky',
    url: 'https://www.onesky.xyz'
  },
  {
    img: 'blackbuck_dp.png',
    title: 'Blackbuck',
    url: 'https://www.blackbuck.com'
  },
  {
    img: 'saasboomi_dp.png',
    title: 'SaaSBOOMi',
    url: 'http://saasboomi.com'
  },
  {
    img: 'rapido_dp.png',
    title: 'Rapido',
    url: 'https://rapido.bike'
  },
  {
    img: 'nts_dp.png',
    title: 'News Technology Services',
    url: 'https://www.newstechnologyservices.com'
  },
  {
    img: 'bookmyshow_dp.png',
    title: 'BookMyShow',
    url: 'https://www.bookmyshow.com'
  },
  {
    img: 'cleartrip_dp.png',
    title: 'Cleartrip',
    url: 'https://www.cleartrip.com'
  },

]

export default function(props) {
  return(
    <>
      <Container maxWidth="lg" className={styles.partnersContainer}>
        <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <div className={styles.partnerWrapper}>
              <h5 className="heading2">Trusted by</h5>
              <List className="list-inline">
              {
                partners.map((partners, key) => {
                  return(
                      <ListItem key={key} className="list-inline-item">
                          <img src={ imageBasePath+'v4/'+partners.img } alt={partners.title} />
                      </ListItem>
                  )
                })
              }
              </List>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
