import React, {Component} from 'react';
import {Container, Grid, List, ListItem} from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import {imageBasePath} from '../constants';
import {PageNavigation} from '../shared_elements';
import { Link, withRouter } from "react-router-dom";

function Footer(props) {
  window.CLUTCHCO.Init();
  return(
    <footer>
      <Container maxWidth="lg">

        <Grid container direction="row" className="footerMiddle">
          <Grid item xs={12}>
            <p className="logoShort">
              <Link to="/">
                  <img src={ imageBasePath+'v4/logo_short.svg' } alt="DesignString"/>
              </Link>
            </p>
            <Grid container direction="row" className="footerContentWrapper">
                <Grid item md={3} xs={12} sm={6}>
                  <h5 className="btnTextBold">Contact</h5>
                  <p>
                    <a className="paragraph" href="tel:080 4806 2233" target="_blank">080 4806 2233</a>
                  </p>
                  <h5 className="btnTextBold email">Email</h5>
                  <p className="emailAddress">
                    <a className="paragraph" href="mailto:info@designstring.com" target="_blank">info@designstring.com</a>
                  </p>
                </Grid>
                <Grid item md={3} xs={12} sm={6}>
                  <h5 className="btnTextBold office">Office</h5>
                  <p className="paragraph address">
                    1st Floor, A P Complex, 65/6 <br/>
                    Agara, Sarjapur Main Road <br/>
                    Bengaluru, Karnataka
                  </p>
                </Grid>
                <Grid item md={2} xs={6} sm={4}>
                  <h5 className="btnTextBold">Social</h5>
                  <List className="list-inline socialMediaBlk">
                    <ListItem>
                      <a className="paragraph" href="http://www.linkedin.com/company/designstring"  target="_blank" rel="noopener">
                        Linkedin
                      </a>
                    </ListItem>
                    <ListItem>
                      <a className="paragraph" href="http://www.twitter.com/design_string"  target="_blank" rel="noopener">
                        Twitter
                      </a>
                    </ListItem>
                    <ListItem>
                      <a className="paragraph" href="https://dribbble.com/DesignString" target="_blank" rel="noopener">
                        Dribbble
                      </a>
                    </ListItem>
                    <ListItem>
                      <a className="paragraph" href="http://www.facebook.com/designstringtech"  target="_blank" rel="noopener">
                        Facebook
                      </a>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item md={4} xs={12} sm={8}>
                  <Grid container direction="row">
                    <Grid item md={6} xs={6} sm={6}>
                      <h5 className="btnTextBold">What we do</h5>
                      <List className="list-inline menuListBlk">
                          <ListItem>
                              <Link to="/what-we-do/launch">
                                  <span className="paragraph">Launch</span>
                              </Link>
                          </ListItem>
                          <ListItem>
                              <Link to="/what-we-do/accelerate">
                                  <span className="paragraph">Accelerate</span>
                              </Link>
                          </ListItem>
                          <ListItem>
                              <Link to="/what-we-do/partner">
                                  <span className="paragraph">Partner</span>
                              </Link>
                          </ListItem>
                      </List>
                    </Grid>
                    <Grid item md={6} xs={6} sm={6}>
                      <List className="list-inline menuListBlk">
                          <ListItem>
                              <Link to="/work">
                                  <span className="paragraph">Work</span>
                              </Link>
                          </ListItem>
                          <ListItem>
                              <Link to="/initiatives">
                                  <span className="paragraph">Initiatives</span>
                              </Link>
                          </ListItem>
                          <ListItem>
                              <Link to="/company">
                                  <span className="paragraph">Company</span>
                              </Link>
                          </ListItem>
                          <ListItem>
                              <Link to="/careers">
                                  <span className="paragraph">Careers</span>
                              </Link>
                          </ListItem>
                          <ListItem>
                              <Link to="/contact">
                                  <span className="paragraph">Connect With Us</span>
                              </Link>
                          </ListItem>
                      </List>
                      
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12} className="clutchWidgetGrid">
                  <div className="clutchWidget">
                    <div class="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="2" data-height="50" data-darkbg="1" data-clutchcompany-id="1356243"></div>
                  </div>
                </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center" className="footerBottom" >
          <Grid item xs={12} className="copyrightsContainer">
            <p className="paragraph">© 2021. DesignString Solutions Pvt Ltd. All Rights Reserved</p>
          </Grid>
        </Grid>
      </Container>
    </footer>
  )
}

export default withRouter(Footer)
