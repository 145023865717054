import React from 'react'
import { Summary, TeamCategory } from '../components'
import { FooterTop, PageBio } from '../../../shared_elements';
import styles from '../../../assets/styles/company.module.scss';
import {Helmet} from "react-helmet";

const operationTeam = [

  {
    img: 'shreya.jpg',
    name: 'Shreya Thakur ',
    designation: 'HR Manager',
    linkedInProfile:'https://www.linkedin.com/in/shreya-thakur-b2b59410b/',
    alt: 'Shreya Thakur'
  },
]

const leadershipTeam = [
    {
      img: 'yash.jpg',
      name: 'Yashawanth B M',
      designation: 'Founder & Chief Executive Officer',
      linkedInProfile:'https://www.linkedin.com/in/yashuweb/',
      alt: 'Yashawanth B M'
    },
    {
      img: 'punith.jpg',
      name: 'Punith B M',
      designation: 'Co-Founder & Chief Innovation Officer',
      linkedInProfile:'https://www.linkedin.com/in/punith-b-m-5794b199/',
      alt: 'Punith B M'
    },
    {
      img: 'nikhil.jpg',
      name: 'Nikhil Nanu',
      designation: 'Director & Chief Design Officer',
      linkedInProfile:'https://www.linkedin.com/in/nikhil-nanu-89195553/',
      alt: 'Nikhil Nanu'
    },
]
const designTeam = [
    {
      img: 'sharadhi.jpg',
      name: 'Sharadhi Lakshmipathi',
      designation: 'Product Designer',
      linkedInProfile:'https://www.linkedin.com/in/sharadhi-lakshmipathi-3a9831131/',
      alt: 'Sharadhi Lakshmipathi'
    },
    {
      img: 'abhishek.jpg',
      name: 'Abhishek Pandey',
      designation: 'Sr. Product Designer',
      linkedInProfile:'https://www.linkedin.com/in/abhishek-pandey-696a88137/',
      alt: 'Abhishek Pandey'
    },
  {
    img: 'shravan.jpg',
    name: 'Shravan Patel',
    designation: 'Product Designer',
    linkedInProfile: 'https://www.linkedin.com/in/shravan-patel-8b660034/',
    alt: 'Shravan Patel'
  },
  {
    img: 'adil.jpg',
    name: 'Adil Shaik',
    designation: 'Product Designer',
    linkedInProfile: 'https://www.linkedin.com/in/adil-shaik/',
    alt: 'Adil Shaik'
  },
  {
    img: 'pawan.jpg',
    name: 'Pavan Kumar',
    designation: 'Product Designer',
    linkedInProfile: 'https://www.linkedin.com/in/pavan-k-050a94102',
    alt: 'Pavan Kumar'
  },
]
const innovationTeam = [
    {
      img: 'nakul.jpg',
      name: 'Nakul K J',
      designation: 'Principal Software Engineer',
      linkedInProfile:'https://www.linkedin.com/in/nakul-kj-14527395/',
      alt: 'Nakul K J'
    },
    {
      img: 'harsha.jpg',
      name: 'Harsha V',
      designation: 'Principal Software Engineer',
      linkedInProfile:'https://www.linkedin.com/in/harsha-v-naik-9bb33190/',
      alt: 'Harsha V'
    },
    {
      img: 'murugan.jpg',
      name: 'Murugan D',
      designation: 'Principal Software Engineer',
      linkedInProfile:'https://www.linkedin.com/in/murugan-d-91228547/',
      alt: 'Murugan D'
    },
    {
      img: 'goutham.jpg',
      name: 'Goutham J M',
      designation: 'Sr. Software Engineer',
      linkedInProfile:'https://www.linkedin.com/in/gouthamjm/',
      alt: 'Goutham J M'
    },
    {
      img: 'jaya.jpg',
      name: 'Jayathirtha A M',
      designation: 'QA Engineer',
      linkedInProfile:'https://www.linkedin.com/in/jayathirtha-a-m-44b871126/',
      alt: 'Jayathirtha A M'
    }, 
    {
      img: 'arun.jpg',
      name: 'Arun Pandia Raja P',
      designation: 'Software Engineer',
      linkedInProfile: 'https://www.linkedin.com/in/arun-pandia-raja-780274102',
      alt: 'Arun'
    },
    {
      img: 'alok.jpg',
      name: 'Alok K Sahoo',
      designation: 'Software Engineer',
      linkedInProfile:'https://www.linkedin.com/in/alok-kumar-sahoo-5b4639114/',
      alt: 'Alok'
    },
    // {
    //   img: 'srivatsa.jpg',
    //   name: 'Srivathsa K M',
    //   designation: 'Software Engineer',
    //   linkedInProfile: 'https://www.linkedin.com/in/srivathsa-k-m-54687476/',
    //   alt: 'Srivathsa'
    // },
    {
      img: 'vaithi.jpg',
      name: 'Vaithiyalingam Sowmiyan',
      designation: 'Associate Software Engineer',
      linkedInProfile: 'https://www.linkedin.com/in/vaithiyalingam-sowmiyan-aa903b187',
      alt: 'Vaithi'
  },
  // {
  //   img: 'ankit.jpg',
  //   name: 'Ankit Singh',
  //   designation: 'Associate Software Engineer',
  //   linkedInProfile: 'https://www.linkedin.com/in/ankshsn/',
  //   alt: 'Ankit'
  // }
]
export default function Company(props){
    return(
        <div className={styles.companyPage + ' companyPage'}>
        <Helmet>
          <title>DesignString - Enabler for user-centric digital products</title>
          <meta id="meta-description" name="description" content="We are quick in adapting, work side by side as your own team and bring in the same passion for your vision and the determination to make it happen !" />
          <meta id="og-title" property="og:title" content="DesignString - Enabler for user-centric digital products" />
          <meta id="og-description" property="og:description" content="We are quick in adapting, work side by side as your own team and bring in the same passion for your vision and the determination to make it happen !" />
          <meta id="og-image" property="og:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/company.jpg" />
          <meta id="og-url" property="og:url" content="https://designstring.com/company" />
          <meta id="twitter-title" property="twitter:title" content="DesignString - Enabler for user-centric digital products" />
          <meta id="twitter-description" property="twitter:description" content="We are quick in adapting, work side by side as your own team and bring in the same passion for your vision and the determination to make it happen !" />
          <meta id="twitter-image" property="twitter:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/company.jpg" />
          <meta id="twitter-url" property="twitter:url" content="https://designstring.com/company" />
          <meta id="twitter-card" property="twitter:card" content="summary_large_image" />
        </Helmet>

            <section className={styles.pageBioSection + ' pageBioSection'}>
                <PageBio
                    title={`Who we are`}
                    description={<>We are an enabler for user-centric digital products. From launching design-first products to revamping product experiences to helping you overcome your product’s UX challenges, we do it all and we do it with love. <br/><br/>We are quick in adapting, work side by side as your own team and bring in the same passion for your vision and the determination to make it happen !</>}
                    img={`v4/company.png`}
                />
            </section>
            <Summary />
            <section className={styles.dsTeamSection}>
                <div className={styles.leadershipTeam}>
                  <TeamCategory
                      team={leadershipTeam}
                      title={`Leadership team`}
                      type="leadership"
                  />
                </div>
                <TeamCategory
                    team={designTeam}
                    title={`Design Squad`}
                />
                <TeamCategory
                    team={innovationTeam}
                    title={`Innovation Squad`}
                />
                <TeamCategory
                    team={operationTeam}
                    title={`Operations`}
                />
            </section>
            <FooterTop link="contact" title={<>Got a big idea ? Let’s make it <br/> happen</>} />
        </div>
    )
}
