import React, { Component } from 'react'
import {Hero, Bg, Challenge, Solution, Feedback, Results, FeaturedWorks} from '../components'
import styles from '../../../assets/styles/casestudy_detail.module.scss';
import {FooterTop} from '../../../shared_elements/'
import {CaseStudies} from '../../home/components';
import { imageBasePath } from '../../../constants';
import {Helmet} from "react-helmet";

export class OneskyCaseStudy extends Component {
    constructor(props){
        super(props);
        this.state = {
            pageName : 'onesky',
            heroDetails: {
                title : 'Translating complex airspace rules and regulations into coherent and ready-to-use information for small UAS and drone operators.',
                subtitle: 'OneSky, a subsidiary of Analytical Graphics, Inc. (AGI), is an established player in aerospace software providing industry-leading dynamic analytics for the commercial and civil Unmanned Aerial Systems (UAS) and Urban Air Mobility (UAM) markets.  They  had identified a need for small UAS and drone pilots to quickly and easily understand the airspace restrictions and impacting conditions that may affect their operations. However, the internal team already had its hands full with the core offering. OneSky was looking for a Launch expert who can quickly understand the nitty-gritties of their business and translate the identified need into a tangible product.',
                type: 'Launch',
                typeImage: 'launch.svg',
                logo: 'onesky_logo.svg',
                category: 'Aerospace Software',
                geo: 'United States of America',
                output : 'OneSky Mobile App '
            },
            bg:{
                img : 'onesky_bg.png',
                prod_img: 'onesky_app.png'
            },
            challenge : 'The task at hand was to build an application that provided up-to-date airspace awareness while providing flight safety metrics to enhance pilots’ understanding of other operational risks such as anticipated weather conditions and anticipated GPS performance. ',
            productType : 'mobile',
            solution: {
                description : [
                    'DesignString held in-depth discussions with key stakeholders to understand the fundamentals of the product and identify user flows. A multidisciplinary team of specialists across design, product, tech, QA & DevOps was deployed to bring the idea to life. ',
                    'The outcome was an intuitive OneSky Mobile app both for Android and iOS platforms. The app integrated directly with the OneSky UTM platform and made drone operations simpler for pilots'
                ],
                list : [
                    {
                        title: 'Airspace awareness',
                        link: 'onesky/onesky_airspace_awareness.mp4'
                    },
                    {
                        title: 'Weather forecasts',
                        link: 'onesky/onesky_weather_forecast.mp4'
                    },
                    {
                        title: 'Information on GPS/GNSS performance',
                        link: 'onesky/onesky_gps_data.mp4'
                    },
                ],
                media: {
                    product_type: 'mobile',
                    media_type: 'video',
                }
            },
            disignsImage : '',
            clientFeedback: {
                feedback: "The one metric I can think of is how fast the mobile apps were accepted into the Android and Apple app stores. We also had several internal and external users test the mobile app. We went through a pretty detailed process with them to find issues, which they then resolved. I think they are very responsive, and, out of that, we got a much better app.",
                clientDetail: {
                    img: '',
                    name: 'Head of Operations',
                    designation: '',
                    company: 'Onesky Systems, Inc'
                }
            },
            result: 'Though the app was tech-intensive, the user experience was crafted in such a way that it provided the same delight as that of a consumer app. The OneSky Mobile app has made OneSky’s product offering more comprehensive and has created opportunities for future value add-ons',
            availableon: [
                {
                    img : 'ios_version.png',
                    link: 'https://apps.apple.com/us/app/onesky-mobile/id1499845781?ls=1'
                },
                {
                    img : 'android_version.png',
                    link: 'https://play.google.com/store/apps/details?id=com.onesky.mobile'
                }
            ],
            caseStudies : [
                {
                  title: 'MyoPal',
                  featuredImage: 'myopal_work_cover.png',
                  type: 'launch',
                  typeImage: 'launch.svg',
                  logo: 'myopal_dp.png',
                  description: 'Creating awareness about Juvenile Myositis and providing an easy and convenient way to conduct the CMAS test at home',
                  link: '/work/myopal/',
                },
                {
                  title: 'Kargo',
                  featuredImage: 'kargo/kargo_thumb.png',
                  type: 'launch',
                  typeImage: 'launch.svg',
                  logo: 'kargo/kargo_logo.svg',
                  description: 'To digitize mini-truck bookings for intra-city movement of goods with ease-of-use both for the Merchant and the Captain (Driver)',
                  link: '/work/kargo/'
                }
            ],
            writeClutchReview: 'https://clutch.co/profile/designstring#review-1549523'

        }
    }
    render() {
        const{pageName, heroDetails, bg, challenge, productType, solution, clientFeedback, result, availableon, caseStudies, feedbacks, disignsImage, writeClutchReview} =this.state;
        return (
            <div className={styles.caseStudyDetailPage}>
                <Helmet>
                    <title>DesignString: Case Study OneSky, Category: Aerospace</title>
                    <meta id="meta-description" name="description" content="How we helped translate complex airspace rules into coherent and useful information for small drone operators" />
                    <meta id="og-title" property="og:title" content="DesignString: Case Study OneSky, Category: Aerospace" />
                    <meta id="og-description" property="og:description" content="How we helped translate complex airspace rules into coherent and useful information for small drone operators" />
                    <meta id="og-image" property="og:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/onesky.jpg" />
                    <meta id="og-url" property="og:url" content="https://designstring.com/work/onesky" />
                    <meta id="twitter-title" property="twitter:title" content="DesignString: Case Study OneSky, Category: Aerospace" />
                    <meta id="twitter-description" property="twitter:description" content="How we helped translate complex airspace rules into coherent and useful information for small drone operators" />
                    <meta id="twitter-image" property="twitter:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/onesky.jpg" />
                    <meta id="twitter-url" property="twitter:url" content="https://designstring.com/work/onesky" />
                    <meta id="twitter-card" property="twitter:card" content="summary_large_image" />
                </Helmet>
                <section className={styles.heroSection}>
                    <Hero pageName={pageName} heroDetails={heroDetails} />
                </section>
                <section className={styles.bgSection}>
                    <Bg pageName={pageName} bg={bg} />
                </section>
                <section className={styles.challengeSection}>
                    <Challenge pageName={pageName} challenge={challenge} />
                </section>
                <section className={styles.solutionSection}>
                    <Solution pageName={pageName} productType={productType} solution={solution} />
                </section>
                {
                    disignsImage ?
                    <section className={styles.disignsImageSection+' backgroundProp'} style={{ backgroundImage : `url(${imageBasePath+'v4/'+disignsImage})` }}>

                    </section>
                    : null
                }
                <section className={styles.feedbackSection}>
                    <Feedback pageName={pageName} clientFeedback={clientFeedback} writeClutchReview={writeClutchReview}/>
                </section>
                <section className={styles.resultSection}>
                    <Results pageName={pageName} result={result} availableon={availableon} />
                </section>
                <section className={styles.featuredWork}>
                    <CaseStudies caseStudies={caseStudies} feedbacks={feedbacks} />
                </section>
                <FooterTop link="contact" title={<>Ready to launch your product to the world?</>} />
            </div>
        )
    }
}



export default OneskyCaseStudy
