import React ,{useEffect}from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top'
import './assets/styles_old/app_old.scss';
import './assets/styles/app.scss';
import configureStore from './store/configureStore';
import {homeRoutes} from './features/home/homeRoutes';
import {caseStudiesRoutes} from './features/case_studies/caseStudiesRoutes';
import {whatwedoRoutes} from './features/what_we_do/whatwedoRoutes';
import { workRoutes } from './features/work/workRoutes';
import { companyRoutes } from './features/company/companyRoutes';
import { careersRoutes } from './features/careers/careersRoutes';
import { initiativesRoutes } from './features/initiatives/initiativesRoutes';
import { tinyCanvasRoutes } from './features/tinycanvas/tinyCanvasRoutes';
import { giffyCanvasRoutes } from './features/giffycanvas/giffyCanvasRoutes';
// import { connectWithUsRoutes } from './features_old/connect_with_us/connectWithUsRoutes';
import { contactRoutes } from './features/contact/contactRoutes';
import { useClearCache } from 'react-clear-cache';

import PageNotFound from './shared_elements/PageNotFound'
import logo from './logo.svg';
const store = configureStore();
function App() {
const { isLatestVersion, emptyCacheStorage } = useClearCache({ auto: true });

useEffect(()=>{
    if(!isLatestVersion){
      emptyCacheStorage()
    }
},[])

  return(
      <div className="">
        <Provider store={store}>
          <Router>
              <ScrollToTop>
                <Switch>
                  { [...homeRoutes, ...caseStudiesRoutes,...whatwedoRoutes, ...workRoutes, ...companyRoutes, ...careersRoutes, ...initiativesRoutes, ...giffyCanvasRoutes, ...tinyCanvasRoutes, ...contactRoutes].map(({path, component, key}, index) =>
                    <Route exact path={path} component={component} key={key} />
                  )}
                </Switch>
              </ScrollToTop>
          </Router>
        </Provider>
      </div>
    );
}

export default App;
