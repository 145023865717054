import React, { useState, useEffect } from 'react'
import { Container, Grid, List, ListItem } from '@material-ui/core';
import { Link } from 'react-router-dom'
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/careers.module.scss';
import { globalGetService } from '../../../utils/globalApiServices';

export default function OpenPositions(props) {
    const [openPositions, setOpenPositions] = useState([
        // {
        //     title: 'Visual Designer',
        //     description: '1-2 Yr Experience,  Bengaluru',
        //     slug: 'https://www.linkedin.com/jobs/view/133003179/'
        // },
        // {
        //     title: 'Python Developer',
        //     description: '0-4 Yr Experience as a Python developer (Django),  Bengaluru',
        //     slug: 'https://angel.co/company/designstring/jobs/1737094-python-developer'
        // },
         // slug: 'https://angel.co/company/designstring/jobs/1597519-product-designer'
        {
            title: 'Senior / Product Designer',
            description: '2+ years of experience in designing digital interfaces ( Web/Mobile ),  Bengaluru',
            slug: 'https://dschampion.notion.site/dschampion/Product-Designer-217741117d9648c8810543507c12ce97'
        }
    ])

    // useEffect(() => {
    //     globalGetService('careers/')
    //     .then(response => {
    //     })
    // }, [])

    return (
        <section className={styles.openPositionsSection} id="openings">
            <Container maxWidth="lg" className={styles.openPositionsContainer}>
                <Grid container justify="center" alignItems="center">
                    <Grid item xs={12} sm={10} md={8}>
                        <div className={styles.titleBlk}>
                            <h3 className="heading2">Open Positions</h3>
                            <p className="subHeading2Reg">
                                We're just getting started. Join us as we continue on our<br /> mission to impact lives and drive a positive change, one <br />product at a time.<br /><br /><br />
                                Share resumes on <a href="mailto:hr@designstring.com">hr@designstring.com</a>
                            </p>
                        </div>
                        <List className="list-unstyled">
                            {
                                openPositions.length ? openPositions.map((item, index) => <ListItem key={index}>
                                    <a href={`${item.slug}`} className={styles.openPositionItem} target="_blank" rel="nopener noreferrer" >
                                        <p className={styles.menuItem + " heading3"}>
                                            <span>{item.title}</span>
                                            <img src={imageBasePath + 'v4/go_arrow.svg'} alt="Introduction" />
                                        </p>
                                        <span className={styles.menuItem + " paragraph"}>{item.description}</span>
                                    </a>
                                </ListItem>) : 'No openings now'
                            }
                        </List>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}
