import React, { Component } from 'react'
import {Hero, Bg, Challenge, Solution, Feedback, Results, FeaturedWorks} from '../components'
import styles from '../../../assets/styles/casestudy_detail.module.scss';
import {FooterTop} from '../../../shared_elements/'
import {CaseStudies} from '../../home/components';
import { imageBasePath } from '../../../constants';
import {Helmet} from "react-helmet";

export class Cleartrip extends Component {
    constructor(props){
        super(props);
        this.state = {
            pageName : 'cleartrip',
            heroDetails: {
                title : 'Identifying gaps and revamping the B2B user experience to make it intuitive and best in the industry ',
                subtitle: 'Cleartrip is a global online travel company. The company operates an online travel aggregator website for booking flights and train tickets, hotel reservations, and activities in India and the Middle East countries. The Cleartrip B2C app has always stood out for its great user experience. Hotel reservations formed an integral part of its offering. Cleartrip also has a B2B interface, Cleartrip Suite, which allows hotel owners and managers to manage the properties listed on its site. The interface was lacking in many aspects. Poor usability and absence of essential features necessitated the over dependency on the Support team to get changes done.',
                type: 'Accelerate',
                typeImage: 'accelerate.svg',
                logo: 'cleartrip_dp.png',
                category: 'Hospitality Software',
                geo: 'India & Middle East',
                output : 'Cleartrip Suite (Hotel Extranet) '
            },
            bg:{
                img : 'cleartrip_work_bg.png'
            },
            challenge : 'We were chosen to revamp the complete Cleartrip Suite experience. The challenge was to live up to the Cleartrip brand experience as provided by its consumer app. The aim was to make the user experience of its B2B app better than the best in the industry.',
            productType : 'website',
            solution: {
                description : [
                    'We worked closely with internal stakeholders to identify the gaps. User studies were conducted to understand pain points. Considering that hoteliers posted the same listing across multiple competitor sites, we kept the experience simple and intuitive to increase the stickiness of the app. The revamped experience was implemented through ReactJS and integrated with Cleartrip’s backend APIs. Key modules we worked on were:  '
                ],
                list : [
                    {
                        title: 'Booking management & Inventory',
                        link: 'cleartrip/calendar.png'
                    },
                    {
                        title: 'Package creation workflow',
                        link: 'cleartrip/create_rate_plan.gif'
                    },
                    {
                        title: 'Invoicing within the platform',
                        link: 'cleartrip/invoicing.png'
                    },
                ],
                media: {
                    product_type: 'website',
                    media_type: 'image',
                }
            },
            disignsImage : '',
            clientFeedback: {
                feedback: "They were flexible, reliable, and always there to support us.”",
                clientDetail: {
                    img: 'chethan_dp.png',
                    name: 'Chethan Sharma',
                    designation: 'Head of Marketing',
                    company: 'Cleartrip'
                }
            },
            result: 'The revamped interface took the user experience several notches higher and strengthened the brand’s position further. Cleartrip Suite now offers a user experience at par with its B2C app, which is considered an industry benchmark.',
            availableon: [
                {
                    img : 'web_version.svg',
                    link: 'https://suite.cleartrip.com/'
                }
            ],
            caseStudies : [
                {
                  title: 'Kaleyra',
                  featuredImage: 'kaleyra_work_cover.png',
                  type: 'Accelerate',
                  typeImage: 'accelerate.svg',
                  logo: 'kaleyra_dp.png',
                  description: 'Unifying multiple interfaces to build a single self-serve flagship product and cut down on overhead costs.',
                  link: '/work/kaleyra/',
                },
                {
                  title: 'Rapido',
                  featuredImage: 'rapido_work_cover.png',
                  type: 'Partner',
                  typeImage: 'partner.svg',
                  logo: 'rapido_dp.png',
                  description: 'Streamlining UX efforts by filling in gaps in skill sets and setting up established design processes',
                  link: '/work/rapido/'
                },
            ],
            writeClutchReview: 'https://clutch.co/profile/designstring?page=1#review-1359473'

        }
    }
    render() {
        const{pageName, heroDetails, bg, challenge, productType, solution, clientFeedback, result, availableon, caseStudies, disignsImage, writeClutchReview} =this.state;
        return (
            <div className={styles.caseStudyDetailPage}>
                <Helmet>
                    <title>DesignString: Case Study Cleartrip, Category: Hospitality</title>
                    <meta id="meta-description" name="description" content="How we helped identify gaps and revamped the Cleartrip B2B user experience to make it more intuitive" />
                    <meta id="og-title" property="og:title" content="DesignString: Case Study Cleartrip, Category: Hospitality" />
                    <meta id="og-description" property="og:description" content="How we helped identify gaps and revamped the Cleartrip B2B user experience to make it more intuitive" />
                    <meta id="og-image" property="og:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/cleartrip.jpg" />
                    <meta id="og-url" property="og:url" content="https://designstring.com/work/cleartrip" />
                    <meta id="twitter-title" property="twitter:title" content="DesignString: Case Study Cleartrip, Category: Hospitality" />
                    <meta id="twitter-description" property="twitter:description" content="How we helped identify gaps and revamped the Cleartrip B2B user experience to make it more intuitive" />
                    <meta id="twitter-image" property="twitter:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/cleartrip.jpg" />
                    <meta id="twitter-url" property="twitter:url" content="https://designstring.com/work/cleartrip" />
                    <meta id="twitter-card" property="twitter:card" content="summary_large_image" />
                </Helmet>
                <section className={styles.heroSection}>
                    <Hero pageName={pageName} heroDetails={heroDetails} />
                </section>
                <section className={styles.bgSection}>
                    <Bg pageName={pageName} bg={bg} />
                </section>
                <section className={styles.challengeSection}>
                    <Challenge pageName={pageName} challenge={challenge} />
                </section>
                <section className={styles.solutionSection}>
                    <Solution pageName={pageName} productType={productType} solution={solution} />
                </section>
                {
                    disignsImage ?
                    <section className={styles.disignsImageSection+' backgroundProp'} style={{ backgroundImage : `url(${imageBasePath+'v4/'+disignsImage})` }}>

                    </section>
                    : null
                }
                <section className={styles.feedbackSection}>
                    <Feedback pageName={pageName} clientFeedback={clientFeedback} writeClutchReview={writeClutchReview}/>
                </section>
                <section className={styles.resultSection}>
                    <Results pageName={pageName} result={result} availableon={availableon} />
                </section>
                <section className={styles.featuredWork}>
                    <CaseStudies caseStudies={caseStudies} />
                </section>
                <FooterTop link="contact" title={<>Ready to launch your product to the world?</>} />
            </div>
        )
    }
}

export default Cleartrip
