import React, { useState } from 'react'
import styles from '../../../assets/styles/careers.module.scss';
import { imageBasePath } from '../../../constants';
import { Container, Grid, List, ListItem } from '@material-ui/core';

export default function CoreValues(props){
    const [coreValues] = useState([
        {
            title: 'Put users first',
            value: 'We always start by putting ourselves in the users’ shoes.'
        },
        {
            title: 'Delight our clients',
            value: 'Our biggest business metric is our clients’ success.'
        },
        {
            title: 'Be accountable',
            value: 'When we take up a task, we take up full responsibility for it.'
        },
        {
            title: 'Act with integrity',
            value: 'Mistakes make us human and we own up to them.'
        },
        {
            title: 'Win together',
            value: 'We are committed to succeed as a team.'
        },
    ])
    return(
        <section className={styles.coreValuesSection}>
            <Container maxWidth={"lg"} className={styles.coreValuesContainer}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                        <div className={styles.leftBlk}>
                            <h3 className="heading2">Our Core Values:</h3>
                            <img src={`${imageBasePath}v4/company.png`}/>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <List className="listUnstyled ">
                            {
                                coreValues.map((item, index) => <ListItem key={index} className="">
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                            <h3 className="heading3">{item.title}</h3>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <p className="paragraph">{item.value}</p>
                                        </Grid>
                                    </Grid>
                                </ListItem>)
                            }
                        </List>
                    </Grid>
                </Grid>
            </Container>
        </section>
    )
}
