import React from 'react'
import {Container, Grid} from '@material-ui/core'
import {imageBasePath} from '../../../constants';
import styles from '../../../assets/styles/casestudy_detail.module.scss';

export default function Bg(props) {
    const{bg} = props;
    return (
        <div className={styles.bgContainer+ " backgroundProp"} style={{ backgroundImage : `url(${imageBasePath+'v4/'+bg.img})` }}>
            <Container maxWidth="lg">
                <Grid container direction="row" spacing={2}>
                    <Grid item xs={12}>
                        <div className={styles.imageContainer}>
                            {
                                bg && bg.prod_img ?
                                <img src={imageBasePath+'v4/'+bg.prod_img} alt="tracking app image"/>
                                : null
                            }
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
