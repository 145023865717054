import React, {Component} from 'react';
import {Container, Grid} from '@material-ui/core';
import {imageBasePath} from '../../../constants';
import styles from '../../../assets/styles/tinycanvas.module.scss';

const features = [
    {
      img : 'optimized_load_time.svg',
      title: 'Easy to create GIFs',
      description: 'In one go, user can create a GIF image'
    },
    {
      img : 'no_restriction.svg',
      title: 'No restrictions',
      description: 'User can select as many number of images they want  to create a GIF'
    },
  ]

export default function(props) {
  return(
    <Container maxWidth="md" className={styles.whyTinyCanvas}>
      <Grid container direction="row" spacing={2} className={styles.titleRow}>
        <Grid item xs={12}>
          <h3 className={styles.title + ' heading1'}>Why GiffyCanvas  Plugin?</h3>
          <p className="sub-heading2">Ever wonder why anybody has to use GiffyCanvas plugin. It comes up with below features</p>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center" spacing={2} className={styles.contentRow}>
        {
          features.map((feature, key) => {
            return(
                  <Grid item xs={12} md={5} sm={6} className="flex-not-centered">
                    <div className={styles.cardWrapper}>
                      <div className={styles.imgBlk}>
                        <img src={imageBasePath+ feature.img} alt={feature.title} />
                      </div>
                      <div className={styles.contentBlk}>
                        <h4 className="heading4">{feature.title}</h4>
                        <p className="paragraph">{feature.description}</p>
                      </div>
                    </div>
                  </Grid>
                )
          })
        }
      </Grid>
    </Container>
  )
}
