import React, { Component } from 'react';
import {Container, Grid} from '@material-ui/core';
import styles from '../../../assets/styles/whatwedo.module.scss';
import {Banner, Tabs, Process} from '../components';
import {CaseStudies, WhatSetsUs} from '../../home/components';
import {FooterTop} from '../../../shared_elements/';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Scrollspy from 'react-scrollspy';
import {Helmet} from "react-helmet";

class Launch extends Component {
    constructor(props){
        super(props);
        this.state = {
            activeTab : 1,
            bannerDetails : {
                type: 'Launch',
                typeImage : 'launch.svg',
                title : <>Take your product <br/> to the world</>,
                subtitle: <>Ideal for entrepreneurs or existing businesses looking <br/> to launch their next innovative product</>
            },
            sliderDetails : [                
                {
                    link: '/work/amby/',
                    product_img: 'amby_featured_image.png'
                },
                {
                    link: '/work/kargo/',
                    product_img: 'kargo_featured_image.png'
                },
                {
                    link: '/work/onesky/',
                    product_img: 'onesky_featured_home.png'
                }
            ],
            tabTitles : [
                {
                    index : 1,
                    title : 'Right fit for',
                    ref : 'tabsRef'
                },
                {
                    index : 2,
                    title : 'Process',
                    ref : 'processRef'
                },
                {
                    index : 3,
                    title : 'Case Studies',
                    ref : 'caseStudiesRef'
                },
                {
                    index : 4,
                    title : 'Why DesignString',
                    ref : 'whyRef'
                }
            ],
            tabContent : {
                '1' : {
                    title : 'We are the right fit if you',
                    descriptionLeft: 'Have a strong digital product idea or Proof of Concept or MVP in place and are looking for an experienced partner to handle everything - right from design to tech to QA.',
                    descriptionRight: 'Are a growing enterprise business with a strong product idea but is finding it difficult to execute due to internal team bandwidth.'
                }
            },
            processContent : [
                {
                    img : 'brainstorm.png',
                    title : 'Brainstorm',
                    description: 'Brainstorm with key stakeholders on the product concept and finalize the SoW.'
                },
                {
                    img : 'assign_team.png',
                    title : 'Assign team',
                    description: 'Assign a multidisciplinary team of specialists - design, product, tech, QA & DevOps.'
                },
                {
                    img : 'launch_quickly.png',
                    title : 'Launch quickly',
                    description: 'Develop and launch the product quickly. Maintain, adapt and work on a sprint basis.'
                },
                {
                    img : 'help_to_grow.png',
                    title : 'Help to grow',
                    description: 'Help you grow until you build your internal team.'
                }
            ],
            quote : 'At every step, our team also doubles up as your strategic advisory partner to achieve the desired business goals and give you a competitive advantage.',
            caseStudies : [
                {
                    title: 'Kargo',
                    featuredImage: 'kargo/kargo_thumb.png',
                    type: 'launch',
                    typeImage: 'launch.svg',
                    logo: 'kargo/kargo_logo.svg',
                    description: 'To digitize mini-truck bookings for intra-city movement of goods with ease-of-use both for the Merchant and the Captain (Driver)',
                    link: '/work/kargo/'
                },
                {
                  title: 'Amby',
                  featuredImage: 'amby_work_cover.png',
                  logo: 'amby_dp.png',
                  description: 'Making OKR-based goal-setting and management as easy and adoptable as an excel spreadsheet.',
                  link: '/work/amby/'
                },
                {
                  title: 'OneSky',
                  featuredImage: 'onesky_work_cover.png',
                  logo: 'onesky_dp.png',
                  description: 'Translating complex airspace rules and regulations into coherent and ready-to-use information for small UAS and drone operators.',
                  link: '/work/onesky/',
                },
                {
                  title: 'MyoPal',
                  featuredImage: 'myopal_work_cover.png',
                  logo: 'myopal_dp.png',
                  description: 'Creating awareness about Juvenile Myositis and providing an easy and convenient way to conduct the CMAS test at home.',
                  link: '/work/myopal/'
                },
            ],
            feature : {
                title : <>Why DesignString <br/> to launch my next product ?</>,
                list : [
                    {
                        step : '01',
                        title: 'Design-first always',
                        description:'A user-centric design approach forms the core of all our solutions.'
                    },
                    {
                        step : '02',
                        title: 'Committed & Accountable',
                        description:'We work as your own internal team and leave no stones unturned to achieve your success.'
                    },
                    {
                        step : '03',
                        title: 'End-to-end solutions',
                        description:'We will do all the heavy lifting while you focus on your core competency.'
                    },
                    {
                        step : '04',
                        title: 'Launched 35 products',
                        description:'Team with a proven track record of successfully launching digital products.'
                    },
                    {
                        step : '05',
                        title: 'Faster turnaround time',
                        description:'We work with perfected open source technologies (ReactJS & Python) that drastically reduces our lead time. '
                    },
                    {
                        step : '06',
                        title: 'Transparent process',
                        description:'We follow an agile & sprint-based approach focussing on scalability and reliability.'
                    },
                    {
                        step : '07',
                        title: 'All IP rights belong to you',
                        description:'We associate with you purely on a work-for-hire basis.'
                    }
                ]
            },
            feedbacks:[
                {
                  feed : 'I think they are very responsive, and, out of that, we got a much better app',
                  img: '',
                  author: '',
                  designation: 'Head of Operations',
                  company: 'OneSky Systems, Inc.'
                },
                {
                  feed : 'The most impressive thing for us is their thorough professionalism and excellent domain expertise.',
                  img: 'syed_dp.png',
                  author: 'Syed Waqarul Haque',
                  designation: 'Head of Project',
                  company: 'MyoPal'
                },
                {
                  feed : 'They took ownership to ensure we meet our business objectives',
                  img: 'savitha_dp.png',
                  author: 'Savitha Kuttan',
                  designation: 'Founder & CEO',
                  company: 'Omnicuris'
                },
                {
                  feed : 'What impressed me the most was their commitment to fine-tuning and improving the product until it was perfect.',
                  img: '',
                  author: 'Anirban Biswas',
                  designation: 'Founder',
                  company: 'Ek fota Katambrita'
                }
            ]
        }
        this.tabsRef = React.createRef();
        this.processRef = React.createRef();
        this.caseStudiesRef = React.createRef();
        this.whyRef = React.createRef();
    }

    scrollToSection = (ref) => {
        this[ref].current.scrollIntoView({block:'start', behavior: "smooth"});
    }

    render() {
        const{feedbacks, activeTab, bannerDetails, sliderDetails, tabTitles, tabContent, processContent, quote, caseStudies, feature} = this.state;
        return (
            <div class={styles.lauchPage}>

            <Helmet>
              <title>DesignString - Launch your design-first digital product with us</title>
              <meta id="meta-description" name="description" content="Team with experience of launching 35+ digital products. With a fast turnaround time, we provide end-to-end solutions to take your product to the world" />
              <meta id="og-title" property="og:title" content="DesignString - Launch your design-first digital product with us" />
              <meta id="og-description" property="og:description" content="Team with experience of launching 35+ digital products. With a fast turnaround time, we provide end-to-end solutions to take your product to the world" />
              <meta id="og-image" property="og:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/launch.jpg" />
              <meta id="og-url" property="og:url" content="https://designstring.com/what-we-do/launch" />
              <meta id="twitter-title" property="twitter:title" content="DesignString - Launch your design-first digital product with us" />
              <meta id="twitter-description" property="twitter:description" content="Team with experience of launching 35+ digital products. With a fast turnaround time, we provide end-to-end solutions to take your product to the world" />
              <meta id="twitter-image" property="twitter:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/launch.jpg" />
              <meta id="twitter-url" property="twitter:url" content="https://designstring.com/what-we-do/launch" />
              <meta id="twitter-card" property="twitter:card" content="summary_large_image" />
            </Helmet>

                <section class={styles.bannerSection}>
                    <Banner bannerDetails={bannerDetails} sliderDetails={sliderDetails}/>
                </section>
                <section className={styles.tabsTitleSection}>
                    <div className={styles.tabsContainer}>
                        <Container maxWidth="lg">
                            <Grid container direction="row" className={styles.tabsListContainer}>
                                <Scrollspy
                                    items={['tab-1', 'tab-2', 'tab-3', 'tab-4']}
                                    currentClassName={styles.active}
                                    // offset={-100}
                                    className="flex-not-centered list-inline"
                                >
                                {
                                    tabTitles.map((title, key) => (
                                        <li class="list-inline-item" key={key} >
                                            <AnchorLink href={`#tab-${title.index}`} className={"paragraph"}>
                                                {title.title}
                                            </AnchorLink>
                                        </li>
                                    ))
                                }
                                </Scrollspy>
                            </Grid>
                        </Container>
                    </div>
                </section>
                <section class={styles.tabsSection} ref={this.tabsRef} id="tab-1">
                    <Tabs scrollToSection={this.scrollToSection} tabTitles={tabTitles} tabContent={tabContent} />
                </section>
                <section class={styles.processSection} ref={this.processRef} id="tab-2">
                    <Process processContent={processContent} quote={quote} />
                </section>
                <div ref={this.caseStudiesRef} id="tab-3">
                    <CaseStudies feedbacks={feedbacks} caseStudies={caseStudies} />
                </div>
                <div ref={this.whyRef} id="tab-4">
                    <WhatSetsUs feature={feature}/>
                </div>
                <FooterTop link="contact" title={<>Got a big idea ? Let’s make it <br/> happen</>} />
            </div>
        )
    }
}

export default Launch
