import React from 'react'
import { CoreValues, Perks, OpenPositions } from '../components'
import { FooterTop, PageBio } from '../../../shared_elements';
import styles from '../../../assets/styles/careers.module.scss';
import {Helmet} from "react-helmet";

export default function Careers(props){
    const handleClick = () => {
        document.getElementById(`openings`).scrollIntoView({behavior: 'smooth'});
    }
    return(
        <div className={styles.careersPage}>
            <Helmet>
                <title>DesignString - UI/UX Careers in building design-first digital products</title>
                <meta id="meta-description" name="description" content="UI/UX roles in Bangalore. Join us as we continue on our mission to impact lives and drive a positive change, one product at a time" />
                <meta id="og-title" property="og:title" content="DesignString - UI/UX Careers in building design-first digital products" />
                <meta id="og-description" property="og:description" content="UI/UX roles in Bangalore. Join us as we continue on our mission to impact lives and drive a positive change, one product at a time" />
                <meta id="og-image" property="og:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/careers.jpg" />
                <meta id="og-url" property="og:url" content="https://designstring.com/careers" />
                <meta id="twitter-title" property="twitter:title" content="DesignString - UI/UX Careers in building design-first digital products" />
                <meta id="twitter-description" property="twitter:description" content="UI/UX roles in Bangalore. Join us as we continue on our mission to impact lives and drive a positive change, one product at a time" />
                <meta id="twitter-image" property="twitter:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/careers.jpg" />
                <meta id="twitter-url" property="twitter:url" content="https://designstring.com/careers" />
                <meta id="twitter-card" property="twitter:card" content="summary_large_image" />
            </Helmet>
            <section className={styles.pageBioSection}>
                <PageBio
                    title={<>To be an enabler & maker space for <span>user-centric digital products</span> that matter !</>}
                    description={`If you think this excites you and you want to make a difference to our team, come join us.`}
                    btnText={`See Opening Positions`}
                    img={`v4/careers_new_bg.png`}
                    handleClick={handleClick}
                    customClassName={`careersPage`}
                />
            </section>
            <CoreValues />
            <Perks />
            <OpenPositions />
            <FooterTop link="contact" title={<>Got a big idea ? Let’s make it <br/> happen</>} />
        </div>
    )
}
