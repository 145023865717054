import React, {Component} from 'react';
import {Container, Grid} from '@material-ui/core';
import {imageBasePath} from '../../../constants';
import styles from '../../../assets/styles/tinycanvas.module.scss';

export default function(props) {
  return(
    <Container maxWidth="md" className={styles.pricingContainer}>
      <Grid container direction="row" className={styles.titleRow}>
        <Grid item xs={12}>
          <h3 className={styles.title + ' heading1'}>Pricing Details</h3>
          <p className="sub-heading2">To start using TinyCanvas plugin,user should have TinyPNG account and can start compressing images</p>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="flex-start" spacing={3} alignItems="center" className={styles.contentRow + ' flex-centered'}>
        <Grid item xs={12} md={6} sm={6} className={styles.cardBlk}>
          <div className={styles.cardWrapper + ' ' + styles.freeAccount}>
            <div className={styles.contentBlk}>
              <h5 className="heading1">
                <span className={styles.free}>Free</span>
                <span className={styles.text}>TinyPNG Account</span>
              </h5>
              <p className="paragraph">You can compress only 500 images per month</p>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={6} sm={6} className={styles.cardBlk}>
          <div className={styles.cardWrapper  + ' ' + styles.proAccount}>
            <h5 className="heading1">
              <img src={imageBasePath + 'pro.svg'} alt="Go Pro"/>
              <span className={styles.text}>Go Pro</span>
            </h5>
            <p className={styles.description + ' paragraph'}>You only pay for what you use. The first 500  compressions each month <span>free</span>. You will only be billed if you compress more than <span> 500 images </span></p>
            <p className={styles.description + ' paragraph'}>Check out more  details on the <a href="https://tinypng.com/developers" rel="noopener" target="_blank"> price calculator of tinyPNG </a> </p>
          </div>
        </Grid>
      </Grid>
      <Grid container direction="row" className={styles.poweredByRow}>
        <Grid item xs={12}>
          <p className="paragraph">
            <span>Powered by:</span>
            <a href="https://tinypng.com/" target="_blank" rel="noopener"><img src={imageBasePath + 'tinypng.svg'} alt="TinyPNG"/></a>
          </p>
        </Grid>
      </Grid>
    </Container>
  )
}
