import React from 'react'
import {Container, Grid} from '@material-ui/core'
import {imageBasePath} from '../../../constants';
import styles from '../../../assets/styles/casestudy_detail.module.scss';

export default function Feedback(props) {
    const{clientFeedback, writeClutchReview}= props;
    return (
        <Container maxWidth="lg">
            <Grid container alignItems="center" direction="row" spacing={4}>
                <Grid item xs={12} sm={8} md={8}>
                    <img  className={styles.quoteImg} src={imageBasePath+'v4/quotemarks_left.svg'} />
                    <p className={styles.feeds+' heading4'}>
                       {clientFeedback.feedback}
                    </p>
                    {
                        clientFeedback && clientFeedback.clientDetail && !clientFeedback.clientDetail.img ?
                        <>
                            <h4 className="heading4">{clientFeedback.clientDetail.name}</h4>
                            <p className="paragraph" style={{ fontWeight: 600 }}>{clientFeedback.clientDetail.designation} {clientFeedback.clientDetail.company}</p>
                            <br/>
                        </>
                        : null
                    }
                    {
                        writeClutchReview ?
                        <a target="_blank" class={"secondaryBtn "+ styles.reviewBtn} href={writeClutchReview}><span class="paragraph">Read full review on clutch</span><img src="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/go_arrow.svg" alt="Image" class="inActive"/></a>
                        : null
                    }
                </Grid>
                {
                    clientFeedback && clientFeedback.clientDetail && clientFeedback.clientDetail.img ?
                    <Grid item xs={12} sm={4} md={4} className={styles.feedbackRight +' flexNotCentered'}>
                        <div className={styles.feederInfo}>
                            <img style={{width:"182px" }} src={imageBasePath+'v4/'+clientFeedback.clientDetail.img} alt={clientFeedback.clientDetail.name} />
                            <h4 className="heading4">{clientFeedback.clientDetail.name}</h4>
                            <p className="paragraph">{clientFeedback.clientDetail.designation} {clientFeedback.clientDetail.company}</p>
                        </div>
                    </Grid>
                    : null
                }
            </Grid>
        </Container>
    )
}
