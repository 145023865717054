import React, {Component} from 'react';
import {Container, Grid, FormControlLabel, Checkbox, TextField, FormGroup, FormControl, Select, MenuItem, InputLabel} from '@material-ui/core';
import styles from '../../../assets/styles/contact.module.scss';

export default function(props) {
  const [lookingfor, setLookingfor] = React.useState('');

  const handleChange = (event) => {
    setLookingfor(event.target.value);
  };

  const{error, formData, submitLoader} = props;

  return(

    <div className={styles.formWrapper}>
      <form>
        <h3 className="heading4">You looking for?</h3>
        <div className={styles.inputGroupNew + ' inputGroupNew'}>
          <FormControl variant="outlined">
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              // value={lookingfor}
              // onChange={handleChange}
              // defaultValue={1}
              value={formData.looking_for ? formData.looking_for : ''}
              onChange={(e) => props.handleChange('looking_for', e.target.value)}
            >
              <MenuItem value={1}>Launch a Product from Scratch</MenuItem>
              <MenuItem value={2}>Revamp the User Experience</MenuItem>
              <MenuItem value={3}>Onboard Product Designers</MenuItem>
              <MenuItem value={4}>Other</MenuItem>
            </Select>
          </FormControl>
          {
            error.looking_for ?
            <h6 className="error-msg" >{error.looking_for}</h6>
            :
            null
          }
        </div>
        {
          formData.looking_for == 4 ?
          null
          :
          <div className={styles.productDetails}>
            <h3 className="heading4">Product Details</h3>
              <div className={styles.inputGroupNew + ' inputGroupNew'}>
              <TextField
                id="productName"
                label="Product Name *"
                variant="outlined"
                value={formData.product_name ? formData.product_name : ''}
                onChange={(e) => props.handleChange('product_name', e.target.value)}
              />
              {
                error.product_name ?
                <h6 className="error-msg" >{props.error.product_name}</h6>
                :
                null
              }
            </div>
            <div className={styles.inputGroupNew + ' inputGroupNew'}>
              <TextField
                id="websiteLink"
                label="Website Link"
                variant="outlined"
                value={formData.product_link ? formData.product_link : ''}
                onChange={(e) => props.handleChange('product_link', e.target.value)}
              />
              {
                error.product_link ?
                <h6 className="error-msg" >{props.error.product_link}</h6>
                :
                null
              }
            </div>
            <div className={styles.inputGroupNew + ' inputGroupNew'}>
              <TextField
                id="productBrief"
                label="Product Brief"
                variant="outlined"
                multiline
                rows={4}
                value={formData.product_description ? formData.product_description : ''}
                onChange={(e) => props.handleChange('product_description', e.target.value)}
              />
              {
                error.product_description ?
                <h6 className="error-msg" >{props.error.product_description}</h6>
                :
                null
              }
            </div>
          </div>
        }
        <div className={styles.contactDetails}>
          <h3 className="heading4">Contact Details</h3>
          <div className={styles.inputGroupNew + ' inputGroupNew'}>
            <TextField
              id="yourName"
              label="Your  Name*"
              variant="outlined"
              value={formData.contact_name ? formData.contact_name : ''}
              onChange={(e) => props.handleChange('contact_name', e.target.value)}
            />
            {
              error.contact_name ?
              <h6 className="error-msg" >{props.error.contact_name}</h6>
              :
              null
            }
          </div>
          <div className={styles.inputGroupNew + ' inputGroupNew'}>
            <TextField
              id="emailAddress"
              label="Email Address*"
              variant="outlined"
              value={formData.contact_email ? formData.contact_email : ''}
              onChange={(e) => props.handleChange('contact_email', e.target.value)}
            />
            {
              error.contact_email ?
              <h6 className="error-msg" >{props.error.contact_email}</h6>
              :
              null
            }
          </div>
          <div className={styles.inputGroupNew + ' inputGroupNew'}>
            <TextField
              id="contactNumber"
              label="Contact Number"
              variant="outlined"
              value={formData.contact_number ? formData.contact_number : ''}
              onChange={(e) => props.handleChange('contact_number', e.target.value)}
            />
            {
              error.contact_number ?
              <h6 className="error-msg" >{props.error.contact_number}</h6>
              :
              null
            }
          </div>
          <div className={styles.inputGroupNew + ' inputGroupNew'}>
            <TextField
              id="message"
              label="Anything else you want us to know before we reach-out? "
              variant="outlined"
              multiline
              rows={4}
              value={formData.message ? formData.message : ''}
              onChange={(e) => props.handleChange('message', e.target.value)}
            />
            {
              error.message ?
              <h6 className="error-msg" >{props.error.message}</h6>
              :
              null
            }
          </div>
        </div>
        <div className={styles.submitBlk}>
          <button type="button" className={`primaryBtn ${submitLoader ? ' submitDisabled' : null}`} onClick={(e) => {e.preventDefault(); e.stopPropagation(); props.handleSubmit()}}>{submitLoader ? 'Submitting...' : 'Submit'}</button>
        </div>
      </form>
    </div>
  )
}
