import React from 'react';
import {Container, Grid,List, ListItem} from '@material-ui/core';
import {imageBasePath} from '../constants';
import { Link, withRouter } from "react-router-dom";

const urls = [
  '/',
  '/approach',
  '/work',
  '/community',
  '/life-at-designstring',
  '/company',
  '/contact'
]

function PageNavigation(props) {
  return(
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item xs={12}>
        <div className="pageNavigation">
          <List className="list-inline pageNavigationList">
            <ListItem className={urls.indexOf(props.location.pathname) === 0 ? "list-inline-item disabled" : "list-inline-item"}>
              <Link onClick={() => urls.indexOf(props.location.pathname) > 0 ? props.history.push(urls[urls.indexOf(props.location.pathname) - 1]) : null}>
                <img src={ imageBasePath+'prev.png' } alt="Prev"/>
              </Link>
            </ListItem>
            <ListItem className={urls.indexOf(props.location.pathname) === (urls.length - 1) ? "list-inline-item disabled" : "list-inline-item"}>
              <Link onClick={() => urls.indexOf(props.location.pathname) < (urls.length - 1) ? props.history.push(urls[urls.indexOf(props.location.pathname) + 1]) : null}>
                <img src={ imageBasePath+'next.png' } alt="Next"/>
              </Link>
            </ListItem>
          </List>
        </div>
        </Grid>
    </Grid>
  )
}

export default withRouter(PageNavigation)
