import React, { Component } from 'react';
import {Container, Grid} from '@material-ui/core';
import styles from '../../../assets/styles/whatwedo.module.scss';
import {Banner, Tabs, Process} from '../components';
import {CaseStudies, WhatSetsUs} from '../../home/components';
import {FooterTop} from '../../../shared_elements/'
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Scrollspy from 'react-scrollspy';
import {Helmet} from "react-helmet";

class Accelerate extends Component {
    constructor(props){
        super(props);
        this.state = {
            activeTab : 1,
            bannerDetails : {
                type: 'Accelerate',
                typeImage : 'accelerate.svg',
                title : <>Take your product from <br/> good to great</>,
                subtitle: 'Ideal for digital products looking to revamp their existing user experience to make it more intuitive and appealing'
            },
            sliderDetails : [
                {
                    link: '/work/kaleyra/',
                    product_img: 'kaleyra_featured_home.png'
                },
                {
                    link: '/work/cleartrip/',
                    product_img: 'cleartrip_featured_home.png'
                }
            ],
            tabTitles : [
                {
                    index : 1,
                    title : 'Right fit for',
                    ref : 'tabsRef'
                },
                {
                    index : 2,
                    title : 'Process',
                    ref : 'processRef'
                },
                {
                    index : 3,
                    title : 'Case Studies',
                    ref : 'caseStudiesRef'
                },
                {
                    index : 4,
                    title : 'Why DesignString',
                    ref : 'whyRef'
                }
            ],
            tabContent : {
                '1' : {
                    title : 'We are the right fit if you',
                    descriptionLeft: 'Want to improve your product’s user experience but are unsure about the what and how',
                    descriptionRight: 'Have a legacy digital product that is vast and complex and are struggling to make change happen'
                }
            },
            processContent : [
                {
                    img : 'audit.png',
                    title : 'Audit',
                    description: 'Audit existing product user experience.'
                },
                {
                    img : 'benchmarking.png',
                    title : 'Benchmarking',
                    description: 'Forming problem statements based on research and competitor benchmarking.'
                },
                {
                    img : 'user_validation.png',
                    title : 'User validation',
                    description: 'Validate the revamped experience with prototypes. User validation through interviews.'
                },
                {
                    img : 'hand_over.png',
                    title : 'Hand over',
                    description: 'Hand over and provide support to implement the revamped experience.'
                }
            ],
            quote : 'At every step, our team works across departments to understand pain points, navigate constraints and build consensus.',
            caseStudies : [
                {
                  title: 'Kaleyra',
                  featuredImage: 'kaleyra_work_cover.png',
                  logo: 'kaleyra_dp.png',
                  description: 'Unifying multiple interfaces to build a single self-serve flagship product and cut down on overhead costs',
                  link: '/work/kaleyra/'
                },
                {
                  title: 'Cleartrip',
                  featuredImage: 'cleartrip_work_cover.png',
                  logo: 'cleartrip_dp.png',
                  description: 'Identifying gaps and revamping the B2B user experience to make it intuitive and best in the industry',
                  link: '/work/cleartrip/',
                }
            ],
            feature : {
                title : <>Why DesignString to <br/>revamp my product ?</>,
                list : [
                    {
                        step : '01',
                        title: 'Collaborative approach',
                        description:'Designers with exceptional people skills who can work side by side with internal teams across functions to get the job done.'
                    },
                    {
                        step : '02',
                        title: 'Committed & Accountable',
                        description:'We work as your own internal team and leave no stones unturned to achieve your success.'
                    },
                    {
                        step : '03',
                        title: 'Perfected process',
                        description:'Adaptive design to suit product needs and provide a framework to build upon.'
                    },
                    {
                        step : '04',
                        title: 'Highly specialized team',
                        description:'Experienced designers skilled at working on core product designs across SaaS, Mobile Apps, B2B / B2C Apps.'
                    },
                    {
                        step : '05',
                        title: 'Diversified approach',
                        description:'An active design community facilitates inputs by peers and seniors.'
                    },
                    {
                        step : '06',
                        title: 'Strategic advantage',
                        description:'Guidance from domain experts to reduce risks and build a competitive advantage.'
                    }
                ]
            },
            feedbacks:[
                {
                    feed : 'The attitude of getting things done and their agility in adapting to the situation were very impressive.',
                    img: 'ashish_dp.png',
                    author: 'Ashish Agarwal',
                    designation: 'Cheif Technology Officer',
                    company: 'kaleyra'
                },
                {
                  feed : 'They were flexible, reliable, and always there to support us.',
                  img: 'chethan_dp.png',
                  author: 'Chethan Sharma',
                  designation: 'Head of Marketing',
                  company: 'Cleartrip'
                },
                {
                  feed : 'They kept to timelines and did what was right without compromising.',
                  img: '',
                  author: '',
                  designation: 'Director',
                  company: 'News Technologies Services Pvt Ltd'
                },
                {
                  feed : 'The key to any successful initiative is the empathy and flexibility to hear each other.',
                  img: 'vijay_dp.png',
                  author: 'Vijayaraghavan Venugopal',
                  designation: 'Founder',
                  company: 'Fast&Up'
                }
            ]
        }
        this.tabsRef = React.createRef();
        this.processRef = React.createRef();
        this.caseStudiesRef = React.createRef();
        this.whyRef = React.createRef();
    }

    scrollToSection = (ref) => {
        this[ref].current.scrollIntoView({block:'start', behavior: "smooth"});
    }

    render() {
        const{feedbacks, activeTab, bannerDetails, sliderDetails, tabTitles, tabContent, processContent, quote, caseStudies, feature} = this.state;
        return (
            <div class={styles.lauchPage}>
              <Helmet>
                <title>DesignString - Revamp your digital product with us</title>
                <meta id="meta-description" name="description" content="Collaborative approach and experienced designers skilled at working on core product designs across SaaS, Mobile Apps, B2B / B2C Apps" />
                <meta id="og-title" property="og:title" content="DesignString - Revamp your digital product with us" />
                <meta id="og-description" property="og:description" content="Collaborative approach and experienced designers skilled at working on core product designs across SaaS, Mobile Apps, B2B / B2C Apps" />
                <meta id="og-image" property="og:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/accelerate.jpg" />
                <meta id="og-url" property="og:url" content="https://designstring.com/what-we-do/accelerate" />
                <meta id="twitter-title" property="twitter:title" content="DesignString - Revamp your digital product with us" />
                <meta id="twitter-description" property="twitter:description" content="Collaborative approach and experienced designers skilled at working on core product designs across SaaS, Mobile Apps, B2B / B2C Apps" />
                <meta id="twitter-image" property="twitter:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/accelerate.jpg" />
                <meta id="twitter-url" property="twitter:url" content="https://designstring.com/what-we-do/accelerate" />
                <meta id="twitter-card" property="twitter:card" content="summary_large_image" />
              </Helmet>
                <section class={styles.bannerSection}>
                    <Banner bannerDetails={bannerDetails} sliderDetails={sliderDetails}/>
                </section>
                <section className={styles.tabsTitleSection}>
                    <div className={styles.tabsContainer}>
                        <Container maxWidth="lg">
                            <Grid container direction="row" className={styles.tabsListContainer}>
                                <Scrollspy
                                    items={['tab-1', 'tab-2', 'tab-3', 'tab-4']}
                                    currentClassName={styles.active}
                                    // offset={-100}
                                    className="flex-not-centered list-inline"
                                >
                                {
                                    tabTitles.map((title, key) => (
                                        <li class="list-inline-item" key={key} >
                                            <AnchorLink href={`#tab-${title.index}`} className={"paragraph"}>
                                                {title.title}
                                            </AnchorLink>
                                        </li>
                                    ))
                                }
                                </Scrollspy>
                            </Grid>
                        </Container>
                    </div>
                </section>
                <section class={styles.tabsSection} ref={this.tabsRef} id="tab-1">
                    <Tabs scrollToSection={this.scrollToSection} tabTitles={tabTitles} tabContent={tabContent} />
                </section>
                <section class={styles.processSection} ref={this.processRef} id="tab-2">
                    <Process processContent={processContent} quote={quote} />
                </section>
                <div ref={this.caseStudiesRef} id="tab-3">
                    <CaseStudies feedbacks={feedbacks} caseStudies={caseStudies} />
                </div>
                <div ref={this.whyRef} id="tab-4">
                    <WhatSetsUs feature={feature}/>
                </div>
                <FooterTop link="contact" title={<>Ready to revamp your product user experience?</>} />
            </div>
        )
    }
}

export default Accelerate
