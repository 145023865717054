import React, { Component } from "react";
import { Container, Grid } from "@material-ui/core";
import styles from "../../../assets/styles/home.module.scss";
import {
  Banner,
  DesignPartners,
  WhatSetsUs,
  WhatweDo,
  CaseStudies,
} from "../components";
import { PageBio, FooterTop } from "../../../shared_elements";
import { Helmet } from "react-helmet";
import { Link, withRouter } from "react-router-dom";
import { imageBasePath } from "../../../constants";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      caseStudies: [
        {
          title: "Amby",
          featuredImage: "amby_work_cover.png",
          type: "Launch",
          typeImage: "launch.svg",
          logo: "amby_dp.png",
          description:
            "Making OKR-based goal-setting and management as easy and adoptable as an excel spreadsheet",
          link: "/work/amby/",
        },
        {
          title: "Kaleyra",
          featuredImage: "kaleyra_work_cover.png",
          type: "Accelerate",
          typeImage: "accelerate.svg",
          logo: "kaleyra_dp.png",
          description:
            "Unifying multiple interfaces to build a single self-serve flagship product and cut down on overhead costs",
          link: "/work/kaleyra/",
        },
        {
          title: "Rapido",
          featuredImage: "rapido_work_cover.png",
          type: "Partner",
          typeImage: "partner.svg",
          logo: "rapido_dp.png",
          description:
            "Streamlining UX efforts by filling in gaps in skill sets and setting up established design processes",
          link: "/work/rapido/",
        },
      ],
      feature: {
        title: (
          <>
            What sets us <br /> apart from the crowd
          </>
        ),
        list: [
          {
            step: "01",
            title: "Design-first always",
            description:
              "A user-centric design approach forms the core of all our solutions.",
          },
          {
            step: "02",
            title: "Committed & Accountable",
            description: "Our success lies in achieving our client’s success.",
          },
          {
            step: "03",
            title: "Expertise across several industries",
            description:
              "Chances are whatever your industry, we would have already worked in a similar space.",
          },
          {
            step: "04",
            title: "Dedicated teams for all products",
            description:
              "You get a dedicated team that will focus solely on your product.",
          },
        ],
      },
      feedbacks: [
        {
          feed: "They have a great process and system in place, and they know what the end-user wants",
          img: "aniketh_dp.png",
          author: "Aniketh Jain",
          designation: "Chief Revenue Officer",
          company: "Kaleyra",
        },
        {
          feed: "We appreciated how obsessive they were about design, making sure the platform focused on the user's needs",
          img: "rishikesh_dp.png",
          author: "Rishikesh S R",
          designation: "Co-founder",
          company: "Rapido",
        },
        {
          feed: "My experience with DesignString been really good. Both interms of people and commitment",
          img: "manish_dp.png",
          author: "Manish Singh",
          designation: "Head of Products",
          company: "Blackbuck",
        },
      ],
    };
  }

  render() {
    const { caseStudies, feature, feedbacks } = this.state;
    return (
      <div className={styles.homePage}>
        <Helmet>
          <title>
            DesignString - UI/UX agency dedicated to digital products
          </title>
          <meta
            id="meta-description"
            name="description"
            content="DesignString is now BlockTheory"
          />
          <meta
            id="og-title"
            property="og:title"
            content="DesignString - UI/UX agency dedicated to digital products"
          />
          <meta
            id="og-description"
            property="og:description"
            content="DesignString is now BlockTheory"
          />
          <meta
            id="og-image"
            property="og:image"
            content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/mainsunset.jpg"
          />
          <meta
            id="og-url"
            property="og:url"
            content="https://designstring.com/"
          />
          <meta
            id="twitter-title"
            property="twitter:title"
            content="DesignString - UI/UX agency dedicated to digital products"
          />
          <meta
            id="twitter-description"
            property="twitter:description"
            content="DesignString is now BlockTheory"
          />
          <meta
            id="twitter-image"
            property="twitter:image"
            content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/mainsunset.jpg"
          />
          <meta
            id="twitter-url"
            property="twitter:url"
            content="https://designstring.com/"
          />
          <meta
            id="twitter-card"
            property="twitter:card"
            content="summary_large_image"
          />
        </Helmet>

        <Banner />
      </div>
    );
  }
}
