import React from 'react'
import {Container, Grid} from '@material-ui/core'
import {imageBasePath} from '../../../constants';
import styles from '../../../assets/styles/casestudy_detail.module.scss';

export default function Hero(props) {
    const{pageName, heroDetails} = props;
    return (
        <Container maxWidth="lg">
            <Grid container  direction="row" spacing={2}>
                <Grid item xs={12} sm={12} md={7}>
                    <h2 className={styles.launchTitle+ " flex-centered subHeading2Med"}>
                        <img src={imageBasePath+'v4/'+heroDetails.typeImage} alt={heroDetails.type} />
                        <span>{heroDetails.type}</span>
                    </h2>
                    <h1 className="heading2">
                        {heroDetails.title}
                    </h1>
                    <p class="subHeading2Reg">
                        {heroDetails.subtitle}
                    </p>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                    <div className={styles.caseStudyBio}>
                        <img style={{height:"80px" }} className={styles.logo} src={imageBasePath+'v4/'+heroDetails.logo} alt={pageName+' logo'} />
                        <ul className="listUnstyled">
                            <li>
                                <span>Category</span>
                                <h3 className="heading4">{heroDetails.category}</h3>
                            </li>
                            <li>
                                <span>Geo</span>
                                <h3 className="heading4">{heroDetails.geo}</h3>
                            </li>
                            <li>
                                <span>Product</span>
                                <h3 className="heading4">{heroDetails.output}</h3>
                            </li>
                        </ul>
                    </div>
                </Grid>
            </Grid>
        </Container>
    )
}
