import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {imageBasePath} from '../constants';
import LazyLoad from 'react-lazyload';

export default function(props) {
  return(
    <Container className={`pageBioContainer ${props.customClassName}`} maxWidth="lg">
        <Grid container direction="row" justify="flex-start" alignItems="center">
            <Grid item lg={7} md={7} sm={12} xs={12} className="leftBlk">
                <div className="contentBlk">
                  {/* <h5 className="pageTitle">{props.pageName}</h5> */}
                  <h3 className="heading1Reg">{props.title}</h3>
                  <p className="subHeading1">{props.description}</p>
                  {props.btnText ? <p className="link"><a className="primaryBtn" onClick={props.handleClick}>{props.btnText}</a></p> : null}
                </div>
            </Grid>
            <Grid item lg={5} md={5} sm={12} xs={12} className="rightBlk">
                <div className="imgBlk">
                  <LazyLoad height={480} once >
                    <img className="patternImage" src={imageBasePath + props.img} alt="Image" />
                  </LazyLoad>
                </div>
            </Grid>
        </Grid>
    </Container>
  )
}
