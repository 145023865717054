import React,{useState} from 'react';
import {Container, Grid} from '@material-ui/core';
import styles from '../../../assets/styles/whatwedo.module.scss';

// const tabContent = {
//     '1' : {
//         title : 'We are the right fit if you',
//         descriptionLeft: 'Have a strong digital product idea or Proof of Concept or MVP in place and are looking for an experienced partner to handle everything - right from design to tech to QA.',
//         descriptionRight: 'Are a growing enterprise business with a strong digital product idea but is finding it difficult to execute due to internal team bandwidth.'
//     },
//     '2' : {
//         title : 'We are the right fit if you2',
//         descriptionLeft: 'Have a strong digital product idea or Proof of Concept or MVP in place and are looking for an experienced partner to handle everything - right from design to tech to QA.',
//         descriptionRight: 'Are a growing enterprise business with a strong digital product idea but is finding it difficult to execute due to internal team bandwidth.'
//     },
//     '3' : {
//         title : 'We are the right fit if you3',
//         descriptionLeft: 'Have a strong digital product idea or Proof of Concept or MVP in place and are looking for an experienced partner to handle everything - right from design to tech to QA.',
//         descriptionRight: 'Are a growing enterprise business with a strong digital product idea but is finding it difficult to execute due to internal team bandwidth.'
//     },
//     '4' : {
//         title : 'We are the right fit if you4',
//         descriptionLeft: 'Have a strong digital product idea or Proof of Concept or MVP in place and are looking for an experienced partner to handle everything - right from design to tech to QA.',
//         descriptionRight: 'Are a growing enterprise business with a strong digital product idea but is finding it difficult to execute due to internal team bandwidth.'
//     },
// }

function Tabs(props) {
    const{tabTitles, tabContent} = props;
    const [activeTab, setActiveTab] = useState(1);
    return (
        <>
            {/* <div className={styles.tabsContainer}>
                <Container maxWidth="lg">
                    <Grid container direction="row" className={styles.tabsListContainer}>
                        {
                            tabTitles.map((title, key) => (
                                <Grid key={key} item xs={3}>
                                    <a href="javascript:void(0)" className={activeTab == title.index ? styles.active +" paragraph" : "paragraph"} onClick={() => {setActiveTab(title.index); props.scrollToSection(title.ref) }}>{title.title}</a>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Container>
            </div>   */}
            <div className={styles.tabsContentContainer} >
                <Container maxWidth="lg">
                    <div className={styles.tabsContentBlock}>
                        <div className={styles.tabContent}>
                            <h2 className={styles.contentTitle+" heading2"}>{tabContent[1].title}</h2>
                            <Grid container direction="row" spacing={4}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <p className={styles.description+" subHeading1"}>
                                        {tabContent[1].descriptionLeft}
                                    </p>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <p className={styles.description+" subHeading1"}>
                                        {tabContent[1].descriptionRight}
                                    </p>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default Tabs
