import React, {Component} from 'react';
import {Container, Grid} from '@material-ui/core';
import {imageBasePath} from '../../../constants';
import { Link } from 'react-router-dom';
import styles from '../../../assets/styles/tinycanvas.module.scss';

export default function(props) {
  return(
    <Container maxWidth="md" className={styles.howLandingContainer}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          <h2 className={styles.title + ' heading1'}>How it Works on Figma?</h2>
          <p className="sub-heading2">Check out the video to know more about TinyCanvas</p>
          <div className={styles.videoBlk}>
            <video controls="controls" height="300" width="600">
              <source src={imageBasePath + "tinycanvas.mp4"} type="video/mp4" />
            </video>
            <p className={styles.horizontalBar}></p>
          </div>
          <p>
            <Link to="/tinycanvas/detail" className="primaryBtn readBtn">
              Read how it works
            </Link>
          </p>
        </Grid>
      </Grid>
    </Container>
  )
}
