import React, { Component } from 'react'
import {Hero, Bg, Challenge, Solution, Feedback, Results, FeaturedWorks} from '../components'
import styles from '../../../assets/styles/casestudy_detail.module.scss';
import {FooterTop} from '../../../shared_elements/'
import {CaseStudies} from '../../home/components';
import { imageBasePath } from '../../../constants';
import {Helmet} from "react-helmet";

export class RapidoCaseStudy extends Component {
    constructor(props){
        super(props);
        this.state = {
            pageName : 'rapido',
            heroDetails: {
                title : 'Streamlining UX efforts by filling in gaps in skill sets and setting up established design processes',
                subtitle: 'Rapido is India’s first and fastest-growing Bike taxi app. It has two mobile apps - Consumer-facing, which allows users to book bike taxis near them & Captain app, which enables bike-riders (captains) to serve the users who book the ride. As a tech first company, their development team was strong. However, the design team was struggling to keep up with the growth. As a young company growing at a rapid pace, Rapido needed to quickly scale its design team, define a visual style guide and set processes that enabled all stakeholders to take strategic product design decisions.',
                type: 'Partner',
                typeImage: 'partner.svg',
                logo: 'rapido_dp.png',
                category: 'Travel / Commute',
                geo: 'India (200+ Cities)',
                output : 'Consumer Mobile experience (Android & iOS) Captain App (Android)'
            },
            bg:{
                img : 'rapido_work_bg.png'
            },
            challenge : 'The challenge at hand was to clear the design backlog and work towards bringing the design efforts ahead of engineering efforts. The partnership also involved filling in the missing design pieces and building a consistent user experience across the different platforms.',
            productType : 'website',
            solution: {
                description : [
                    'After an in-depth analysis of the current status of the product, we handpicked a suitable team with the right skill sets for the job. Our team would collaborate with the developers, be in meetings with the customer service teams and also brainstorm with the existing designers on an everyday basis. We worked towards delivering designs 3 weeks ahead of development. At the same time we ensured building a product that is intuitive and has world-class functionality.'
                ],
                media: {
                    
                }
            },
            disignsImage : 'rapido_screens.png',
            clientFeedback: {
                feedback: "The app is up and running and has gathered a large user base, meeting the expectations of the internal team. DesignString Solutions Pvt Ltd works closely with the internal team to ensure a smooth workflow. The team is creative, reliable, and attentive.",
                clientDetail: {
                    img: 'rishikesh_dp.png',
                    name: 'Rishikesh S R',
                    designation: 'Co-Founder',
                    company: 'Rapido'
                }
            },
            result: 'DesignString helped set up industry-standard design workflow for seamless, effective, and quality design outputs. With the ongoing partnership, the Design team has come at par with the Development team and ensures all delivery deadlines and quality standards are adhered to.',
            availableon: [
                {
                    img : 'android_version.png',
                    link: 'https://play.google.com/store/apps/details?id=com.rapido.passenger'
                },
                {
                    img : 'ios_version.png',
                    link: 'https://apps.apple.com/IN/app/id1198464606?mt=8'
                }
            ],
            caseStudies : [
                {
                    itle: 'Bookmyshow',
                    featuredImage: 'bookmyshow_work_cover.png',
                    type: 'Partner',
                    typeImage: 'partner.svg',
                    logo: 'book_my_show.svg',
                    description: 'Scaling up design efforts with speed and perfection to meet the growing needs of a highly competitive market',
                    link: '/work/bookmyshow/'
                },
                {
                    title: 'OneSky',
                    featuredImage: 'onesky_work_cover.png',
                    type: 'Launch',
                    typeImage: 'launch.svg',
                    logo: 'onesky_dp.png',
                    description: 'Translating complex airspace rules and regulations into coherent and ready-to-use information for small UAS and drone operators.',
                    link: '/work/onesky/'
                }
            ],
            writeClutchReview: 'https://clutch.co/profile/designstring?page=1#review-1365132',
        }
    }
    render() {
        const{pageName, heroDetails, bg, challenge, productType, solution, clientFeedback, result, availableon, caseStudies, disignsImage, writeClutchReview} =this.state;
        return (
            <div className={styles.caseStudyDetailPage}>
                <Helmet>
                    <title>DesignString: Case Study Rapido, Category: Travel</title>
                    <meta id="meta-description" name="description" content="How we helped streamline UX efforts by filling in gaps in skill sets and setting up established design processes" />
                    <meta id="og-title" property="og:title" content="DesignString: Case Study Rapido, Category: Travel" />
                    <meta id="og-description" property="og:description" content="How we helped streamline UX efforts by filling in gaps in skill sets and setting up established design processes" />
                    <meta id="og-image" property="og:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/rapido.jpg" />
                    <meta id="og-url" property="og:url" content="https://designstring.com/work/rapido" />
                    <meta id="twitter-title" property="twitter:title" content="DesignString: Case Study Rapido, Category: Travel" />
                    <meta id="twitter-description" property="twitter:description" content="How we helped streamline UX efforts by filling in gaps in skill sets and setting up established design processes" />
                    <meta id="twitter-image" property="twitter:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/rapido.jpg" />
                    <meta id="twitter-url" property="twitter:url" content="https://designstring.com/work/rapido" />
                    <meta id="twitter-card" property="twitter:card" content="summary_large_image" />
                </Helmet>
                <section className={styles.heroSection}>
                    <Hero pageName={pageName} heroDetails={heroDetails} />
                </section>
                <section className={styles.bgSection}>
                    <Bg pageName={pageName} bg={bg} />
                </section>
                <section className={styles.challengeSection}>
                    <Challenge pageName={pageName} challenge={challenge} />
                </section>
                <section className={styles.solutionSection}>
                    <Solution pageName={pageName} productType={productType} solution={solution} disignsImage={disignsImage} />
                </section>
                {
                    disignsImage ?
                    <section className={styles.disignsImageSection+' backgroundProp'} style={{ backgroundImage : `url(${imageBasePath+'v4/'+disignsImage})` }}>

                    </section>
                    : null
                }
                <section className={styles.feedbackSection}>
                    <Feedback pageName={pageName} clientFeedback={clientFeedback} writeClutchReview={writeClutchReview}/>
                </section>
                <section className={styles.resultSection}>
                    <Results pageName={pageName} result={result} availableon={availableon} />
                </section>
                <section className={styles.featuredWork}>
                    <CaseStudies caseStudies={caseStudies} />
                </section>
                <FooterTop link="contact" title={<>Ready to launch your product to the world?</>} />
            </div>
        )
    }
}

export default RapidoCaseStudy
