import React, {Component, Fragment} from 'react';
import {Banner, Form} from '../components';
import {Container, Grid} from '@material-ui/core';
import { FooterTop } from '../../../shared_elements';
import styles from '../../../assets/styles/contact.module.scss';
import { regExpression } from '../../../constants';
import { fieldValidation } from '../../../utils/formValidation';
import { createContactApi } from '../apiServices';
import { toastFlashMessage } from '../../../utils'
import {Helmet} from "react-helmet";

export default class Contact extends Component{
  constructor(props){
    super(props);
    this.state={
      loader:false,
      formData : {

      },
      error: {},
      errorCode: {
          looking_for : {
            0: '',
            1:'Please choose What You Looking For?',
          },
          looking_for_obj: {
              requiredFlag: true,
          },
          product_name : {
              0: '',
              1:'Please enter Product Name',
          },
          product_name_obj: {
              requiredFlag: true,
          },
          product_link : {
            0: '',
            1: 'Please enter product website link'
          },
          product_link_obj: {
              requiredFlag: false,
          },
          product_description : {
            0: '',
            1: 'Please enter What are you looking to achieve by on-boarding design firm?'
          },
          product_description_obj: {
              requiredFlag: false,
          },
          contact_name : {
            0: '',
            1:'Please enter Your Name',
          },
          contact_name_obj: {
              requiredFlag: true,
          },
          contact_number : {
            0: '',
            1:'Please enter Contact Number',
          },
          contact_number_obj: {
              requiredFlag: false,
          },
          contact_email : {
              0: '',
              1:'Please enter Email Address',
              4: 'Please enter valid Email Address'
          },
          contact_email_obj: {
              requiredFlag: true,
              regexPattern: regExpression.email
          },
          message : {
            0: '',
            1:'Please enter Message',
          },
          message_obj: {
              requiredFlag: false,
          },
      },
      submitLoader: false,
    }
    this.createContactApi = createContactApi.bind(this);

  }

  handleChange = (key, value) => {
    const{formData} = this.state;
    this.setState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [key]: value
      },
      error:{
        ...prevState.error,
        [key]: ''
      }
    }));
  }

  handleSubmit = () => {
    const {errorCode, formData, submitLoader} = this.state;
    let validateNewInput = {
      contact_name : errorCode['contact_name'][fieldValidation({...errorCode['contact_name_obj'], fieldval: formData.contact_name})],
      // contact_number : errorCode['contact_number'][fieldValidation({...errorCode['contact_number_obj'], fieldval: formData.contact_number})],
      contact_email : errorCode['contact_email'][fieldValidation({...errorCode['contact_email_obj'], fieldval: formData.contact_email})],
      looking_for : errorCode['looking_for'][fieldValidation({...errorCode['looking_for_obj'], fieldval: formData.looking_for ? formData.looking_for.toString(): ''})],
      // looking_for: 1
    }
    if(formData.looking_for !== 4){
      validateNewInput = {
        ...validateNewInput,
        product_name : errorCode['product_name'][fieldValidation({...errorCode['product_name_obj'], fieldval: formData.product_name})],
      }
    }
    if(Object.keys(validateNewInput).every(k => validateNewInput[k] == '')){
      this.setState({
        submitLoader : true
      });
      this.createContactApi({formData: formData});
    }else{
      this.setState({
        error : validateNewInput
      })
    }

  }

  render(){
    const{ error, formData, submitLoader, loader} = this.state;

    return (
      <div className={styles.contactPage + " contactPage"}>
        <Helmet>
          <title>DesignString - Mail, call or just drop by at our office</title>
          <meta
            id="meta-description"
            name="description"
            content="Contact us for launching design-first products, revamping product experiences or overcoming your product’s UX challenges"
          />
          <meta
            id="og-title"
            property="og:title"
            content="DesignString - Mail, call or just drop by at our office"
          />
          <meta
            id="og-description"
            property="og:description"
            content="Contact us for launching design-first products, revamping product experiences or overcoming your product’s UX challenges"
          />
          <meta
            id="og-image"
            property="og:image"
            content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/mainsunset.jpg"
          />
          <meta
            id="og-url"
            property="og:url"
            content="https://designstring.com/contact"
          />
          <meta
            id="twitter-title"
            property="twitter:title"
            content="DesignString - Mail, call or just drop by at our office"
          />
          <meta
            id="twitter-description"
            property="twitter:description"
            content="Contact us for launching design-first products, revamping product experiences or overcoming your product’s UX challenges"
          />
          <meta
            id="twitter-image"
            property="twitter:image"
            content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/mainsunset.jpg"
          />
          <meta
            id="twitter-url"
            property="twitter:url"
            content="https://designstring.com/contact"
          />
          <meta
            id="twitter-card"
            property="twitter:card"
            content="summary_large_image"
          />
        </Helmet>

        <section className={styles.contactSection}>
          <Container maxWidth="lg" className={styles.formConatiner}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              spacing={4}
            >
              <Grid item md={5} xs={12}>
                <Banner />
              </Grid>
              <Grid item md={7} xs={12}>
                <Form
                  formData={formData}
                  error={error}
                  handleChange={this.handleChange}
                  handleSubmit={this.handleSubmit}
                  submitLoader={submitLoader}
                />
              </Grid>
            </Grid>
          </Container>
        </section>
        <FooterTop
          link="contact"
          title={
            <>
              Got a big idea ? Let’s make it <br /> happen
            </>
          }
        />
      </div>
    );
  }
}
