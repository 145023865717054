import * as actions from './actions';
import { browserHistory } from 'react-router';
import produce from 'immer';
export default function(
  state = {
    toastLists: [],
  }, action){
  switch (action.type) {

    case actions.CLEAR_TOAST:
      return {
        ...state,
        toastLists: []
      }

    case actions.SHOW_TOAST:
        if(action.payload.toastType == 'error'){
            if(state.toastLists.filter(toast => toast.toastType == 'error').length < 1){
                return {
                    ...state,
                    toastLists: produce(state.toastLists, draft => {
                    draft.push(action.payload)
                    })
                }
            }else{
                return state;
            }
        }else{
            return {
                ...state,
                toastLists: produce(state.toastLists, draft => {
                    draft.push(action.payload)
            })
        }
    }

    case actions.HIDE_TOAST:
      if(state.toastLists){
        return {
          ...state,
          toastLists: []
        }
      }else{
        return state;
      }

    default:
        return state;
    }
}
