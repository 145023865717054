import React from "react";
import { Grid, Container } from "@material-ui/core";
import { ControlPoint } from "@material-ui/icons";
import { styled } from "@material-ui/styles";
import { imageBasePath } from "../constants";
import { Link, withRouter } from "react-router-dom";
import pageLayoutHoc from "../hocs/pageLayoutHoc";
import { Helmet } from "react-helmet";
const MyControlPoint = styled(ControlPoint)({
  color: "white",
});

function PageNotFound(props) {
  return (
    <>
      <Helmet>
        <title>Top UX / UI Design firm for SaaS & Mobile apps</title>
        <meta
          id="meta-description"
          name="description"
          content="We deliver results to tech-driven businesses through UX and UI design, help to build fantastic product experiences. Primary focus on SaaS &  Mobile apps"
        />
        <meta
          id="og-title"
          property="og:title"
          content="Top UX / UI Design firm for SaaS &  Mobile apps"
        />
        <meta
          id="og-description"
          property="og:description"
          content="We deliver results to tech-driven businesses through UX and UI design, help to build fantastic product experiences. Primary focus on SaaS &  Mobile apps"
        />
        <meta
          id="og-image"
          property="og:image"
          content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/mainsunset.jpg"
        />
        <meta
          id="og-url"
          property="og:url"
          content="https://designstring.com/"
        />
        <meta
          id="twitter-title"
          property="twitter:title"
          content="Top UX / UI Design firm for SaaS &  Mobile apps"
        />
        <meta
          id="twitter-description"
          property="twitter:description"
          content="We deliver results to tech-driven businesses through UX and UI design, help to build fantastic product experiences. Primary focus on SaaS &  Mobile apps"
        />
        <meta
          id="twitter-image"
          property="twitter:image"
          content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/mainsunset.jpg"
        />
        <meta
          id="twitter-url"
          property="twitter:url"
          content="https://designstring.com/"
        />
        <meta
          id="twitter-card"
          property="twitter:card"
          content="summary_large_image"
        />
      </Helmet>
      <Container className="pageBioContainer page404BioContainer" maxWidth="lg">
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          <Grid item md={6} xs={12} className="leftBlk">
            <div className="contentBlk">
              <h3 className="heading1">Oops! Some thing went wrong</h3>
              <p className="sub-heading1">
                <Link to="/" className="link">
                  <span>Go to home page</span>
                  <img
                    src={imageBasePath + "go_arrow.svg"}
                    alt="Go to Home Page"
                  />
                </Link>
              </p>
            </div>
          </Grid>
          <Grid item md={6} xs={12} className="rightBlk">
            <div className="imgBlk">
              <img
                className="patternImage"
                src={imageBasePath + "404.png"}
                alt={props.title}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default pageLayoutHoc(PageNotFound);
