import React, {Component} from 'react';
import {Container, Grid} from '@material-ui/core';
import {imageBasePath} from '../../../constants';
import styles from '../../../assets/styles/tinycanvas.module.scss';

export default function(props) {
  return(
    <Container maxWidth="md" className={styles.whyTinyCanvas}>
      <Grid container direction="row" spacing={2} className={styles.titleRow}>
        <Grid item xs={12}>
          <h3 className={styles.title + ' heading1'}>Why TinyCanvas  Plugin?</h3>
          <p className="sub-heading2">Ever wonder why anybody has to use TinyCanvas plugin. It comes up with below features</p>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="flex-start" spacing={3} className={styles.contentRow}>
        <Grid item xs={12} md={5} sm={6} className="flex-not-centered">
          <div className={styles.cardWrapper}>
            <div className={styles.imgBlk}>
              <img src={imageBasePath + 'optimized_load_time.svg'} alt="Optimized Load Time"/>
            </div>
            <div className={styles.contentBlk}>
              <h4 className="heading4">Optimized Load Time</h4>
              <p className="paragraph">Compressed image size helps in quick loading of websites and apps</p>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={5} sm={6} className="flex-not-centered">
          <div className={styles.cardWrapper}>
            <div className={styles.imgBlk}>
              <img src={imageBasePath + 'high_resolution.svg'} alt="High Resolution"/>
            </div>
            <div className={styles.contentBlk}>
              <h4 className="heading4">High Resolution</h4>
              <p className="paragraph">Up to 4X resolution, users can download compressed images</p>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="flex-end" spacing={3} className={styles.contentRow}>
        <Grid item xs={12} md={5} sm={6} className="flex-not-centered">
          <div className={styles.cardWrapper}>
            <div className={styles.imgBlk}>
              <img src={imageBasePath + 'download_zip.svg'} alt="Download ZIP file"/>
            </div>
            <div className={styles.contentBlk}>
              <h4 className="heading4">Download ZIP file</h4>
              <p className="paragraph">Multiple images will get compressed and downloaded in zip folder in one go</p>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={5} sm={6} className="flex-not-centered">
          <div className={styles.cardWrapper}>
            <div className={styles.imgBlk}>
              <img src={imageBasePath + 'supports_used_formats.svg'} alt="Supports Most Widely Used Formats"/>
            </div>
            <div className={styles.contentBlk}>
              <h4 className="heading4">Supports Most Widely Used Formats</h4>
              <p className="paragraph">Download images in PNG and JPEG formats</p>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  )
}
