import React, {useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Grid, Button, Dialog, Typography}  from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {imageBasePath} from '../constants';
// import styles from '../assets/styles/uxaudit.module.scss';
import styles from '../assets/styles/home.module.scss';

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function UxAuditSuccessModal(props) {
  const{uxAuditSuccessModal, formData} = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(uxAuditSuccessModal);
  }, [uxAuditSuccessModal])


  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen} className="hidden">
        Open dialog
      </Button>
      <Dialog maxWidth="sm" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="uxAuditSuccessModal">
        <DialogTitle id="customized-dialog-title" onClose={handleClose} className="dialogTitle">
        </DialogTitle>
        <DialogContent className="dialogContent">
          <img src={imageBasePath+'uxaudit_success.png'} alt="UxAudit Success" />
          <h1 className="heading3">UX Audit Request <br/> Submitted successfully !</h1>
          <p className="sub-heading2 description">We will verify your request and get back to you soon on your  email Address <span>{formData.email}</span>.</p>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item md={6} sm={6} xs={12}>
              <p>
                <a href="/ux-audit/apply" className="primary-btn requestBtn">
                  Request new UX Audit
                </a>
              </p>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <p>
                <a href="/" className="knowMoreBtn">
                  Back to home page
                </a>
              </p>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
