import React, {Component} from 'react';
import {Container, Grid} from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import {imageBasePath} from '../../../constants';
import { Link } from 'react-router-dom';
import styles from '../../../assets/styles/giffycanvasdetail.module.scss';
import LazyLoad from 'react-lazyload';

const canvas = [
  {
    img: 'install_plugin_giffy.jpg',
    title: 'Install Plugin',
    description: 'Install the ‘GiffyCanvas’ plugin from the ‘Plugins’ section of Figma.'
  },
  {
    img: 'select_images_giffy.jpg',
    title: 'Select Images',
    description: 'Select at least two layers to create a GIF image.'
  },
  {
    img: 'tweaks_giffy.jpg',
    title: 'Tweaks',
    description: 'Select interval, height, and width of GIF image. Click on ‘Preview’ to see the GIF image.'
  },
  {
    img: 'download_giffy.jpg',
    title: 'Download',
    description: 'Click on ‘Download’ button to download GIF image'
  },
]

export default function(props) {
  return(
    <>
      <Container maxWidth="md" className={styles.howSectionContainer}>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xs={12}>
            <div className={styles.backArrow}>
              <Link to="/giffycanvas" className="sub-heading2 color_black">
                <img src={ imageBasePath+'back2.svg' } alt="Back"/>
                <span className={styles.back}>Back</span>
              </Link>
            </div>
            <h1 className={styles.howItWorks + ' heading2'}>How it works?</h1>
          </Grid>
        </Grid>
          {
            canvas.map((canvas, key) => {
              return(
                <Grid container direction="row" className={styles.canvasCard} justify="flex-start" alignItems="center" spacing={isMobile ? 2 : 8}>
                  <Grid item md={6} xs={12}>
                    <LazyLoad height={350} once >
                    <img src={ imageBasePath+ canvas.img } alt={canvas.title} />
                    </LazyLoad>
                  </Grid>
                  <Grid item md={5} xs={12} className={styles.rightBlk}>
                    <h2 className="heading2">{canvas.title}</h2>
                    <p className="paragraph">{canvas.description}</p>
                  </Grid>
                </Grid>
              )
            })
          }
      </Container>
    </>
  )
}
