import { globalPostService} from '../../utils/globalApiServices'
import { toastFlashMessage } from '../../utils'

export function createContactApi(query={}){
    const {formData} = this.state;
    globalPostService('/contact-us/', query.formData)
    .then(response => {
        if(response.statusCode == 200){
            // const data = {...response.data}
            this.setState((prevState) => ({
                ...prevState,
                submitLoader: false,
            }));
            toastFlashMessage(response.message, 'success');
            this.setState({
              formData: ' '
            });
        }else{
            this.setState((prevState) => ({
                ...prevState,
                submitLoader: false,
            }));
            toastFlashMessage(response.message, 'error')
        }
    })
}
