import React from 'react'
import { Container, Grid } from '@material-ui/core';
import {imageBasePath} from '../../../constants';
import LazyLoad from 'react-lazyload';
import styles from '../../../assets/styles/company.module.scss';

export default function TeamCategory(props){
    const { title, team, type="" } = props
    return(
        <Container maxWidth="lg" className={styles.teamCatagoryContainer}>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={12} className={styles.teamTitle}>
                    <h3 className="heading2">{title}</h3>
                </Grid>
            </Grid>
            <Grid container className={styles.teamListWrapper} spacing={4}>
                {
                    team.map((item, index) => <TeamMember person={item} key={index} type={type}/>)
                }
            </Grid>
        </Container>
    )
}

function TeamMember(props){
    const { person, type } = props
    return(
        <Grid item md={type == "leadership" ? 4: 3} xs={6} sm={6}>
            <a className={styles.teamMember} href={person.linkedInProfile} target="_blank" rel="noopener">
                <LazyLoad height={180} once >
                    <img className={styles.image} src={ imageBasePath +'team/'+ person.img } alt={person.alt}/>
                </LazyLoad>
                <h3 className={`${styles['heading4']} heading4`} style={person.css}>{person.name}</h3>
                <p className={`${styles['paragraph']} paragraph`}>{person.designation}</p>
            </a>
        </Grid>
    )
}
