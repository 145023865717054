import React, { useState } from 'react'
import styles from '../../../assets/styles/company.module.scss';
import { Container, Grid, List, ListItem } from '@material-ui/core';

export default function Summary(props){
    const [summaryData] = useState([
        {
            value: '7 Years',
            title: 'In operation'
        },
        {
            value: '35+',
            title: 'Products launched'
        },
        {
            value: '45+',
            title: 'Products enabled'
        },
        {
            value: '30+',
            title: 'Team Size'
        },
        {
            value: '3-4 Years',
            title: 'Avg. Team experience'
        }        
    ])
    return(
        <section className={styles.summarySection}>
            <Container maxWidth={"lg"} className={styles.summaryContainer}>
                <div className={styles.outerWrapper}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5} lg={5}>
                            <h3 className="heading2">DesignString in <br/>numbers:</h3>
                        </Grid>
                        <Grid item xs={12} md={7} lg={7}>
                            <List className="list-inline flex-not-centered">
                                {summaryData.map((item, index) => <ListItem key={index} className="list-inline-item">
                                    <h3 className="heading2">{item.value}</h3>
                                    <p className="subHeading1">{item.title}</p>
                                </ListItem>)
                                }
                            </List>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </section>
    )
}