import React, { Component } from 'react'
import {Hero, Bg, Challenge, Solution, Feedback, Results, FeaturedWorks} from '../components'
import styles from '../../../assets/styles/casestudy_detail.module.scss';
import {FooterTop} from '../../../shared_elements/'
import {CaseStudies} from '../../home/components';
import { imageBasePath } from '../../../constants';
import {Helmet} from "react-helmet";

export class Kargo extends Component {
    constructor(props){
        super(props);
        this.state = {
            pageName : 'kargo',
            heroDetails: {
                title : 'To digitize mini-truck bookings for intra-city movement of goods with ease-of-use both for the Merchant and the Captain (Driver)',
                subtitle: 'When two entrepreneurs in Lucknow felt frustrated finding mini-truck vendors for their business on a regular basis, they decided to take matters into their own hands. And thus was born the idea of Kargo - a mini-truck aggregator to help intra-city movement of goods. Market study of similar products in other cities and a few quick prototypes to test the product validated the product concept. With the right MVP in place, they approached us to build the full-fledged product and help in scaling it to other cities.',
                type: 'Launch',
                typeImage: 'launch.svg',
                logo: 'kargo/kargo_logo.svg',
                category: 'Transport',
                geo: 'India ( Lucknow City )',
                output : 'Merchant & Driver App, Platform'
            }, 
            bg:{
                img : 'kargo/kargo_hero_bg.png'
            },
            challenge : 'The challenge was to build the product in such a way that there was a minimal learning curve for the users of the app. The technology behind had to be carefully planned to support future features and scaling up to pan-India operations. ',
            productType : 'mobile',
            solution: {
                description : [
                    'With our domain experience in a similar sector, we were successful in getting the product ready in a record time of 3 months. To help its non-English speaking users, the app provided support for Hindi language too. We also doubled up as their strategic advisory partner to help them achieve their desired business goals and give them a competitive advantage. Some notable modules in the app were:'
                ],
                list : [
                    {
                        title: 'Trip Booking Flow',
                        link: 'kargo/booking_flow.mp4'
                    },
                    {
                        title: 'Captain Interface',
                        link: 'kargo/driver_flow.mp4'
                    },
                    {
                        title: 'Trip on-going',
                        link: 'kargo/trip_ongoing.mp4'
                    }
                ],
                media: {
                    product_type: 'mobile',
                    media_type: 'video',
                }
            },
            disignsImage : '', 
            clientFeedback: {
                feedback: "The right design and tech partner, they are capable and go  extra mile to make sure entrepreneurs have everything they need to go to market and crush. ",
                clientDetail: {
                    img: '',
                    name: 'Jayanth Mishra',
                    designation: 'Co-Founder',
                    company: 'Kargo'
                }
            },
            result: 'Kargo is launched in Lucknow state of India and operational. With its ease of booking for Merchants and on-time payments for Captains, it is sure to be the Uber for mini-trucks in Lucknow and expand further to other cities.',
            availableon: [
                {
                    img : 'android_version.png',
                    link: 'https://play.google.com/store/apps/details?id=com.kargo.consumer'
                },
                {
                    img : 'android_version.png',
                    link: 'https://play.google.com/store/apps/details?id=com.kargo.captain'
                }
            ],
            caseStudies : [
                {
                    title: 'Amby',
                    featuredImage: 'amby_work_cover.png',
                    type: 'Launch',
                    typeImage: 'launch.svg',
                    logo: 'amby_dp.png',
                    description: 'Making OKR-based goal-setting and management as easy and adoptable as an excel spreadsheet.',
                    link: '/work/amby/'
                },
                {
                  title: 'OneSky',
                  featuredImage: 'onesky_work_cover.png',
                  type: 'Launch',
                  typeImage: 'launch.svg',
                  logo: 'onesky_dp.png',
                  description: 'Translating complex airspace rules and regulations into coherent and ready-to-use information for small UAS and drone operators.',
                  link: '/work/onesky/' 
                }
                
            ],
            writeClutchReview: ''
        }
    }
    render() {
        const{pageName, heroDetails, bg, challenge, productType, solution, clientFeedback, result, availableon, caseStudies, disignsImage, writeClutchReview} =this.state;
        return ( 
            <div className={styles.caseStudyDetailPage}>
                <Helmet>
                    <title>DesignString: Case Study Kargo, Category: Transport</title>
                    <meta id="meta-description" name="description" content="How we helped entrepreneurs from Lucknow to build a mini-truck platform to solve their problem being business people and also Lucknow intra-city goods transport" />
                    <meta id="og-title" property="og:title" content="DesignString: Case Study Kargo, Category: Transport" />
                    <meta id="og-description" property="og:description" content="How we helped entrepreneurs from Lucknow to build a mini-truck platform to solve their problem being business people and also Lucknow intra-city goods transport" />
                    <meta id="og-image" property="og:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/kargo.jpg" />
                    <meta id="og-url" property="og:url" content="https://designstring.com/work/kargo" />
                    <meta id="twitter-title" property="twitter:title" content="DesignString: Case Study Kargo, Category: Transport" />
                    <meta id="twitter-description" property="twitter:description" content="How we helped entrepreneurs from Lucknow to build a mini-truck platform to solve their problem being business people and also Lucknow intra-city goods transport" />
                    <meta id="twitter-image" property="twitter:image" content="https://ds-storage.sgp1.cdn.digitaloceanspaces.com/website/assets/v4/meta/kargo.jpg" />
                    <meta id="twitter-url" property="twitter:url" content="https://designstring.com/work/kargo" />
                    <meta id="twitter-card" property="twitter:card" content="summary_large_image" />
                </Helmet>
                <section className={styles.heroSection}>
                    <Hero pageName={pageName} heroDetails={heroDetails} />
                </section>
                <section className={styles.bgSection}>
                    <Bg pageName={pageName} bg={bg} />
                </section>
                <section className={styles.challengeSection}>
                    <Challenge pageName={pageName} challenge={challenge} />
                </section>
                <section className={styles.solutionSection}>
                    <Solution pageName={pageName} productType={productType} solution={solution} />
                </section>
                {
                    disignsImage ?
                    <section className={styles.disignsImageSection+' backgroundProp'} style={{ backgroundImage : `url(${imageBasePath+'v4/'+disignsImage})` }}>

                    </section>
                    : null
                }
                <section className={styles.feedbackSection}>
                    <Feedback pageName={pageName} clientFeedback={clientFeedback} writeClutchReview={writeClutchReview} />
                </section>
                <section className={styles.resultSection}>
                    <Results pageName={pageName} result={result} availableon={availableon} />
                </section>
                <section className={styles.featuredWork}>
                    <CaseStudies caseStudies={caseStudies} />
                </section>
                <FooterTop link="contact" title={<>Ready to launch your product to the world?</>} />
            </div>
        )
    }
}

export default Kargo
